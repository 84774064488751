import _ from 'lodash';
import { transformWorkflowBeforeSave } from '@src-v2/containers/workflow/transformers';
import {
  ApplicationDefaultProjectOption,
  OpenIssueInSameRepositoryOption,
  TeamDefaultProjectOption,
  ThenType,
  anyOption,
} from '@src-v2/containers/workflow/types/types';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { ApiClient, SearchParams } from '@src-v2/services/api-client';
import { AggregationResult } from '@src-v2/types/aggregation-result';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { ProviderGroup } from '@src-v2/types/enums/provider-group';
import { OrganizationTeamProfileResponse } from '@src-v2/types/profiles/organization-team-profile-response';
import { convertIssueType } from '@src/blocks/WorkflowsPage/blocks/project-select/utils';

type ValueOption = { key: string; displayName: string };

export class Workflows {
  #client: ApiClient;
  #asyncCache;
  #hasGlobalAccess;
  #application;

  constructor({ apiClient, rbac, asyncCache, application }) {
    this.#client = apiClient;
    this.#asyncCache = asyncCache;
    this.#hasGlobalAccess = rbac.canEdit(resourceTypes.Global);
    this.#application = application;
  }

  getWorkflows(): Promise<[]> {
    return this.#client.get(`workflows/workflows`);
  }

  searchWorkflows = async (
    searchParams: SearchParams,
    apiOptions?: any
  ): Promise<AggregationResult<OrganizationTeamProfileResponse>> => {
    return await this.#client.search(
      `workflows/workflows/search`,
      {
        ...searchParams,
        limit: 20,
        sort: searchParams?.sort ?? 'DisplayName',
        searchTerm: searchParams?.searchTerm ?? '',
      },
      apiOptions
    );
  };

  async deleteWorkflow(key) {
    await this.#client.delete(`workflows/workflows/${key}`);
    this.#asyncCache.invalidateAll(this.getWorkflows);
    this.#asyncCache.invalidateAll(this.searchWorkflows);
  }

  async saveWorkflow(workflow) {
    const workflowToSave = transformWorkflowBeforeSave(workflow);
    await this.#client.put(`workflows/workflows/${workflowToSave.key}`, workflowToSave);
    this.#asyncCache.invalidateAll(this.getWorkflows);
    this.#asyncCache.invalidateAll(this.searchWorkflows);
  }

  async createWorkflow(workflow) {
    const key = crypto.randomUUID();
    const workflowToSave = transformWorkflowBeforeSave(workflow);
    await this.#client.put(`workflows/workflows/${key}`, {
      ...workflowToSave,
      key,
    });
    this.#asyncCache.invalidateAll(this.getWorkflows);
    this.#asyncCache.invalidateAll(this.searchWorkflows);
  }

  testWebhook(url, authorizationHeader) {
    return this.#client.post(
      `workflows/testWebhook?url=${url}&authorizationHeader=${authorizationHeader}`
    );
  }

  async getWorkflowRecipes() {
    const recipeGroups = await this.#client.get('workflows/recipes');
    return recipeGroups.map(group =>
      Object.assign(group, {
        recipes: group.recipes.map(recipe =>
          _.defaultsDeep(recipe, {
            workflow: {
              key: crypto.randomUUID(),
              name: recipe.title,
              workflowNotificationEffect: 'Immediate',
            },
          })
        ),
      })
    );
  }

  async getProjectUsers({ searchTerm, projectKey }) {
    const params = searchTerm ? { searchTerm } : {};

    return await this.#client.get(`projects/${projectKey}/users/search`, {
      params,
    });
  }

  async getProjectUser({ searchTerm, projectKey }) {
    const users = await this.#client.get(`projects/${projectKey}/users/search`, {});

    if (!Array.isArray(users)) {
      return;
    }

    return (
      (users ?? []).find(user => user.includes?.(searchTerm) ?? user.key === searchTerm) ??
      searchTerm
    );
  }

  getMonitoredTicketingProjects({ provider, withCreateIssuesPermission, searchTerm }) {
    if (!(provider === 'Jira' || provider === 'AzureDevops')) {
      return Promise.resolve();
    }

    return this.#client.get(`projects/v2/${provider}`, {
      params: {
        withCreateIssuesPermission,
        searchTerm,
        pageSize: 20,
        showDefaults: true,
      },
    });
  }

  getThenSubTypeSpecialOptions() {
    const options: any = [OpenIssueInSameRepositoryOption];
    if (this.#application.isFeatureEnabled(FeatureFlag.ApplicationDefaultCommunication)) {
      options.push(ApplicationDefaultProjectOption);
    }
    if (this.#application.isFeatureEnabled(FeatureFlag.TeamDefaultCommunication)) {
      options.push(TeamDefaultProjectOption);
    }
    return options;
  }

  async createHierarchyDictionary() {
    const items = await this.#client.get('asset-collections/org-teams/chart');
    const dictionary = {};

    items.forEach(item => {
      dictionary[item.key] = item.hierarchy.map(h => h.name).join(' / ');
    });

    return dictionary;
  }

  async getIssueTypeOptions({ projectKey, provider, isCustomSchema }) {
    if (isCustomSchema && projectKey) {
      const issueTypes = await this.#client.get(`workflows/issueTypes/${provider}/${projectKey}`);

      return (issueTypes ?? []).map(convertIssueType);
    }

    return Promise.resolve();
  }

  async searchRepositories({ searchTerm }) {
    const { items } = await this.#client.search('repositories/search', {
      searchTerm,
    });

    return this.#hasGlobalAccess ? [anyOption, ...items] : items;
  }

  async searchContributors({ searchTerm }) {
    const { items } = await this.#client.search('developers/profiles/search', {
      searchTerm,
    });
    return items ?? [];
  }

  async searchApplications({ searchTerm }) {
    const { items } = await this.#client.search('assetCollections/profiles/search', {
      searchTerm,
    });
    return this.#hasGlobalAccess ? [anyOption, ...items] : items;
  }

  async searchTeams({ searchTerm }) {
    const { items } = await this.#client.search('asset-collections/org-teams/profiles/search', {
      searchTerm,
    });

    const d = await this.createHierarchyDictionary();
    items.forEach(item => {
      item.uniqueName = d[item.key];
    });

    return this.#hasGlobalAccess ? [anyOption, ...items] : items;
  }

  async getQuestionnaireTemplates({ searchTerm }) {
    const { items } = await this.#client.search('questionnaire-templates/search', {
      searchTerm,
    });

    return items;
  }

  async searchProjects({ searchTerm }) {
    const { items } = await this.#client.get(`projects/search`, {
      searchTerm,
      pageSize: 20,
    });
    return this.#hasGlobalAccess ? [anyOption, ...items] : items;
  }

  async searchServers({ searchTerm }) {
    const items = await this.#client.get(`servers/v2/project-and-repository-servers`, {
      searchTerm,
      pageSize: 20,
    });
    const itemsToDisplay = items.map(item => ({ ...item, key: item.url, name: item.url }));
    return this.#hasGlobalAccess ? [anyOption, ...itemsToDisplay] : itemsToDisplay;
  }

  async getRepository({ key }) {
    if (!key || typeof key !== 'string') {
      return Promise.resolve(null);
    }
    if (key === 'any') {
      return Promise.resolve({ key, name: 'any' });
    }

    const repository = await this.#client.get(`repositories/${key}`);
    if (!repository) {
      return key;
    }
    return repository;
  }

  async getApplication({ key }) {
    if (!key || typeof key !== 'string') {
      return Promise.resolve();
    }
    if (key === 'any') {
      return Promise.resolve(anyOption);
    }

    const application = await this.#client.get(`assetCollections/${key}/profile`);
    if (!application) {
      return key;
    }
    return application;
  }

  async getTeam({ key }) {
    if (!key || typeof key !== 'string') {
      return Promise.resolve();
    }
    if (key === 'any') {
      return Promise.resolve(anyOption);
    }
    const team = await this.#client.get(`asset-collections/org-teams/${key}/profile`);
    if (!team) {
      return key;
    }
    const d = await this.createHierarchyDictionary();
    team.uniqueName = d[team.key];
    return team;
  }

  async getQuestionnaireTemplate({ key }) {
    if (!key || typeof key !== 'string') {
      return Promise.resolve();
    }
    if (key === 'any') {
      return Promise.resolve(anyOption);
    }
    return await this.#client.get(`questionnaire-templates/${key}`);
  }

  async getProject({ key }) {
    if (key === 'OpenIssueInSameRepository') {
      return Promise.resolve(OpenIssueInSameRepositoryOption);
    }

    if (key === 'ApplicationDefaultCommunication__ApplicationDefaultCommunication') {
      return Promise.resolve(ApplicationDefaultProjectOption);
    }

    if (key === 'TeamDefaultCommunication__TeamDefaultCommunication') {
      return Promise.resolve(TeamDefaultProjectOption);
    }

    if (!key || typeof key !== 'string') {
      return Promise.resolve();
    }
    if (key === 'any') {
      return Promise.resolve(anyOption);
    }

    const project = await this.#client.get(`projects/${key}`);
    if (!project) {
      return key;
    }
    return project;
  }

  getContributor({ key }) {
    return this.#client.get(`developers/${key}`);
  }

  getWhenOptions({ type }): Promise<ValueOption[]> {
    return this.#client.get(`workflows/whenOptions?whenType=${type}`);
  }

  getThenValueOptions = async ({ type }: { type: ThenType }): Promise<ValueOption[]> => {
    const channels = await this.#client.get(`workflows/thenOptions?thenType=${type}`);

    if (
      type === ProviderGroup.Slack ||
      type === ProviderGroup.GoogleChat ||
      type === ProviderGroup.Teams
    ) {
      const specialOptions = [];
      if (this.#application.isFeatureEnabled(FeatureFlag.ApplicationDefaultCommunication)) {
        specialOptions.push({
          key: 'ApplicationDefaultCommunication__ApplicationDefaultCommunication',
          displayName: 'Application default channel',
        });
      }
      if (this.#application.isFeatureEnabled(FeatureFlag.TeamDefaultCommunication)) {
        specialOptions.push({
          key: 'TeamDefaultCommunication__TeamDefaultCommunication',
          displayName: 'Team default channel',
        });
      }
      return [...specialOptions, ...channels];
    }

    if (type === ProviderGroup.Gitlab || ProviderGroup.Gitlab || ProviderGroup.AzureDevops) {
      return [{ key: 'OpenIssueInSameRepository', displayName: 'Same repository' }, ...channels];
    }
    return channels;
  };
}
