import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { ExitPoints, ExitPointsHeader } from '@src/components/ExitPoints/exitPointsCommon';
import { VerticalStack } from '@src/components/VerticalStack';

const SensitiveDataExitPointMaterialChange = ({
  materialChange,
  name,
  repository,
  commitSha,
  relativeFilePath,
}) => {
  const exitPoints = sortBy(materialChange.exitPoints, ['kind']);
  const exitPointsByMember = groupBy(exitPoints, 'member');
  return (
    <VerticalStack>
      <ExitPointsHeader
        exitPoints={exitPoints}
        dataOfInterestFamilyType={materialChange.associatedSensitiveDataTypeDescription}
        relativeFilePath={relativeFilePath}
        showName
        name={name}
      />
      <ExitPoints
        exitPointsByMember={exitPointsByMember}
        repository={repository}
        commitSha={commitSha}
      />
    </VerticalStack>
  );
};

export default SensitiveDataExitPointMaterialChange;
