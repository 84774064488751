import styled from 'styled-components';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { ApiGatewayReferenceDescription } from '@src/components/ApiGatewayReferenceDescription';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { FontBodyBold } from '@src/style/common';
import { getApiText } from '@src/utils/apiUtils';
import {
  CommitCodeReference,
  thenSubTypeToConjunction,
  thenSubTypeToVerb,
} from './MaterialChangeUtils';
import { StyledHighlightedCode, StyledWrappedTitledList } from './Styles';

const StyledApiMethodName = styled(PopoverTip)`
  ${FontBodyBold};
`;

const ApiMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  materialChange,
  thenSubType,
}) => {
  const { apiGatewayReference, codeReference } = materialChange;
  const { apiName, methodName, methodSignature, httpMethod, httpRoute, className } = codeReference;
  if (thenSubType === 'Altered') {
    return (
      <StyledWrappedTitledList
        title={
          <VerticalStack>
            <HorizontalStack spacing="0.3">
              <span>API</span>
              <StyledApiMethodName
                title="API"
                linkText={getApiText(httpMethod, httpRoute, methodName)}>
                <StyledHighlightedCode language="java" code={methodSignature}>
                  {methodSignature}
                </StyledHighlightedCode>
              </StyledApiMethodName>
              <span>of</span>
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={relativeFilePath}>
                {className || relativeFilePath}
              </CommitCodeReference>
              <span>was</span>
              <Bold>significantly changed</Bold>
            </HorizontalStack>
            {apiGatewayReference && (
              <ApiGatewayReferenceDescription apiGatewayReference={apiGatewayReference} />
            )}
          </VerticalStack>
        }
        list={materialChange.descriptions}
      />
    );
  }

  return (
    <VerticalStack>
      <HorizontalStack spacing="0.3">
        <span>API was</span>
        <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
        <>
          <span>{`${thenSubTypeToConjunction(thenSubType)} class`}</span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}>
            {className || relativeFilePath}
          </CommitCodeReference>
        </>
      </HorizontalStack>
      {apiGatewayReference && (
        <ApiGatewayReferenceDescription apiGatewayReference={apiGatewayReference} />
      )}
      <StyledApiMethodName title="API" linkText={apiName || methodName}>
        <StyledHighlightedCode language="java" code={methodSignature}>
          {methodSignature}
        </StyledHighlightedCode>
      </StyledApiMethodName>
    </VerticalStack>
  );
};

export default ApiMaterialChange;
