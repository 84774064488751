import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { SplitScreen } from '@src-v2/components/layout/split-screen';
import { useToggle } from '@src-v2/hooks';
import {
  getChartNodePresentationConfig,
  getLinkPresentationConfig,
  getRiskLevelBadge,
} from '@src/cluster-map-work/components/charts/chart-view-presentation-config';
import { ChartZoomControl } from '@src/cluster-map-work/components/charts/chart-zoom-control';
import {
  D3GraphOverview,
  SizedForceGraph2D,
} from '@src/cluster-map-work/components/charts/graph-utils';
import { VerticalStack } from '@src/components/VerticalStack';
import { riskTitles } from '@src/services/riskService';

const DOUBLE_CLICK_SPEED = 200;

const REVERSED_RISK_TITLES = riskTitles.concat().reverse();

export function ChartView({
  graphData,
  nodeRiskSummary,
  chartViewPresentationConfig,
  externalSelectedNode,
  initialShowProperties,
  highlightsList,
  overviewSize,
  minScaleForNodeLabels,
  maxZoom = 8,
  onNodeSelected,
  onCreatePropertiesPane,
}) {
  const [selectedNode, setSelectedNode] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [lastClick, setLastClick] = useState();

  const [showProperties, toggleShowProperties] = useToggle(initialShowProperties);

  const [selectionZoomPending, setSelectionZoomPending] = useState(null);
  const [forceSimulationRunning, setForceSimulationRunning] = useState(true);

  const [currentZoom, setCurrentZoom] = useState(0);

  const chartRef = useRef();
  const overviewRef = useRef();

  const clusterBoundingBox = useMemo(() => ({ top: 0, left: 0, width: 1, height: 1 }), []);
  const screenBoundingBox = useMemo(() => ({ top: 0, left: 0, width: 1, height: 1 }), []);

  const selectNode = useCallback(
    node => {
      setSelectedNode(node);

      if (onNodeSelected) {
        onNodeSelected(node);
      }
    },
    [setSelectedNode, showProperties, toggleShowProperties, onNodeSelected]
  );

  useEffect(() => {
    setForceSimulationRunning(true);
    chartRef.current.zoom(1);
    chartRef.current.centerAt(0, 0);
  }, [graphData]);

  useEffect(() => {
    chartRef.current.zoomToFit(500, 10, isNodeHighlighted);
  }, [highlightsList, graphData]);

  const handleNodeDoubleClick = node => {
    if (node) {
      chartRef.current.zoom(5, 500);
      chartRef.current.centerAt(node.x, node.y, 500);
    }
  };

  const selectNodeByClick = node => {
    selectNode(node);

    if ((node && !showProperties) || (!node && showProperties)) {
      toggleShowProperties();
    }
  };

  const handleNodeClicked = (node, event) => {
    if (
      (event?.timeStamp || 0) - (lastClick?.timeStamp || 0) < DOUBLE_CLICK_SPEED &&
      lastClick?.node === node
    ) {
      setLastClick(null);
      handleNodeDoubleClick(node);
    } else {
      setLastClick({ timeStamp: event?.timeStamp, node });
      selectNodeByClick(node);
    }
  };

  const handleBackgroundClicked = () => {
    handleNodeClicked(null);
  };

  const effectiveHighlightedNodes = useMemo(() => {
    if (highlightsList) {
      return new Set(highlightsList);
    }

    const cliqueFocusNode = hoveredNode || selectedNode;
    if (!cliqueFocusNode) {
      return null;
    }

    const ret = new Set();
    const visitQueue = [graphData.linkedNodes[cliqueFocusNode.id]];

    for (let queueIndex = 0; queueIndex < visitQueue.length; queueIndex++) {
      const currentNode = visitQueue[queueIndex];
      if (!ret.has(currentNode.node.id)) {
        ret.add(currentNode.node.id);
        visitQueue.push(...currentNode.neighbours);
      }
    }

    return ret;
  }, [highlightsList, hoveredNode, selectedNode]);

  const isNodeHighlighted = node =>
    effectiveHighlightedNodes && effectiveHighlightedNodes.has(node.id);

  const isEdgeHilighted = edge => isNodeHighlighted(edge.source) || isNodeHighlighted(edge.target);

  const paintNodeForClusterResource = (node, canvasContext, globalScale) => {
    const nodeHighestRisk = riskForNode(nodeRiskSummary, node);

    const presConf = getChartNodePresentationConfig(chartViewPresentationConfig, node.nodeType);

    const nodeSvg = nodeHighestRisk
      ? presConf.svgNodesByRisk[nodeHighestRisk]
      : presConf.noRiskSvgNode;
    nodeSvg.draw(
      canvasContext,
      node.x,
      node.y,
      screenBoundingBox,
      globalScale,
      node === selectedNode,
      false,
      !isNodeHighlighted(node) && effectiveHighlightedNodes
    );

    if (nodeHighestRisk) {
      getRiskLevelBadge(nodeHighestRisk).draw(
        canvasContext,
        node.x - presConf.riskBadgeOffset,
        node.y - presConf.riskBadgeOffset,
        screenBoundingBox,
        globalScale,
        false,
        false
      );
    }

    if (minScaleForNodeLabels && globalScale >= minScaleForNodeLabels) {
      const captionText = node.name;
      const metrics = canvasContext.measureText(captionText);
      canvasContext.fillStyle = 'rgba(255,255,255,0.8)';
      canvasContext.fillRect(
        node.x - metrics.width / 2 - 1,
        node.y + 10 - metrics.fontBoundingBoxAscent,
        metrics.width + 2,
        metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
      );

      canvasContext.fillStyle = 'black';
      canvasContext.textAlign = 'center';
      canvasContext.font = `3px sans-serif`;
      canvasContext.fillText(node.name, node.x, node.y + 10);
    }
  };

  const paintEdgeForClusterLink = (link, canvasContext) => {
    const presConf = getLinkPresentationConfig(chartViewPresentationConfig, link.linkType);
    canvasContext.strokeStyle = presConf.color;
    canvasContext.lineWidth = 0.75;
    canvasContext.globalAlpha = effectiveHighlightedNodes && !isEdgeHilighted(link) ? 0.2 : 1;
    canvasContext.beginPath();
    canvasContext.moveTo(link.source.x, link.source.y);
    canvasContext.lineTo(link.target.x, link.target.y);
    canvasContext.stroke();
    canvasContext.globalAlpha = 1;
  };

  const [minZoomFactor, setMinZoomFactor] = useState(0.05);

  const handlePreRenderFrame = canvasContext => {
    if (!chartRef.current) {
      return;
    }

    const {
      x: [xmin, xmax],
      y: [ymin, ymax],
    } = chartRef.current.getGraphBbox();

    Object.assign(clusterBoundingBox, {
      top: ymin,
      left: xmin,
      width: xmax - xmin,
      height: ymax - ymin,
    });

    const screenBoundingBoxTopLeft = chartRef.current.screen2GraphCoords(0, 0);
    const screenBoundingBoxBottomRight = chartRef.current.screen2GraphCoords(
      canvasContext.canvas.clientWidth,
      canvasContext.canvas.clientHeight
    );

    Object.assign(screenBoundingBox, {
      top: screenBoundingBoxTopLeft.y,
      left: screenBoundingBoxTopLeft.x,
      width: screenBoundingBoxBottomRight.x - screenBoundingBoxTopLeft.x,
      height: screenBoundingBoxBottomRight.y - screenBoundingBoxTopLeft.y,
    });

    if (!chartRef.current) {
      return;
    }

    const newMinZoomFactor = Math.min(
      Math.max(
        canvasContext.canvas.clientWidth / (2 * clusterBoundingBox.width),
        canvasContext.canvas.clientHeight / (2 * clusterBoundingBox.height)
      ),
      1
    );

    // Updating min zoom factor is costly so only do this if there's a
    // significant enough change
    if (Math.abs(newMinZoomFactor - minZoomFactor) / Math.max(minZoomFactor) > 0.2) {
      setMinZoomFactor(newMinZoomFactor);
    }
  };

  const handlePostRenderFrame = () => {
    if (overviewRef.current) {
      overviewRef.current.refreshOverview();
    }

    if (selectionZoomPending && !forceSimulationRunning) {
      const zoomTarget = selectionZoomPending;
      setSelectionZoomPending(null);
      chartRef.current.zoom(0.5, 500);

      setTimeout(() => {
        chartRef.current.centerAt(zoomTarget.x, zoomTarget.y, 500);
      }, 300);

      setTimeout(() => {
        chartRef.current.zoom(3, 400);
      }, 600);
    }
  };

  useEffect(() => {
    if (externalSelectedNode !== selectedNode) {
      selectNode(externalSelectedNode);
      setSelectionZoomPending(externalSelectedNode);
    }
  }, [graphData, externalSelectedNode]);

  const handleOverviewClick = useCallback((event, chartX, chartY) => {
    chartRef.current.centerAt(chartX, chartY, 100);
  }, []);

  const handleOverviewDragTo = useCallback((event, chartX, chartY) => {
    chartRef.current.centerAt(chartX, chartY, 0);
  }, []);

  const handleZoomEnd = useCallback(
    zoom => {
      setCurrentZoom(zoom.k);
    },
    [setCurrentZoom]
  );

  const handleZoomRequest = useCallback(
    (event, newZoom, isHome) => {
      chartRef.current.zoom(newZoom, 200);
      if (isHome) {
        chartRef.current.centerAt(0, 0, 200);
      }
    },
    [chartRef.current]
  );

  const handleHoveredNode = useCallback(
    node => {
      setHoveredNode(node);
    },
    [setHoveredNode]
  );

  const renderNodeLabel = useCallback(node => {
    const presConf = getChartNodePresentationConfig(chartViewPresentationConfig, node.nodeType);
    return `
        <div class="chart-label">
            <div class="chart-label-element-type">${_.escape(presConf.friendlyName)}</div>
            <div class="chart-label-element-name">${_.escape(node.name)}</div>
        </div>`;
  });

  const propertiesPane = useMemo(
    () =>
      onCreatePropertiesPane !== null
        ? onCreatePropertiesPane(selectedNode, toggleShowProperties)
        : null,
    [selectedNode, onCreatePropertiesPane]
  );

  const DRAG_THRESHOLD = 10;

  const handleNodeDrag = (node, translate) => {
    if (
      translate.x > -DRAG_THRESHOLD &&
      translate.x < DRAG_THRESHOLD &&
      translate.y > -DRAG_THRESHOLD &&
      translate.y < DRAG_THRESHOLD
    ) {
      handleNodeClicked(node);
    }
  };

  return (
    <ChartAndPropertiesPaneContainer>
      <SplitScreen asideOpen={showProperties} defaultWidth="120rem">
        <SizedForceGraph2D
          maxZoom={maxZoom}
          minZoom={minZoomFactor}
          chartRef={chartRef}
          graphData={graphData}
          nodeCanvasObject={paintNodeForClusterResource}
          linkCanvasObject={paintEdgeForClusterLink}
          onNodeClick={handleNodeClicked}
          onNodeHover={handleHoveredNode}
          onBackgroundClick={handleBackgroundClicked}
          onRenderFramePre={handlePreRenderFrame}
          onRenderFramePost={handlePostRenderFrame}
          nodeLabel={renderNodeLabel}
          onZoomEnd={handleZoomEnd}
          cooldownTicks={100}
          onEngineTick={() => {
            setForceSimulationRunning(true);
          }}
          onEngineStop={() => {
            setForceSimulationRunning(false);
          }}
          onNodeDrag={handleNodeDrag}
        />

        <ZoomControlsContainer>
          <CenteredVerticalStack>
            <ChartZoomControl
              minZoom={minZoomFactor}
              maxZoom={maxZoom}
              value={currentZoom}
              onChange={handleZoomRequest}
            />
          </CenteredVerticalStack>

          {overviewSize && (
            <OverviewContainer
              style={{
                width: overviewSize.width,
                height: overviewSize.height,
              }}>
              <GraphOverview
                ref={overviewRef}
                graphData={graphData}
                selectedNode={selectedNode}
                overviewBoundingBox={clusterBoundingBox}
                screenBoundingBox={screenBoundingBox}
                onClick={handleOverviewClick}
                onDragTo={handleOverviewDragTo}
              />
            </OverviewContainer>
          )}
        </ZoomControlsContainer>

        <SplitScreen.Aside>{propertiesPane}</SplitScreen.Aside>
      </SplitScreen>
    </ChartAndPropertiesPaneContainer>
  );
}

const GraphOverview = styled(D3GraphOverview)`
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: white;
`;

const ChartAndPropertiesPaneContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  width: 100%;

  & div.graph-tooltip {
    background: #212a3f;
    border-radius: 8px;
    padding: 1.5rem 3rem;
    font-family: inherit;
  }

  & .chart-label {
    padding: 1rem;
  }

  & .chart-label-element-type {
    font-size: 16px;
    font-weight: 600;
  }

  & .chart-label-element-name {
    font-size: 14px;
    font-weight: 400;
  }
`;

const OverviewContainer = styled.div`
  margin-left: 5rem;
  background: white;
  border-radius: 12px;
  border: solid 1px #e2e3f5;
  overflow: hidden;
`;

const ZoomControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  position: absolute;
  bottom: 6rem;
  left: 6rem;
`;

const CenteredVerticalStack = styled(VerticalStack)`
  align-items: center;
`;

export function riskForNode(nodeRiskSummary, node) {
  if (!node) {
    return null;
  }

  const nodeRiskHistogram = nodeRiskSummary?.[node.id]?.risksHistogramByLevel ?? {};
  return REVERSED_RISK_TITLES.find(riskLevel => nodeRiskHistogram[riskLevel]);
}
