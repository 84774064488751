import styled from 'styled-components';
import { StyledVerticalStack } from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableDisplay';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { CommitCodeReference } from '@src/components/MaterialChange/MaterialChangeUtils';
import { MisconfigurationDisplay } from '@src/components/Panes/TerraformModuleHighlightsPane';

const StyledMisconfigurationDisplay = styled(MisconfigurationDisplay)`
  margin-left: 6rem;
  padding-left: 30px;
`;
const TerraformMisconfigurationsMaterialChange = ({ commitSha, repository, materialChange }) => {
  return (
    <StyledVerticalStack>
      <HorizontalStack>
        <span>New misconfigurations introduced in resource</span>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={materialChange.resourceSummary.path}>
          {`${materialChange.resourceSummary.typeDescription} (${materialChange.resourceSummary.key})`}
        </CommitCodeReference>
        <span> under module</span>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={materialChange.modulePath}>
          {materialChange.modulePath}
        </CommitCodeReference>
      </HorizontalStack>
      <StyledMisconfigurationDisplay
        misconfigurations={materialChange.newMisconfigurationsDescriptions}
        repository={repository}
        resource={materialChange.resourceSummary}
        graphEnabled={false}
      />
    </StyledVerticalStack>
  );
};

export default TerraformMisconfigurationsMaterialChange;
