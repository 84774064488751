import { useMemo } from 'react';
import styled from 'styled-components';
import { ControlledCard, ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { ElementSeparator } from '@src-v2/components/element-separator';
import { EvidenceLine } from '@src-v2/components/entity-pane/evidence/evidence-line';
import { SeverityTag } from '@src-v2/components/tags';
import { InfoTooltip } from '@src-v2/components/tooltips/icon-tooltips';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading5 } from '@src-v2/components/typography';
import {
  ExploitMaturityTooltipContent,
  TooltipContent,
  isExploitMaturity,
} from '@src-v2/containers/entity-pane/sca/main-tab/vulnerabilities-card';
import { CveLink, CweLink } from '@src-v2/containers/entity-pane/sca/vulnerability-link';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { useCveEntityContext } from './cve-entity-context-provider';

export const CveEntityLikelihoodCard = (props: ControlledCardProps) => (
  <ControlledCard {...props} title="Likelihood & impact">
    <CardBody>
      <Likelihood />
      <VerticalLine />
      <Impact />
    </CardBody>
  </ControlledCard>
);

const Impact = () => {
  const {
    cveEntity: { cvssScore, cweIdentifiers, cveIdentifiers, severity },
  } = useCveEntityContext();

  return (
    <ImpactWrapper>
      <Heading5>Impact</Heading5>
      {cvssScore && (
        <EvidenceLine isExtendedWidth label="CVSS">
          <SeverityTag riskLevel={severity}>{cvssScore.toFixed(2)}</SeverityTag>
        </EvidenceLine>
      )}
      {Boolean(cweIdentifiers.length) && (
        <EvidenceLine isExtendedWidth label="CWE">
          <ElementSeparator separator=", ">
            {cweIdentifiers.map((cwe: string) => (
              <CweLink key={cwe} cwe={cwe} />
            ))}
          </ElementSeparator>
        </EvidenceLine>
      )}
      {Boolean(cveIdentifiers.length) && (
        <EvidenceLine isExtendedWidth label="Additional Information">
          <CveWrapper>
            {cveIdentifiers.map((cve: string) => (
              <CveLink key={cve} cve={cve} />
            ))}
          </CveWrapper>
        </EvidenceLine>
      )}
    </ImpactWrapper>
  );
};

const CveWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Likelihood = () => {
  const {
    cveEntity: { exploitMaturity, epssSeverity, epss, pocReferences },
  } = useCveEntityContext();

  const epssText = useMemo(
    () => (
      <EpssText data-disabled={dataAttr(!isExploitMaturity(exploitMaturity))}>
        {exploitMaturity}
      </EpssText>
    ),
    [exploitMaturity]
  );

  return (
    <LikelihoodWrapper>
      <Heading5>Likelihood</Heading5>
      {exploitMaturity && (
        <EvidenceLine isExtendedWidth label="Exploit maturity">
          <Tooltip
            interactive
            disabled={!isExploitMaturity(exploitMaturity)}
            content={
              <TooltipContent>
                <ExploitMaturityTooltipContent
                  exploitMaturity={exploitMaturity}
                  pocReferences={Object.values(pocReferences)[0] as Record<string, string[]>}
                />
              </TooltipContent>
            }>
            {exploitMaturity === 'No exploit maturity data' ? (
              <>{epssText}</>
            ) : (
              <SeverityTag riskLevel="high">{exploitMaturity}</SeverityTag>
            )}
          </Tooltip>
        </EvidenceLine>
      )}
      {epssSeverity && epss?.epssScore && (
        <EvidenceLine
          isExtendedWidth
          label={
            <>
              EPSS score
              <InfoTooltip
                content={
                  <>
                    The Exploit Prediction Scoring System (EPSS) is a tool for estimating <br /> the
                    likelihood that a vulnerability will be exploited in the wild during the next 30
                    days.
                    <br /> A higher EPSS score indicates a higher chance of exploitation.
                    <br />
                    Percentile indicates how likely a vulnerability is to be exploited compared to
                    other vulnerabilities.
                    <br /> A vulnerability with an EPSS percentile of 90% means it has a higher
                    probability score than 90% of all other vulnerabilities.
                  </>
                }
              />
            </>
          }>
          <EpssScore>
            <SeverityTag riskLevel={epssSeverity}>{epss.epssScore}</SeverityTag>
            {epssSeverity}
          </EpssScore>
        </EvidenceLine>
      )}
      {Boolean(epss?.percentile) && (
        <EvidenceLine
          isExtendedWidth
          label={
            <>
              EPSS percentile
              <InfoTooltip
                content={
                  <>
                    Indicates how likely a vulnerability is to be exploited compared to other
                    vulnerabilities.
                    <br />A vulnerability with an EPSS percentile of 90% means it has a higher
                    probability <br />
                    score than 90% of all other vulnerabilities
                  </>
                }
              />{' '}
            </>
          }>
          {(epss.percentile * 100).toFixed(2)}%
        </EvidenceLine>
      )}
    </LikelihoodWrapper>
  );
};

const EpssText = styled.span`
  &[data-disabled] {
    color: var(--color-blue-gray-35);
  }
`;

const CardBody = styled.div`
  display: flex;
`;

export const EpssScore = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LikelihoodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3rem;
`;

const ImpactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3rem;
`;

const VerticalLine = styled.div`
  border-left: 1px solid var(--color-blue-gray-20);
  margin: 0 6rem;
`;
