import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import styled from 'styled-components';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { FontBodyBold } from '@src/style/common';
import { getApiText } from '@src/utils/apiUtils';
import { StyledHighlightedCode, StyledWrappedTitledList } from './Styles';

const StyledApiMethodName = styled(PopoverTip)`
  ${FontBodyBold};
`;

const DataModelAccessMaterialChange = ({ materialChange }) => {
  const { name } = materialChange.codeReference;
  const { exposingMethods, involvingMethods } = materialChange;

  const generateUsagesForType = (methods, verb) => (
    <StyledWrappedTitledList
      title={
        <span>
          {verb} in the following {methods.length === 1 ? 'API' : 'APIs'} :{' '}
        </span>
      }
      list={map(methods, (method, index) => (
        <StyledApiMethodName
          key={index}
          title="API"
          linkText={getApiText(method.httpMethod, method.httpRoute, method.methodName)}>
          <StyledHighlightedCode language="java" code={method.methodSignature}>
            {method.methodSignature}
          </StyledHighlightedCode>
        </StyledApiMethodName>
      ))}
    />
  );

  const generateUsageList = (exposingMethods, involvingMethods) => {
    const list = [];
    !isEmpty(exposingMethods) && list.push(generateUsagesForType(exposingMethods, 'Exposed'));
    !isEmpty(involvingMethods) && list.push(generateUsagesForType(involvingMethods, 'Involved'));
    return list;
  };

  return (
    <StyledWrappedTitledList
      title={
        <HorizontalStack>
          <span>Data model</span>
          <Bold>{name}</Bold>
          <span>was accessed by an API for the first time</span>
        </HorizontalStack>
      }
      list={generateUsageList(exposingMethods, involvingMethods)}
    />
  );
};

export default DataModelAccessMaterialChange;
