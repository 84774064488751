import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import selectPlugin from '@rematch/select';
import noop from 'lodash/noop';
import ioc from '@src-v2/ioc';
import assetCollectionConfigurationPane from './blocks/AssetCollectionConfigurationPane/model';
import assetCollectionPage from './blocks/AssetCollectionPage/model';
import buildPage from './blocks/BuildPage/model';
import clientTableInfiniteScroll from './blocks/ClientTableInfiniteScroll/model';
import commitPage from './blocks/CommitPage/model';
import dependencyPane from './blocks/DependencyPane/model';
import deployKeysEnricher from './blocks/DeployKeysEnricher/model';
import developerPage from './blocks/DeveloperPage/model';
import elementTimeline from './blocks/ElementTimeline/model';
import elementTimelineSelector from './blocks/ElementTimelineSelector/model';
import gitReferencesAutoComplete from './blocks/GitReferencesAutoComplete/model';
import governancePage from './blocks/GovernancePage/model';
import insightsPaneTitle from './blocks/InsightsPaneTitle/model';
import issuesEnricher from './blocks/IssuesEnricher/model';
import modulePage from './blocks/ModulePage/model';
import multiSelectFilter from './blocks/MultiSelectFilter/model';
import pane from './blocks/Pane/model';
import processTagSelector from './blocks/ProcessTagSelector/model';
import releasePage from './blocks/ReleasePage/model';
import repositoryPage from './blocks/RepositoryPage/model';
import riskProfilesPage from './blocks/RiskProfilesPage/model';
import riskRuleTrigger from './blocks/RiskRuleTrigger/model';
import riskRuleTriggers from './blocks/RiskRuleTriggers/model';
import riskyIssueDefinition from './blocks/RiskyIssueDefinition/model';
import rolesPage from './blocks/RolesPage/model';
import serverTableInfiniteScroll from './blocks/ServerTableInfiniteScroll/model';
import tableSearch from './blocks/TableSearch/model';
import timelineInfiniteScroll from './blocks/TimelineInfiniteScroll/model';
import workflowsPage from './blocks/WorkflowsPage/model';
import * as models from './models';

export const { history } = ioc;

const safeInit = config => {
  try {
    return init(config);
  } catch (exception) {
    console.error(exception);
    return { select: noop, dispatch: noop, subscribe: noop, getState: noop, initFailed: true };
  }
};

const store = safeInit({
  models: {
    ...models,
    tableSearch,
    multiSelectFilter,
    timelineInfiniteScroll,
    clientTableInfiniteScroll,
    serverTableInfiniteScroll,
    dependencyPane,
    developerPage,
    elementTimeline,
    elementTimelineSelector,
    governancePage,
    repositoryPage,
    riskRuleTriggers,
    riskRuleTrigger,
    riskProfilesPage,
    riskyIssueDefinition,
    assetCollectionPage,
    assetCollectionConfigurationPane,
    pane,
    workflowsPage,
    commitPage,
    releasePage,
    modulePage,
    gitReferencesAutoComplete,
    processTagSelector,
    rolesPage,
    buildPage,
    issuesEnricher,
    deployKeysEnricher,
    insightsPaneTitle,
  },
  plugins: [createLoadingPlugin({}), selectPlugin()],
});

export const { select } = store;
export const { dispatch } = store;
export const { getState } = store;
export default store;

if (module.hot) {
  window.dispatch = dispatch;
}
