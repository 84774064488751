import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Divider } from '@src-v2/components/divider';
import { riskOrder } from '@src/services/riskService';
import { HorizontalStack } from './HorizontalStack';
import MaterialChange from './MaterialChange/MaterialChange';
import MaterialChangeActions from './MaterialChange/MaterialChangeActions';

const EmptyMaterialChangeList = styled.div`
  width: 100%;
  text-align: center;
`;

const MaterialChangeInfo = styled.div`
  flex: 1;
  ${props => (props.isIgnored ? `opacity: 0.5;` : '')};
`;

const MaterialChangeWrapper = styled(HorizontalStack)`
  align-items: start;
  padding: 6px;
  margin-bottom: 6rem;

  &:hover {
    background-color: var(--color-blue-gray-15);
  }
`;

const StyledDivider = styled(Divider)`
  padding: 3rem 0;
`;

export const MaterialChanges = ({
  commitSha,
  repository,
  governanceRules,
  materialChangesByRuleKey,
  selectedMaterialChangeKeys,
  developerProfileByIdentityKey,
  isReleaseContext = false,
}) => {
  if (isEmpty(governanceRules)) {
    return (
      <EmptyMaterialChangeList>
        No material changes match the selected filters
      </EmptyMaterialChangeList>
    );
  }

  const orderedRules = orderBy(
    governanceRules,
    [
      rule => rule.isAutoIgnored,
      rule => (rule.then.type !== 'Risk' ? 0 : riskOrder[rule.then.value]),
    ],
    ['asc', 'desc']
  );
  return (
    <div>
      {orderedRules.map((governanceRule, index) => (
        <Fragment key={governanceRule.key}>
          {index > 0 && <StyledDivider />}
          {map(materialChangesByRuleKey[governanceRule.key], (materialChanges, ruleThenIndex) => {
            const thenSubType = governanceRule.then[ruleThenIndex]?.subType;
            return orderBy(materialChanges, materialChange => materialChange.orderByValue)
              .filter(
                materialChange =>
                  isEmpty(selectedMaterialChangeKeys) ||
                  includes(selectedMaterialChangeKeys, materialChange.key)
              )
              .map(materialChange => (
                <MaterialChangeWrapper
                  key={materialChange.key}
                  isIgnored={governanceRule.isAutoIgnored}>
                  <MaterialChangeInfo isIgnored={governanceRule.isAutoIgnored}>
                    <MaterialChange
                      governanceRule={governanceRule}
                      thenSubType={thenSubType}
                      materialChange={materialChange}
                      repository={repository}
                      commitSha={commitSha}
                      developerProfileByIdentityKey={developerProfileByIdentityKey}
                    />
                  </MaterialChangeInfo>
                  <MaterialChangeActions
                    materialChange={materialChange}
                    governanceRule={governanceRule}
                    thenSubType={thenSubType}
                    isReleaseContext={isReleaseContext}
                  />
                </MaterialChangeWrapper>
              ));
          })}
        </Fragment>
      ))}
    </div>
  );
};
