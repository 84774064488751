import styled from 'styled-components';
import { ItemProps, SelectControl } from '@src-v2/components/forms/form-controls';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';

export const severityItems: ItemProps[] = [
  { label: 'Critical', key: 'Critical', icon: <RiskIcon riskLevel="Critical" /> },
  { label: 'High', key: 'High', icon: <RiskIcon riskLevel="High" /> },
  { label: 'Medium', key: 'Medium', icon: <RiskIcon riskLevel="Medium" /> },
  { label: 'Low', key: 'Low', icon: <RiskIcon riskLevel="Low" /> },
];

export const SeveritySelect = () => (
  <SelectControl
    name="newFinding.severity"
    items={severityItems}
    searchable={false}
    clearable={false}
    renderItemContent={({ item }: { item: { label: string; value: any } }) => (
      <SeverityWrapper>
        {item.value.icon}
        {item.label}
      </SeverityWrapper>
    )}
    placeholder="Select risk level..."
    rules={{ required: true }}
  />
);

const SeverityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
