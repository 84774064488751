import _ from 'lodash';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Circle } from '@src-v2/components/circles';
import { SvgIcon } from '@src-v2/components/icons';
import { useDetectClickOutside, useForwardRef } from '@src-v2/hooks';
import { useDetectEscapePress } from '@src-v2/hooks/dom-events/use-detect-escape-press';
import { customScrollbar } from '@src-v2/style/mixins';
import { RiskIndicator } from '@src/blocks/RiskPosture/blocks/OpenPaneIndicator';
import { ScrollContext, StyledInfiniteScroll } from '@src/components/InfiniteScroller';
import PaneHeader from '@src/components/PaneHeader';
import { StyledCell, StyledCellContentWrapper, StyledRow } from '@src/components/Table';

const StyledContainer = styled.div`
  position: relative;
  z-index: 1000;
`;

const StyledBackground = styled.div`
  position: fixed;
  inset: 0;
  display: none;
  background-color: var(--overlay-dark-color);
  animation: fadeInOpacity 200ms ease-in;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const slideInAnimation = keyframes`
  100% {
    transform: translateX(0%);
  }
`;

const Handle = styled.div`
  display: none;
  height: 18px;
  width: 18px;
  padding: 0 3px;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const HandleDot = styled.div`
  display: flex;
  height: 2px;
  width: 2px;
  margin: 0 0 3px 3px;
  background-color: var(--default-text-color);
`;

const StyledModal = styled.div`
  display: flex;
  position: fixed;
  z-index: 10;
  height: 100%;
  min-width: 40vw;
  right: 0;
  top: 0;
  background: ${props => (props.isContent ? 'var(--color-blue-gray-15)' : 'var(--color-white)')};
  box-shadow: 0 2px 24px 0 rgba(186, 202, 213, 0.3);
  overflow-x: visible;
  opacity: 1;
  width: ${props => `${props.$widthPercentage}vw`};
  transform: translateX(100%);
  animation: 0.1s ease-in forwards ${slideInAnimation};

  &:hover {
    ${Handle} {
      display: flex;
    }

    + ${StyledBackground} {
      display: inline-block;
    }
  }
`;

const StyledControlArea = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 18px;
  background-color: var(--color-blue-gray-15);
`;

const StyledCloseButton = styled(Circle)`
  position: absolute;
  left: -18px;
  top: 3rem;
  background-color: var(--color-blue-gray-15);
  cursor: pointer;
  box-shadow: 0 2px 10px 0 var(--color-blue-gray-15);
`;

const StyledResizeHandle = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const StyledPage = styled.div`
  width: 100%;
  padding-bottom: 9rem;
  flex-grow: 1;

  ${customScrollbar};
`;

const StyledPaneBody = styled.div`
  position: relative;
  display: flex;
  min-height: 100%;
  margin: 0 12rem;
  flex-flow: column;

  ${StyledInfiniteScroll} {
    border-radius: 0 0 3rem 3rem;
    margin-bottom: 0;

    ${StyledRow} {
      padding: 0;
      margin-bottom: 0.25rem;
      box-shadow: var(--elevation-0);

      &:last-child {
        margin-bottom: 0;
        border-radius: 0 0 3rem 3rem;

        ${StyledCell} {
          &:first-child ${RiskIndicator} {
            border-bottom-left-radius: 3rem;
            overflow: hidden;
          }

          &:last-child {
            border-bottom-right-radius: 3rem;
            overflow: hidden;
          }
        }
      }
    }

    ${StyledCell} {
      padding: 0;
    }

    ${StyledCellContentWrapper} {
      box-shadow: none;
    }
  }
`;

export function PaneBody({ title, overview, actions, body, ...props }) {
  const scrollRef = useRef();
  return (
    <ScrollContext.Provider value={() => scrollRef.current}>
      <StyledPage ref={scrollRef} {...props} data-scrollbar-gutters>
        <PaneHeader title={title} overview={overview} actions={actions} />
        <StyledPaneBody>{body}</StyledPaneBody>
      </StyledPage>
    </ScrollContext.Provider>
  );
}

const PaneDisplay = forwardRef(
  (
    {
      pathname,
      innerPane,
      content,
      widthPercentage,
      closePane,
      setWidthPercentage,
      relevantPath,
      listenToEvents = true,
      secondaryPaneRef,
    },
    ref
  ) => {
    const dragRef = useRef(null);
    const innerRef = useForwardRef(ref);
    const [originalPathname] = useState(pathname);

    useDetectClickOutside(
      [innerRef, secondaryPaneRef],
      useCallback(
        event => {
          if (
            !document.getElementById('modal').contains(event.target) &&
            !document.querySelector(`[data-tippy-root]`)?.contains(event.target)
          ) {
            closePane();
          }
        },
        [closePane]
      )
    );

    useEffect(() => {
      if (_.isNil(relevantPath)) {
        if (pathname !== originalPathname) {
          closePane();
        }
      } else if (
        !pathname.includes(`/${relevantPath}/`) &&
        !pathname.endsWith(`/${relevantPath}`)
      ) {
        closePane();
      }
    }, [closePane, originalPathname, pathname, relevantPath]);

    const onMouseDown = (element, event) => {
      if (!listenToEvents || event.button !== 0) {
        return;
      }

      event.preventDefault();
      document.body.classList.add('dragging');

      const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
        setTimeout(() => document.body.classList.remove('dragging'));
      };
      const handleMouseMove = event =>
        setWidthPercentage(Math.floor(100 * (1 - event.clientX / window.innerWidth)));

      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleMouseMove);
    };

    return (
      <StyledContainer>
        <StyledModal $widthPercentage={widthPercentage} isContent={innerPane} ref={innerRef}>
          <StyledControlArea>
            <StyledCloseButton size="large" onClick={closePane}>
              <SvgIcon name="Close" />
            </StyledCloseButton>
            <StyledResizeHandle
              ref={dragRef}
              onMouseDown={event => onMouseDown(dragRef.current, event)}>
              <Handle>
                <HandleDot />
                <HandleDot />
                <HandleDot />
                <HandleDot />
                <HandleDot />
                <HandleDot />
              </Handle>
            </StyledResizeHandle>
          </StyledControlArea>
          {content}
        </StyledModal>
        <StyledBackground />
      </StyledContainer>
    );
  }
);

export function Pane({
  primaryPane,
  secondaryPane,
  setPrimaryWidthPercentage,
  setSecondaryWidthPercentage,
  closePrimaryPane,
  closeSecondaryPane,
}) {
  const { pathname } = useLocation();
  const secondaryPaneRef = useRef();

  useDetectEscapePress(
    secondaryPane?.isOpen ? closeSecondaryPane : closePrimaryPane,
    primaryPane?.isOpen
  );

  if (!primaryPane?.isOpen) {
    return null;
  }

  return (
    <>
      <PaneDisplay
        pathname={pathname}
        setWidthPercentage={setPrimaryWidthPercentage}
        closePane={closePrimaryPane}
        listenToEvents={!secondaryPane?.isOpen}
        secondaryPaneRef={secondaryPaneRef}
        {...primaryPane}
      />
      {secondaryPane?.isOpen && (
        <PaneDisplay
          ref={secondaryPaneRef}
          pathname={pathname}
          setWidthPercentage={setSecondaryWidthPercentage}
          closePane={closeSecondaryPane}
          {...secondaryPane}
        />
      )}
    </>
  );
}
