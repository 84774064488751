import styled from 'styled-components';
import { IconButton } from '@src-v2/components/buttons';
import { useCollapsible } from '@src-v2/hooks/use-collapsible';
import { classNames } from '@src-v2/utils/style-utils';

function PlainGroupedListGroup({
  title,
  className,
  initialExpand = false,
  lazyLoad = false,
  children,
  onToggle,
}) {
  const { isOpen, shouldRender, getTriggerProps, getContentProps } = useCollapsible({
    open: initialExpand,
  });

  return (
    <div>
      <GroupHeader
        className={classNames(className, { isOpen })}
        {...getTriggerProps({ onClick: onToggle })}>
        <GroupHeader.Chevron name="Chevron" />

        <GroupHeader.Title>{title}</GroupHeader.Title>
      </GroupHeader>

      <GroupBody {...getContentProps()}>{(!lazyLoad || shouldRender) && children}</GroupBody>
    </div>
  );
}

export const GroupedList = styled.div`
  overflow: scroll;

  background-color: var(--overlay-light-color);

  border-radius: var(--table-border-radius, 5rem);
  border: 0.25rem solid var(--color-blue-gray-30);
`;

GroupedList.Group = styled(PlainGroupedListGroup)``;

GroupedList.Item = styled.div`
  padding: 2rem 6rem;

  font-size: var(--font-size-m);
  font-weight: 400;

  border-bottom: 1px solid var(--color-blue-gray-25);
  
  &:hover {
    background: var(--color-blue-15);
    box-shadow: var(--elevation-0);
    border-color: (--color-blue-30);
`;

const GroupHeader = styled.div`
  display: flex;
  flex-direction: row;

  padding: 2rem 7rem;
  gap: 3rem;

  background: var(--color-blue-gray-20);
  color: var(--color-blue-gray-70);

  border-bottom: 1px solid var(--color-blue-gray-25);

  font-weight: 700;
  font-size: var(--font-size-m);
`;

GroupHeader.Title = styled.div`
  flex-grow: 1;
`;

GroupHeader.Chevron = styled(IconButton)`
  transition: transform 400ms;

  ${GroupHeader}.is-open & {
    transform: rotate(90deg);
  }
`;

const GroupBody = styled.div``;
