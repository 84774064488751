import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import ioc from '@src-v2/ioc';
import apiService from '@src/services/apiService';
import rulesService from '@src/services/rulesService';

const clearData = () => ({
  fetched: false,
  editedWorkflow: null,
  editWorkflowIsOpen: false,
  editErrorMessage: null,
  workflows: [],
  disableRefreshOnPushNotification: true,
});

export default {
  state: clearData(),
  reducers: {
    setFetched: state => ({ ...state, fetched: true }),
    addWorkflow: state => ({ ...state, editWorkflowIsOpen: true }),
    closeEditWorkflow: state => ({
      ...state,
      editedWorkflow: null,
      editWorkflowIsOpen: false,
      editErrorMessage: null,
    }),
    setWorkflows: (state, workflows, options) => {
      each(workflows, workflow => {
        try {
          rulesService.groupSameTypeValues(
            rulesService.removeUnsupportedThenProperties(workflow, options.then),
            options
          );
        } catch (error) {
          workflow.isInvalid = true;
        }
      });
      return {
        ...state,
        workflows,
      };
    },
    setOptions: (state, options) => ({
      ...state,
      options,
    }),
    setEditedWorkflow: (state, editedWorkflow) => ({
      ...state,
      editWorkflowIsOpen: true,
      editedWorkflow: cloneDeep(editedWorkflow),
    }),
    setEditErrorMessage: (state, errorMessage) => ({
      ...state,
      editErrorMessage: errorMessage,
    }),
    clearData,
  },
  selectors: slice => ({
    workflows: () => slice(state => state.workflows),
    editedWorkflow: () => slice(state => state.editedWorkflow),
    editWorkflowIsOpen: () => slice(state => state.editWorkflowIsOpen),
    editErrorMessage: () => slice(state => state.editErrorMessage),
    options: () => slice(state => state.options),
  }),
  effects: dispatch => ({
    async fetchWorkflows(invalidateCache) {
      return (
        await apiService.get('/api/workflows/workflows', { clearCacheEntry: invalidateCache })
      ).data;
    },
    async fetchOptions(invalidateCache) {
      return (await apiService.get('/api/workflows/options', { clearCacheEntry: invalidateCache }))
        .data;
    },
    async fetchData({ invalidateCache }) {
      const [workflows, options] = await Promise.all([
        this.fetchWorkflows(invalidateCache),
        this.fetchOptions(invalidateCache),
      ]);
      const developerRepresentativeKeys = workflows
        .map(workflow => workflow.developerRepresentativeKeys)
        .flat();
      await dispatch.developerProfiles.getDevelopersByRepresentativeKeysAsync({
        keys: developerRepresentativeKeys,
      });
      this.setFetched();
      this.setOptions(options);
      this.setWorkflows(workflows, options);
    },
    async applyWorkflowModifications(workflow) {
      try {
        const workflowToSend = rulesService.ungroupSameTypeValues(workflow);
        await apiService.put(`/api/workflows/workflows/${workflowToSend.key}`, workflowToSend);
        await this.fetchData({ invalidateCache: true });
        ioc.asyncCache.invalidate(ioc.workflows.getWorkflowRecipes);
        return true;
      } catch (error) {
        if (error.response.status === 400) {
          this.setEditErrorMessage(error.response.data?.message ?? error.response.data);
        } else {
          this.setEditErrorMessage(error);
        }
        return false;
      }
    },
    async deleteWorkflow(deletedWorkflow) {
      try {
        await apiService.delete(`/api/workflows/workflows/${deletedWorkflow.key}`);
        await this.fetchData({ invalidateCache: true });
      } catch (error) {
        dispatch.toast.error(error);
      }
    },
  }),
};
