import { format } from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { dateFormats } from '@src-v2/data/datetime';
import { useQueryParams } from '@src-v2/hooks';
import { FilterButtons } from '@src/components/FilterButtons';
import { InfiniteScroller } from '@src/components/InfiniteScroller';
import { Timeline } from './components/Timeline';

const LoadingContainer = styled.div`
  text-align: center;
  margin-top: 9rem;
`;

export const TimelineInfiniteScroll = ({
  table,
  entityKey,
  subEntityKey,
  clearData,
  fetchData,
  fetchTimelineDataPage,
  onFilterChanged,
  timeline,
  hideRepository,
  hideDeveloper,
  projectProfilesByKey,
  repositoryProfilesByKey,
  providerRepositoriesByKey,
  developerProfileByIdentityKey,
  timelineFilterOptions,
  defaultFilter,
  className,
}) => {
  const { updateQueryParams } = useQueryParams();

  useEffect(() => {
    fetchData({ table, entityKey, subEntityKey, invalidateCache: false });
    return clearData;
  }, [fetchData, entityKey, subEntityKey, table, clearData]);

  const onFilterChangedCallback = useCallback(
    (filters, fromDate, toDate) => {
      onFilterChanged({ table, entityKey, subEntityKey, filters, fromDate, toDate });

      const clearDates = !fromDate || !toDate;
      updateQueryParams({
        labels: _.isEmpty(filters) ? null : JSON.stringify(filters),
        fromDate: clearDates ? null : `"${format(fromDate, dateFormats.serverDate)}"`,
        toDate: clearDates ? null : `"${format(toDate, dateFormats.serverDate)}"`,
      });
    },
    [table, entityKey, subEntityKey, onFilterChanged]
  );

  if (_.isNil(timelineFilterOptions)) {
    return (
      <LoadingContainer>
        <LogoSpinner />
      </LoadingContainer>
    );
  }

  return defaultFilter.name ? (
    <FilterButtons
      defaultFilter={defaultFilter}
      onFilterChanged={onFilterChangedCallback}
      extraFilterToCountMapping={timelineFilterOptions}
      multiselect
      hasDateRange>
      {(selectedFilters, selectedDateRange) => (
        <InfiniteScroller
          scrollParent="main"
          parentFilters={selectedFilters}
          searchFunc={pageCount =>
            fetchTimelineDataPage({
              table,
              entityKey,
              subEntityKey,
              currentPage: pageCount,
              filters: selectedFilters,
              fromDate: selectedDateRange.fromDate,
              toDate: selectedDateRange.toDate,
            })
          }>
          {isLoading => (
            <Timeline
              timelineEvents={timeline}
              materialChangeFilters={selectedFilters}
              noItems={!isLoading && _.isEmpty(timeline)}
              noItemsMessage="No material changes identified yet"
              hideRepository={hideRepository}
              hideDeveloper={hideDeveloper}
              repositoryProfilesByKey={repositoryProfilesByKey}
              providerRepositoriesByKey={providerRepositoriesByKey}
              projectProfilesByKey={projectProfilesByKey}
              developerProfileByIdentityKey={developerProfileByIdentityKey}
              className={className}
              subEntityKey={subEntityKey}
            />
          )}
        </InfiniteScroller>
      )}
    </FilterButtons>
  ) : null;
};
