const defaultLocale = 'en-GB';

const numberAbbreviations = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];

export function abbreviate(number, fraction = 1) {
  const { value, symbol } = numberAbbreviations.reduce((result, item) =>
    number >= item.value ? item : result
  );
  return (
    (number / value).toLocaleString(defaultLocale, {
      maximumFractionDigits: fraction,
    }) + symbol
  );
}

export function formatNumber(number, fraction = 2) {
  return Number(number).toLocaleString(defaultLocale, {
    maximumFractionDigits: fraction,
  });
}

export function toPercent(number, fraction = 1, plusSymbol = false) {
  const percent = `${formatNumber(number * 100, fraction)}%`;
  return plusSymbol && number > 0 ? `+${percent}` : percent;
}

export function pluralFormat(number, single, plural = undefined, withNumber = false) {
  const stringResult = number === 1 ? single : plural ?? `${single}s`;
  return withNumber ? `${number} ${stringResult}` : stringResult;
}
