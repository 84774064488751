import styled from 'styled-components';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import {
  CommitCodeReference,
  thenSubTypeToVerb,
} from '@src/components/MaterialChange/MaterialChangeUtils';
import { StyledWrappedTitledList } from '@src/components/MaterialChange/Styles';
import { FontBodySmall } from '@src/style/common';

const StyledOperationType = styled.div`
  ${FontBodySmall};
`;

export const GqlOperationMaterialChange = ({
  materialChange,
  thenSubType,
  repository,
  commitSha,
}) => {
  const { relativeFilePath, lineNumber } = materialChange.codeReference;

  return (
    <StyledWrappedTitledList
      title={
        <HorizontalStack>
          <span>GraphQL </span>
          <StyledOperationType>{materialChange.gqlOperationType}</StyledOperationType>
          <span> operation </span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}
            lineNumber={lineNumber}>
            {materialChange.gqlOperationName}
          </CommitCodeReference>
          <span>was</span>
          <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
        </HorizontalStack>
      }
      list={materialChange.descriptions}
    />
  );
};
