import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MotionAnimation } from '@src-v2/components/animations/motion-animation';
import { ClampPath, ClampText } from '@src-v2/components/clamp-text';
import { SvgIcon } from '@src-v2/components/icons';
import { ErrorLayout } from '@src-v2/components/layout';
import { SvgArrow } from '@src-v2/components/svg/svg-arrow';
import { Table } from '@src-v2/components/table/table';
import { TableHeader } from '@src-v2/components/table/table-header';
import { DateTime } from '@src-v2/components/time';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Paragraph } from '@src-v2/components/typography';
import { HelpModal } from '@src-v2/containers/modals/help-modal';
import { ConsumableProfileView } from '@src-v2/containers/profiles/consumable-profiles-view';
import { RiskIconCell } from '@src-v2/containers/risks/risks-common-cells';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { useTable } from '@src-v2/hooks/use-table';
import { StyledManageRisk } from './ManageRiskTable';

export const PullRequestsTable = ({
  profile,
  profileType,
  description = 'View risks in pull requests',
}) => {
  const history = useHistory();
  const { profiles } = useInject();

  const columns = useMemo(() => {
    const profileColumns = [...tableColumns];
    if (profileType === 'ApplicationProfile') {
      profileColumns.splice(1, 0, {
        label: 'Repository',
        Cell: ({ data, ...props }) => (
          <PullRequestRepositoryCell
            {...props}
            repository={profile.repositoryByKey[data.repositoryKey]}
          />
        ),
      });
    }

    return profileColumns.map(column => ({ ...column, resizeable: false }));
  }, [profileType, profile, tableColumns]);

  const tableModel = useTable({
    tableColumns: columns,
    hasReorderColumns: false,
  });

  const profilePullRequests = useSuspense(profiles.getProfilePullRequests, {
    profileKey: profile.key,
    profileType,
  });

  return (
    <StyledManageRisk>
      <Paragraph>{description}</Paragraph>
      <Table>
        <TableHeader tableModel={tableModel} />
        <Table.Body>
          {profilePullRequests?.length === 0 ? (
            <Table.EmptyMessage colSpan={columns.length}>
              <ErrorLayout.NoResults data-contained />
            </Table.EmptyMessage>
          ) : (
            profilePullRequests.map((pr, index) => (
              <Table.Row
                key={index}
                onClick={
                  pr.isDone || pr.isDiffedForPrComments
                    ? () => history.push(`/releases/${pr.key}`)
                    : null
                }>
                {tableModel.columns?.map(({ Cell, ...column }) => (
                  <Cell key={column.label} data={pr} />
                ))}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </StyledManageRisk>
  );
};

const PullRequestRiskCell = ({ data, ...props }) => {
  const [modalElement, setModal, closeModal] = useModalState();

  const handleContactHelpClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      setModal(
        <HelpModal onClose={closeModal} subject={`PR Scan failed - PR #${data.pullRequestId}`} />
      );
    },
    [data, setModal, closeModal]
  );

  if (data.isDone) {
    return (
      <RiskIconCell
        {...props}
        hasOverride={false}
        data={{ riskLevel: data.combinedMaterialChangesRiskLevel ?? 'None' }}
      />
    );
  }

  return (
    <>
      <Table.FlexCell {...props}>
        <Tooltip
          interactive
          content={
            data.status.startsWith('Error') ? (
              <>
                Pull Request scanning failed.{' '}
                <TooltipDescription>
                  If the problem persists,
                  <TriggerModalButton onClick={handleContactHelpClick}>
                    contact Apiiro support
                  </TriggerModalButton>
                </TooltipDescription>
              </>
            ) : (
              `In Progress${data.isDiffedForPrComments ? ' - partial results ready' : ''}`
            )
          }>
          {data.status.startsWith('Error') ? (
            <ReportIcon name="Report" onClick={handleContactHelpClick} />
          ) : (
            <MotionAnimation size={3} width={2} margin={3} />
          )}
        </Tooltip>
      </Table.FlexCell>
      {modalElement}
    </>
  );
};

const tableColumns = [
  {
    label: 'Risk',
    width: '15rem',
    Cell: PullRequestRiskCell,
  },
  {
    label: 'PR title',
    Cell: ({ data, ...props }) => (
      <Table.FlexCell {...props}>
        <ClampText limit={2}>{data.name}</ClampText>
      </Table.FlexCell>
    ),
  },
  {
    label: 'Branches',
    width: '120rem',
    Cell: styled(({ data, ...props }) => {
      return (
        <Table.FlexCell {...props}>
          <ClampBranchName>{data.candidate.identifier}</ClampBranchName> <BranchesArrow />{' '}
          <ClampBranchName>{data.baseline.identifier}</ClampBranchName>
        </Table.FlexCell>
      );
    })`
      white-space: nowrap;
      font-weight: 300;
    `,
  },
  {
    label: 'PR #',
    width: '15rem',
    Cell: ({ data, ...props }) => <Table.Cell {...props}>{data.pullRequestId}</Table.Cell>,
  },
  {
    label: 'Created on',
    width: '60rem',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <DateTime date={data.createdAt} />{' '}
      </Table.Cell>
    ),
  },
];

const PullRequestRepositoryCell = ({ repository, ...props }) => (
  <Table.Cell {...props}>
    <ConsumableProfileView
      profile={useMemo(
        () => ({
          key: repository.key,
          name: repository.uniqueName ?? repository.name,
          referenceName: repository.referenceName,
          url: repository.url,
          serverUrl: repository.serverUrl,
          vendor: repository.server?.provider,
          type: 'RepositoryProfile',
        }),
        [repository]
      )}
      showArchivedIndicator={false}
      isActive={null}
    />
  </Table.Cell>
);

const BranchesArrow = styled(SvgArrow)`
  width: 4rem;
`;

const ClampBranchName = styled(({ children, ...props }) => (
  <ClampPath {...props}>{children}</ClampPath>
))`
  max-width: calc(50% - 4rem) !important;
  width: fit-content !important;
  text-overflow: ellipsis;
`;

const TooltipDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const TriggerModalButton = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const ReportIcon = styled(SvgIcon)`
  color: var(--color-blue-gray-50);
`;
