import isEmpty from 'lodash/isEmpty';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { StyledVerticalStack } from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableDisplay';
import Bold from '@src/components/Bold';
import { Link } from '@src/components/Link';
import { Number } from '@src/components/Number';
import { StyledHorizontalStack } from './Styles';

const DockerfileMaterialChange = ({ repository, materialChange }) => (
  <StyledVerticalStack>
    <StyledHorizontalStack>
      <span>A change was made to dockerfile under path </span>
      <Link
        openInNewTab
        url={generateCodeReferenceUrl(repository, {
          relativeFilePath: materialChange.dockerfilePath,
        })}
        ellipsis>
        <Bold>{materialChange.dockerfilePath}</Bold>
      </Link>
    </StyledHorizontalStack>
    {!isEmpty(materialChange.newUsers) && (
      <StyledHorizontalStack>
        <Number value={materialChange.newUsers.length} one="New user is" other="New users are" />
        <div>
          used when running this Docker image: <Bold>{materialChange.newUsers}</Bold>
        </div>
      </StyledHorizontalStack>
    )}
    {!isEmpty(materialChange.newBaseImages) && (
      <StyledHorizontalStack>
        <Number
          value={materialChange.newBaseImages.length}
          one="New base image is"
          other="New base images are"
        />
        <div>
          newly used in this dockerfile <Bold>{materialChange.newBaseImages.toString()}</Bold>
        </div>
      </StyledHorizontalStack>
    )}
    {!isEmpty(materialChange.newExposedPorts) && (
      <StyledHorizontalStack>
        <Number value={materialChange.newExposedPorts.length} one="Port" other="Ports" />
        <div>
          newly exposed: <Bold>{materialChange.newExposedPorts.toString()}</Bold>
        </div>
      </StyledHorizontalStack>
    )}
    {materialChange.newAddCommandsCount > 0 && (
      <span>{materialChange.newAddCommandsCount} new Add commands used in this Dockerfile</span>
    )}
  </StyledVerticalStack>
);

export default DockerfileMaterialChange;
