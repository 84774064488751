import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { ExternalLink } from '@src-v2/components/typography';
import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';
import { TriggerBoxSmallTitle } from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Common';
import Bold from '@src/components/Bold';
import { thenSubTypeToVerb } from '@src/components/MaterialChange/MaterialChangeUtils';
import { VerticalStack } from '@src/components/VerticalStack';
import { HorizontalStack } from './HorizontalStack';
import { Link } from './Link';

const getDisplayName = (dependency, showVersions, name) => {
  return `${isEmpty(name) ? dependency.name : name}${
    showVersions && !isEmpty(dependency.resolvedVersion)
      ? ` (${dependency.resolvedVersion})`
      : showVersions && !isEmpty(dependency.version)
        ? ` (${dependency.version})`
        : ''
  }`;
};

const Dependency = ({
  dependency,
  repository,
  showVersions,
  commitSha,
  className,
  name,
  withLink = true,
}) => {
  return (
    <VerticalStack>
      {dependency.isSubDependency && <TriggerBoxSmallTitle>Sub-dependency</TriggerBoxSmallTitle>}
      {dependency.codeReference?.relativeFilePath &&
        (repository && commitSha ? (
          <CommitCodeReference
            commitSha={commitSha}
            repository={repository}
            relativeFilePath={dependency.codeReference.relativeFilePath}>
            {dependency.codeReference.relativeFilePath}
          </CommitCodeReference>
        ) : (
          <TriggerBoxSmallTitle>{dependency.codeReference.relativeFilePath}</TriggerBoxSmallTitle>
        ))}
      <HorizontalStack>
        {withLink ? (
          <Link
            external={dependency.isExternal}
            className={className}
            url={dependency.homePage}
            tip={dependency.isExternal && 'Dependency homepage'}
            disabled={!dependency.homePage}
            ellipsis
            openInNewTab>
            {getDisplayName(dependency, showVersions, name)}
          </Link>
        ) : (
          <div>{getDisplayName(dependency, showVersions, name)}</div>
        )}
      </HorizontalStack>
    </VerticalStack>
  );
};

export default Dependency;

export const DependencyMaterialChange = styled(
  ({ dependency, thenSubType, children, ...props }) => (
    <div {...props}>
      External dependency declaration{' '}
      {dependency.homePage ? (
        <ExternalLink href={dependency.homePage}>{dependency.name}</ExternalLink>
      ) : (
        <Bold>{dependency.name}</Bold>
      )}{' '}
      was <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
      {children}
    </div>
  )
)`
  display: flex;
  gap: 1rem;
`;
