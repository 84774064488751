import { StyledVerticalStack } from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableDisplay';
import Bold from '@src/components/Bold';
import { CommitCodeReference } from '@src/components/MaterialChange/MaterialChangeUtils';

const ServerlessFunctionMaterialChange = ({ commitSha, repository, serverlessFunction }) => {
  return (
    <StyledVerticalStack>
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={serverlessFunction.codeReference.relativeFilePath}>
        Function key: <Bold>{serverlessFunction.functionKey}</Bold>
      </CommitCodeReference>
      {serverlessFunction.provider && (
        <span>
          Provider: <Bold>{serverlessFunction.provider}</Bold>
        </span>
      )}
      {serverlessFunction.serviceKey && (
        <span>
          Service key: <Bold>{serverlessFunction.serviceKey}</Bold>
        </span>
      )}
    </StyledVerticalStack>
  );
};

export default ServerlessFunctionMaterialChange;
