import _ from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pane } from '@src-v2/components/panes/pane';
import { useInject } from '@src-v2/hooks';
import { primaryPaneLevel } from '@src/blocks/Pane/model';
import { ClusterLocationsView } from '@src/cluster-map-work/components/ClusterLocationsView';
import { dispatch } from '@src/store';

export function ShowOnClusterClusterMap({
  title,
  annotatedRepositoryAndModuleReferences,
  selectedNode,
}) {
  const [displayedLocations, setDisplayedLocations] = useState([]);
  const { clusters } = useInject();

  useEffect(async () => {
    await clusters.enrichWithClusterConnection(annotatedRepositoryAndModuleReferences);

    const allLocations = annotatedRepositoryAndModuleReferences.flatMap(
      annotatedRepositoryAndModuleReference =>
        annotatedRepositoryAndModuleReference.clusterMapLinksList?.flatMap(
          linkList => linkList.nodeLinks
        ) ?? []
    );

    setDisplayedLocations(_.uniqWith(allLocations, _.isEqual));
  }, [annotatedRepositoryAndModuleReferences]);

  return (
    <StyledPaneBody>
      <StyledPaneBodyHeader>{title}</StyledPaneBodyHeader>
      <ClusterLocationsView clusterLocations={displayedLocations} selectedNode={selectedNode} />
    </StyledPaneBody>
  );
}

const StyledPaneBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 9rem 12rem;
`;

const StyledPaneBodyHeader = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 6rem;
  line-height: 9rem;

  color: var(--color-blue-gray-70);
`;

export function openShowOnClusterMap(
  { annotatedRepositoryAndModuleReferences, title, selectedNode = undefined },
  paneState
) {
  const showOnClusterClusterMap = (
    <ShowOnClusterClusterMap
      annotatedRepositoryAndModuleReferences={annotatedRepositoryAndModuleReferences}
      title={title}
      selectedNode={selectedNode}
    />
  );

  paneState
    ? paneState.pushPane(<ClusterMapPane>{showOnClusterClusterMap}</ClusterMapPane>)
    : dispatch.pane.openPane({
        onClose() {},
        innerPane: true,
        level: primaryPaneLevel,
        content: showOnClusterClusterMap,
      });
}

const ClusterMapPane = styled(Pane)`
  background-color: var(--color-blue-gray-15);
`;
