import _ from 'lodash';
import { useRef } from 'react';
import styled from 'styled-components';
import { UnderlineButton } from '@src-v2/components/buttons';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Table } from '@src-v2/components/table/table';
import { TableHeader } from '@src-v2/components/table/table-header';
import { useToggle } from '@src-v2/hooks';
import { useCombineCallbacks } from '@src-v2/hooks/use-combine-callbacks';
import { customScrollbar } from '@src-v2/style/mixins';

export function CollapsibleTable<T>({
  onOpen,
  tableModel,
  items = [],
  compactRowsNumber = 10,
}: {
  tableModel: any;
  items: T[];
  onOpen?: () => void;
  compactRowsNumber?: number;
}) {
  const [isOpen, toggleIsOpen] = useToggle(items.length <= compactRowsNumber);

  const tableRef = useRef<HTMLTableElement>();

  const handleClick = useCombineCallbacks(toggleIsOpen, onOpen);

  return (
    <>
      <ScrollContainer>
        <Table ref={tableRef}>
          <TableHeader tableModel={tableModel} />
          {/*@ts-ignore*/}
          <Table.Body>
            {_.take(items, isOpen ? items.length : compactRowsNumber).map((item, index) => (
              // @ts-ignore
              <Table.Row key={index}>
                {tableModel.columns?.map(({ Cell, ...column }) => (
                  <Cell key={column.label} data={item} />
                ))}
                {/*@ts-ignore*/}
              </Table.Row>
            ))}
            {/*@ts-ignore*/}
          </Table.Body>
        </Table>
      </ScrollContainer>

      {items.length > compactRowsNumber && (
        <ToggleCollapsibleContainer isOpen={isOpen} onClick={handleClick}>
          <UnderlineButton>Show {isOpen ? 'less' : 'all'}</UnderlineButton>
          <SvgIcon name="Chevron" />
        </ToggleCollapsibleContainer>
      )}
    </>
  );
}

const ScrollContainer = styled.div`
  max-width: 100%;
  transition: height 400ms ease-in-out;
  overflow-x: auto;
  overflow-y: hidden;

  ${customScrollbar}
`;

const ToggleCollapsibleContainer = styled.div<{ isOpen: Boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: 1rem;
  cursor: pointer;
  font-size: var(--font-size-s);
  font-weight: 300;

  ${BaseIcon} {
    transform: ${props => `rotate(${props.isOpen ? '-' : ''}90deg)`};
  }
`;
