import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '@src-v2/assets/apiiro-logo.svg';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { Input, Radio } from '@src-v2/components/forms';
import { Combobox } from '@src-v2/components/forms/combobox';
import { TextareaControl } from '@src-v2/components/forms/form-controls';
import { Heading } from '@src-v2/components/typography';
import { useInject, useQueryParams } from '@src-v2/hooks';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { PageLoading } from '@src/components/PageLoading';
import apiService from '@src/services/apiService';

const syncForm = async (previousForm, formData, secret) => {
  const { questions, submitter, submitterMail } = formData;
  for (let i = 0; i < previousForm.questions.length; i++) {
    const question = previousForm.questions[i];
    const questionFormData = questions[question.key];
    question.answer = questionFormData?.answer ?? false;
    question.comment = questionFormData?.comment;
  }
  previousForm.submitter = submitter;
  previousForm.submitterMail = submitterMail;
  await apiService.put(
    `/api/questionnaires/form/${previousForm.key}?secret=${secret}`,
    previousForm
  );
};

const QuestionnaireForm = () => {
  const { formKey } = useParams();
  const { apiClient } = useInject();
  const {
    queryParams: { readOnly = false, secret },
  } = useQueryParams();
  const [{ questionnaireForm }, setFormData] = useState({
    configuration: null,
    questionnaireForm: null,
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  useEffect(
    () =>
      apiClient
        .get(`questionnaires/form/${formKey}?secret=${secret}`)
        .then(setFormData)
        .catch(() => {
          window.location.href = '/unauthorized';
        }),
    []
  );
  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: questionnaireForm,
  });

  if (submitted) {
    return <FormSubmitted goBack={() => setSubmitted(false)} />;
  }

  if (!questionnaireForm) {
    return <PageLoading />;
  }

  const onSubmit = async data => {
    setSubmitting(true);
    try {
      await syncForm(questionnaireForm, data, secret);
      setSubmitted(true);
    } finally {
      setSubmitting(false);
    }
  };

  const { title, commitSha, committer, changes, questions, submitter, submitterMail } =
    questionnaireForm;

  return (
    <Container>
      <ApiiroLogo />
      <h1>{title}</h1>
      <SubTitle>
        Commit <span>{commitSha}</span> by {committer} requires your attention
      </SubTitle>
      <Description>
        <p>
          Seems like this commit introduced <b>{changes.join(', ')}</b>
        </p>
        <p>Please answer whether this application complies with the following requirements:</p>
      </Description>

      <QuestionsContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <>{questions.map(question => questionToFormSection(question, register, readOnly))}</>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <SubmitBox>
              <SubmitContent>
                Submitted by
                <DetailsInput
                  {...register('submitter')}
                  placeholder="Full name"
                  defaultValue={submitter}
                  disabled={Boolean(readOnly)}
                />
                <DetailsInput
                  {...register('submitterMail')}
                  placeholder="Email"
                  defaultValue={submitterMail}
                  disabled={Boolean(readOnly)}
                />
              </SubmitContent>
              <div>
                {!readOnly && <SubmitButton type="submit">Submit</SubmitButton>}
                {submitting && <LogoSpinner />}
                {!submitting && submitted && 'Questionnaire Submitted'}
              </div>
            </SubmitBox>
          </div>
        </Form>
      </QuestionsContainer>
    </Container>
  );
};

export default QuestionnaireForm;

const questionToFormSection = (question, register, readOnly) => {
  const { key, answer, comment, title, description, triggers } = question;
  return (
    <QuestionBox key={key}>
      <HorizontalStack>
        <span>{title}</span>
        {triggers?.map(trigger => (
          <Trigger key={trigger}>{trigger}</Trigger>
        ))}
      </HorizontalStack>
      <b style={{ marginTop: '4px', marginBottom: '4px' }}>{description}</b>
      <Answer>
        <Radio
          {...register(`questions.${key}.answer`)}
          disabled={Boolean(readOnly)}
          value={true}
          defaultChecked={answer}
        />
        Yes
      </Answer>
      <Answer style={{ marginTop: '8px' }}>
        <Radio
          {...register(`questions.${key}.answer`)}
          disabled={Boolean(readOnly)}
          value={false}
          defaultChecked={!answer}
        />
        No
      </Answer>
      <TextareaControl
        defaultValue={comment}
        placeholder="Add Comment (optional)"
        disabled={Boolean(readOnly)}
      />
    </QuestionBox>
  );
};

const SubmittedDiv = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

const StyledLink = styled.a`
  margin: 3px;
  text-decoration: underline;
  font-weight: bold;
`;

const FormSubmitted = ({ goBack }) => (
  <SubmittedDiv>
    Form submitted successfully, click <StyledLink onClick={goBack}>here</StyledLink> if you wish to
    go back to the form
  </SubmittedDiv>
);

const Form = styled.form`
  ${Heading} {
    font-size: 1em;

    &:not(:first-of-type) {
      margin-top: 9rem;
    }
  }

  ${Input},
  ${Combobox} {
    width: 85rem;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 120px 300px;
`;

const ApiiroLogo = styled(Logo)`
  position: absolute;
  top: 32px;
  left: 32px;
`;

const Description = styled.div`
  margin-bottom: 36px;
`;

const SubTitle = styled.h3`
  margin-bottom: 48px;
`;

const QuestionsContainer = styled.div``;

export const QuestionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 8px;
  background-color: ${props => (props.predefined ? '#e9fff8' : '#f5f5f5')};
`;

const Answer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DetailsInput = styled.input`
  width: 400px;
  height: 48px;
  padding: 12px 24px;
  border: 1px solid black;
  margin-bottom: 16px;
`;

const SubmitContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubmitButton = styled.button`
  width: fit-content;
  padding: 12px 48px;
  border: 1px solid black;
  color: white;
  align-self: flex-end;
  background-color: black;
`;

const SubmitBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

export const Trigger = styled.span`
  display: inline-flex;
  height: 7rem;
  padding: 0 3rem;
  align-items: center;
  background-color: #00e3ba;
  border-radius: 100vmax;
`;
