import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { AnalyticsDataField, AnalyticsLayer } from '@src-v2/components/analytics-layer';
import { TextIconButton } from '@src-v2/components/buttons';
import { ClampText } from '@src-v2/components/clamp-text';
import { ControlledScroll, ScrollIconContainer } from '@src-v2/components/controlled-scroll';
import { SvgIcon } from '@src-v2/components/icons';
import { Gutters, PageSpinner, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { GetStartedInventoryTab } from '@src-v2/containers/inventory-query/inventory-query-getting-started-tab';
import {
  loadExportedQuerySettings,
  useApiiroQlSchema,
} from '@src-v2/containers/inventory-query/inventory-query-settings';
import { InventoryQuerySidebar } from '@src-v2/containers/inventory-query/inventory-query-sidebar/inventory-query-sidebar';
import {
  InventoryQueryTab,
  useGateDiscardingOperation,
} from '@src-v2/containers/inventory-query/inventory-query-tab';
import { ModalRouteChangePrompt } from '@src-v2/containers/modals/route-prompt-message-modal';
import { AnimatedTabControl } from '@src-v2/containers/tabs/animated-tab-control';
import { useInject, useQueryParams } from '@src-v2/hooks';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { pluralFormat } from '@src-v2/utils/number-utils';

export const InventoryQueryPage = observer(() => {
  const {
    queryParams: { q: urlRequestedQuery },
  } = useQueryParams();

  const [gateDiscardingOperationModalElement, gateDiscardingOperation] =
    useGateDiscardingOperation();

  const { apiiroQlSchema, querySchemaReady } = useApiiroQlSchema();

  const [tabs, setTabs] = useState([]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const tabSeed = useRef(1);
  const [savingQuery, setSavingQuery] = useState(null);

  const onTabSaved = useCallback((tabId, newPath, newName) => {
    setTabs(tabs => {
      const newTabs = [...tabs];
      const tab = newTabs.find(tab => tab.tabId === tabId);

      if (tab) {
        tab.title = newName;
      }
      setSavingQuery(`${newName}-${crypto.randomUUID()}`);
      return newTabs;
    });
  }, []);

  const generateQueryTab = useCallback(
    (querySettings, path, title) => {
      const tabId = tabSeed.current++;
      return {
        tabId,
        closeable: true,
        title: title ?? `Query ${tabId}`,
        Control: ({ onNewTab, onDirtyUpdated }) => (
          <InventoryQueryTab
            onNewTab={onNewTab}
            queryTitle={title}
            onTabSaved={(newPath, newName) => onTabSaved(tabId, newPath, newName)}
            initialQuerySettings={querySettings}
            onDirtyUpdated={onDirtyUpdated}
            apiiroQlSchema={apiiroQlSchema}
            initialSuggestedSavePath={path}
          />
        ),
      };
    },
    [tabs, apiiroQlSchema, tabSeed?.current]
  );

  const createNewTab = useCallback(
    (initialQuerySettings, select, title, path) => {
      const newTab = generateQueryTab(initialQuerySettings, path, title);

      setTabs(tabs => [...tabs, newTab]);

      if (select) {
        setSelectedTab(newTab);
      }
    },
    [setTabs, tabs, generateQueryTab, setSelectedTab]
  );

  const closeTab = useCallback(
    tab => {
      const newTabs = tabs.concat();
      const tabIndexToDelete = tabs.indexOf(tab);
      newTabs.splice(tabIndexToDelete, 1);
      setTabs(newTabs);

      const oldSelectionIndex = tabs.indexOf(selectedTab);
      setSelectedTab(
        newTabs[oldSelectionIndex < newTabs.length ? oldSelectionIndex : newTabs.length - 1]
      );
    },
    [tabs, setTabs, selectedTab, setSelectedTab]
  );

  const setTabDirty = useCallback(
    (tab, isDirty) => {
      setTabs(tabs => {
        tab.dirty = isDirty;
        return [...tabs];
      });
    },
    [setTabs]
  );

  useEffect(() => {
    if (querySchemaReady) {
      const gettingStartedTab = {
        tabId: -1,
        closeable: false,
        title: `Get Started`,
        Control: ({ onNewTab }) => <GetStartedInventoryTab onNewTab={onNewTab} />,
      };
      const initialTabs = [gettingStartedTab];
      let initialSelectedTab = gettingStartedTab;

      if (urlRequestedQuery) {
        try {
          const urlRequestedQueryDefinition = loadExportedQuerySettings(
            apiiroQlSchema,
            urlRequestedQuery
          );

          const queryDefinitionTab = generateQueryTab(urlRequestedQueryDefinition);

          initialTabs.push(queryDefinitionTab);
          initialSelectedTab = queryDefinitionTab;
        } catch (e) {
          console.warn(`Invalid query provided in URL parameters: ${e}`);
        }
      }

      setTabs(initialTabs);
      setSelectedTab(initialSelectedTab);
    }
  }, [urlRequestedQuery, querySchemaReady]);

  const handleTabCloseClick = useCallback(
    (event, tab) => {
      event.stopPropagation();

      gateDiscardingOperation(tab.dirty, tab.title, () => {
        closeTab(tab);
      });
    },
    [closeTab]
  );

  const numDirtyTabs = useMemo(() => tabs.filter(tab => tab.dirty).length, [tabs]);

  const { subscription } = useInject();

  return (
    <AnalyticsLayer analyticsData={{ [AnalyticsDataField.Context]: 'Explorer' }}>
      <Page title="Risk Graph Explorer">
        <ExplorerLayout data-banner={dataAttr(subscription.isTrial)}>
          <InventoryQuerySidebar savingQuery={savingQuery} createNewTab={createNewTab} />
          <ExplorerContent>
            <StickyHeader />
            {gateDiscardingOperationModalElement}
            {querySchemaReady && (
              <Gutters>
                <TabsContainer>
                  <ScrollableTabsRow scrollGap={150} scrollOffset={10} key={tabs.length}>
                    <AnimatedTabControl
                      value={selectedTab}
                      onChange={value => setSelectedTab(value)}
                      items={tabs}
                      itemToTabControl={item => (
                        <AnimatedTabControl.Tab data-selected={item === selectedTab}>
                          <ClampText>{item.title}</ClampText> {item.dirty && '*'}
                          {item.closeable && tabs.length > 1 && (
                            <SvgIcon
                              name="Close"
                              onClick={event => handleTabCloseClick(event, item)}
                            />
                          )}
                        </AnimatedTabControl.Tab>
                      )}
                    />
                  </ScrollableTabsRow>
                  <AddNewTab>
                    <TextIconButton
                      button={NewTabButton}
                      iconName="Plus"
                      status="secondary"
                      onClick={() => createNewTab(null, true)}>
                      New
                    </TextIconButton>
                  </AddNewTab>
                </TabsContainer>
                {tabs.map(tab => (
                  <SelectedTabWrapper key={tab.tabId} visible={tab === selectedTab}>
                    <tab.Control
                      onNewTab={createNewTab}
                      onDirtyUpdated={dirty => setTabDirty(tab, dirty)}
                    />
                  </SelectedTabWrapper>
                ))}
              </Gutters>
            )}

            {!querySchemaReady && <PageSpinner />}

            <ModalRouteChangePrompt
              title={numDirtyTabs > 1 ? 'Discard queries?' : 'Discard query?'}
              when={numDirtyTabs > 0}>
              There {pluralFormat(numDirtyTabs, 'is an unsaved query', 'are unsaved queries')} that
              will be discarded.
              <br />
              Are you sure?
            </ModalRouteChangePrompt>
          </ExplorerContent>
        </ExplorerLayout>
      </Page>
    </AnalyticsLayer>
  );
});

export default InventoryQueryPage;

const NewTabButton = styled.div`
  display: flex;
  align-items: center;

  ${SvgIcon} {
    color: var(--color-blue-gray-55);
  }
`;

const SelectedTabWrapper = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const ExplorerLayout = styled.div`
  display: grid;
  grid-template-areas: 'sidebar explorer-editor';
  grid-template-columns: auto 1fr;

  ${InventoryQuerySidebar} {
    grid-area: sidebar;
  }
`;

const ExplorerContent = styled.div`
  flex-grow: 1;
  overflow: scroll;
  height: calc(100vh - 15rem);
  grid-area: explorer-editor;

  [data-banner] & {
    padding-top: 10rem;
  }
`;

const AddNewTab = styled(AnimatedTabControl.Tab)`
  display: flex;
  align-items: center;
  padding-top: 1rem;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ScrollableTabsRow = styled(ControlledScroll)`
  max-width: calc(100% - 25rem);
  flex-shrink: 1;

  ${ScrollIconContainer} {
    background: var(--color-blue-gray-10);
  }
`;
