import _ from 'lodash';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import styled from 'styled-components';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { generateCommitReferenceUrl } from '@src-v2/data/connectors';
import { Ellipsis } from '@src/style/common';

export const thenSubTypeToVerb = value => {
  switch (value) {
    case 'Added':
    case 'Removed':
    case 'Upgraded':
    case 'Downgraded':
    case 'Modified':
      return _.camelCase(value);
    case 'Altered':
      return 'significantly changed';
    default:
      return value;
  }
};

export const thenSubTypeToConjunction = value => {
  switch (value) {
    case 'Added':
      return 'to';
    case 'Removed':
      return 'from';
    case 'Altered':
      return 'at';
    default:
      return value;
  }
};

export const getLabelsToGovernanceRuleKeys = governanceRules => {
  const labelsToGovernanceRuleKeys = {};
  governanceRules.forEach(rule => {
    rule.then.forEach(({ type, value }) => {
      if (type === 'Label') {
        labelsToGovernanceRuleKeys[value] = [
          rule.key,
          ...(labelsToGovernanceRuleKeys[value] || []),
        ];
      }
    });
  });
  return labelsToGovernanceRuleKeys;
};

export const materialChangeLabelsToCount = labelsToMaterialChangeKeys =>
  map(
    orderBy(toPairs(labelsToMaterialChangeKeys), ([name]) => name),
    ([name, keys]) => ({ name, count: keys.length })
  );

export const CommitCodeReference = styled(
  ({ repository, commitSha, relativeFilePath, ...props }) => (
    <Tooltip content={relativeFilePath} disabled={!relativeFilePath}>
      <ExternalLink
        {...props}
        href={generateCommitReferenceUrl(repository, commitSha, relativeFilePath)}
      />
    </Tooltip>
  )
)`
  ${Ellipsis};
  max-width: 180rem;
`;
