import styled from 'styled-components';
import { StyledVerticalStack } from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableDisplay';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import {
  CommitCodeReference,
  thenSubTypeToVerb,
} from '@src/components/MaterialChange/MaterialChangeUtils';
import { StyledWrappedTitledList } from '@src/components/MaterialChange/Styles';
import { FontBodySmall } from '@src/style/common';

const StyledField = styled.div`
  ${FontBodySmall};
  padding-top: 6px;
`;

const StyledFieldType = styled.span`
  color: var(--color-blue-gray-45);
`;

const ProtobufServiceMaterialChange = ({ commitSha, repository, materialChange, thenSubType }) => {
  const { descriptions, codeReference } = materialChange;
  return (
    <StyledVerticalStack>
      <StyledWrappedTitledList
        title={
          <HorizontalStack>
            <span>Protobuf test test test </span>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={codeReference.relativeFilePath}>
              <Bold>{codeReference.name}</Bold>
            </CommitCodeReference>
            <span>was</span>
            <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
          </HorizontalStack>
        }
        list={
          thenSubType === 'Added'
            ? descriptions.map(({ name, type }) => (
                <StyledField key={name}>
                  <span>{name}</span>
                  {type && <StyledFieldType>: {type}</StyledFieldType>}
                </StyledField>
              ))
            : descriptions
        }
      />
    </StyledVerticalStack>
  );
};

export default ProtobufServiceMaterialChange;
