import styled from 'styled-components';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { FontBodyBold } from '@src/style/common';
import { CommitCodeReference } from './MaterialChangeUtils';
import { StyledHighlightedCode } from './Styles';

const StyledApiMethodName = styled(PopoverTip)`
  ${FontBodyBold};
`;

const ApiBehindApiGatewayMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  materialChange,
}) => {
  const { methodSignature, apiName, methodName } = materialChange.codeReference;
  return (
    <VerticalStack>
      <HorizontalStack>
        <span>API is behind API Gateway that does not provide:</span>
        <Bold>{materialChange.missingApiSecurityControls.join(', ')}</Bold>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}>
          {apiName || methodName}
        </CommitCodeReference>
      </HorizontalStack>
      <StyledApiMethodName key={methodSignature} title="API" linkText={apiName || methodName}>
        <StyledHighlightedCode language="java" code={materialChange.methodSignature}>
          {materialChange.methodSignature}
        </StyledHighlightedCode>
      </StyledApiMethodName>
    </VerticalStack>
  );
};

export default ApiBehindApiGatewayMaterialChange;
