import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';

export const ApiMethodParameterMaterialChange = ({ commitSha, repository, materialChange }) => {
  const { className, relativeFilePath, lineNumber, apiName } = materialChange.codeReference ?? {};
  return (
    <>
      <Heading>
        <Strong>Method parameters were modified</Strong>
        <Paragraph>in API</Paragraph>
        <Strong>{apiName}</Strong>
        <Paragraph>defined at</Paragraph>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}>
          {className ?? relativeFilePath}
        </CommitCodeReference>
      </Heading>

      {materialChange.modifications.map((modification, index) => (
        <ModificationDescription {...modification} key={index} />
      ))}
    </>
  );
};

const ModificationDescription = ({
  name,
  parentType,
  modificationType,
  previousType,
  currentType,
}) => {
  switch (modificationType) {
    case 'Added':
      return (
        <MaterialChangesRow>
          <Paragraph>{parentType ? 'Field' : 'Parameter'}</Paragraph>
          <Strong>
            {currentType} {name}
          </Strong>
          {parentType && (
            <>
              <Paragraph>in type</Paragraph>
              <Strong>{parentType}</Strong>
            </>
          )}
          <Paragraph>was added</Paragraph>
        </MaterialChangesRow>
      );

    case 'Removed':
      return (
        <MaterialChangesRow>
          <Paragraph>{parentType ? 'Field' : 'Parameter'}</Paragraph>
          <Strong>
            {previousType} {name}
          </Strong>
          {parentType && (
            <>
              <Paragraph>in type</Paragraph>
              <Strong>{parentType}</Strong>
            </>
          )}
          <Paragraph>was removed</Paragraph>
        </MaterialChangesRow>
      );

    case 'ChangedType':
      return (
        <MaterialChangesRow>
          <Paragraph>Type of {parentType ? 'field' : 'parameter'}</Paragraph>
          <Strong>{name}</Strong>
          {parentType && (
            <>
              <Paragraph>in type</Paragraph>
              <Strong>{parentType}</Strong>
            </>
          )}
          <Paragraph>was changed from</Paragraph>
          <Strong>{previousType}</Strong>
          <Paragraph>to</Paragraph>
          <Paragraph>{currentType}</Paragraph>
        </MaterialChangesRow>
      );

    default:
      console.warn(`unsupported modification type ${modificationType ?? ''}`);
      return null;
  }
};
