import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import styled from 'styled-components';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { StyledVerticalStack } from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableDisplay';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import { Number } from '@src/components/Number';
import { FontBodyBold } from '@src/style/common';
import { StyledHighlightedCode, StyledHorizontalStack, StyledWrappedTitledList } from './Styles';

const StyledApiMethodName = styled(PopoverTip)`
  ${FontBodyBold};
`;

const vulnerabilitiesChangeDescription = (additionalVulnerabilitiesByTypes, connectorText) =>
  Object.keys(additionalVulnerabilitiesByTypes).length > 0 && (
    <StyledWrappedTitledList
      list={map(toPairs(additionalVulnerabilitiesByTypes), ([vulnerabilityType, count]) => (
        <StyledHorizontalStack>
          <span>found</span>
          <Number
            value={count}
            one={`${connectorText} ${vulnerabilityType}`}
            other={`${connectorText} ${vulnerabilityType}s`}
          />
          <span>for this API</span>
        </StyledHorizontalStack>
      ))}
    />
  );

const ApiCheckmarxVulnerabilityMaterialChange = ({ materialChange }) => {
  const { methodSignature, apiName, methodName } = materialChange.codeReference;
  return (
    <StyledVerticalStack>
      <HorizontalStack>
        <Link url={materialChange.latestScanUrl} openInNewTab>
          <Bold>according to correlated Checkmarx scan</Bold>
        </Link>
      </HorizontalStack>
      {vulnerabilitiesChangeDescription(materialChange.addedVulnerabilitiesByType, 'additional')}
      {vulnerabilitiesChangeDescription(materialChange.removedVulnerabilitiesByType, 'less')}
      <StyledApiMethodName key={methodSignature} title="API" linkText={apiName || methodName}>
        <StyledHighlightedCode language="java" code={methodSignature}>
          {methodSignature}
        </StyledHighlightedCode>
      </StyledApiMethodName>
    </StyledVerticalStack>
  );
};

export default ApiCheckmarxVulnerabilityMaterialChange;
