import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { ClampText } from '@src-v2/components/clamp-text';
import { OverviewStateBoundary } from '@src-v2/components/overview/overview-state-boundary';
import { OverviewTile } from '@src-v2/components/overview/overview-tiles';
import { useOverviewFilters } from '@src-v2/components/overview/use-overview-filters';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { Table } from '@src-v2/components/table/table';
import { TableHeader } from '@src-v2/components/table/table-header';
import { InsightTag, RiskTag } from '@src-v2/components/tags';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Size } from '@src-v2/components/types/enums/size';
import { ExternalLink } from '@src-v2/components/typography';
import { ExploitMaturityTooltipContent } from '@src-v2/containers/entity-pane/sca/main-tab/vulnerabilities-card';
import { CveLink } from '@src-v2/containers/entity-pane/sca/vulnerability-link';
import { useMakeOverviewUrl } from '@src-v2/containers/overview/tiles/utils';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useTable } from '@src-v2/hooks/use-table';
import { ExploitableVulnerabilities } from '@src-v2/services';
import { Column } from '@src-v2/types/table';
import { stopPropagation } from '@src-v2/utils/dom-utils';

interface PlainTopRisksTileProps {
  dataFetcher: (args?: { filters }) => Promise<any[]>;
  to?: string;
}

export const TopExploitableVulnerabilitiesTile = () => {
  return (
    <OverviewTile size={2} title="Top exploitable vulnerabilities">
      <PlainTopRisksTile
        dataFetcher={useInject().ossOverview.getTopExploitableVulnerabilities}
        to="oss"
      />
    </OverviewTile>
  );
};

const PlainTopRisksTile = ({ dataFetcher, to }: PlainTopRisksTileProps) => {
  const trackAnalytics = useTrackAnalytics();
  const history = useHistory();

  const { activeFilters } = useOverviewFilters();
  const data = useSuspense(dataFetcher, { filters: activeFilters });
  const makeOverviewUrl = useMakeOverviewUrl();
  const baseUrl = `/risks${to ? `/${to}` : ''}`;

  const isEmpty = !data.length;

  const handleRowClick = useCallback(
    (row: ExploitableVulnerabilities) => {
      trackAnalytics(AnalyticsEventName.ActionClicked, {
        [AnalyticsDataField.ActionType]: 'Tile click',
        [AnalyticsDataField.TileName]: 'Top exploitable vulnerabilities',
        [AnalyticsDataField.Context]: 'OSS dashboard',
      });

      history.push(
        makeOverviewUrl({
          baseUrl,
          query: { Vulnerability: row.vulnerabilities, searchTerm: row.packageName },
        })
      );
    },
    [trackAnalytics]
  );

  return (
    <OverviewStateBoundary isEmpty={isEmpty}>
      <TopExploitableVulnerabilitiesTable
        data={data}
        onClick={handleRowClick}
        tableColumns={tableColumns}
      />
    </OverviewStateBoundary>
  );
};

export const tableColumns: Column<ExploitableVulnerabilities>[] = [
  {
    label: 'Vulnerability',
    Cell: ({ data, ...props }) => {
      const vulnerability =
        data.vulnerabilities?.find(vulnerability => vulnerability.toLowerCase().includes('cve')) ??
        data.vulnerabilities?.[0];

      return (
        <Table.Cell {...props}>
          <VulnerabilityColumn>
            <RiskIcon riskLevel={data.severity} size={Size.XSMALL} />
            <Tooltip
              interactive
              appendTo={document.body}
              content={
                <ToolTipContainer>
                  {data.description}
                  {data.vulnerabilities?.map(vulnerability => (
                    <div onClick={stopPropagation}>
                      <CveLink cve={vulnerability} />
                    </div>
                  ))}
                </ToolTipContainer>
              }>
              <span>{vulnerability && <CveLink cve={vulnerability} />}</span>
            </Tooltip>
          </VulnerabilityColumn>
        </Table.Cell>
      );
    },
    width: '48rem',
  },
  {
    label: 'Exploit maturity',
    Cell: ({ data, ...props }) => (
      <Table.FlexCell {...props}>
        {data.exploitMaturity ? (
          <InsightTag
            insight={{
              badge: data.exploitMaturity,
              sentiment: 'Negative',
            }}
            hint={
              <ExploitMaturityTooltipContent
                exploitMaturity={data.exploitMaturity}
                pocReferences={data.pocReferences}
              />
            }
            isTopExploitable
          />
        ) : null}
      </Table.FlexCell>
    ),
    width: '33rem',
  },
  {
    label: 'Package name',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <ClampText>{data.packageName}</ClampText>
      </Table.Cell>
    ),
    minWidth: '40rem',
  },
  {
    label: 'Risks',
    Cell: ({ data, ...props }) => (
      <RiskTagsContainer {...props}>
        {Object.keys(data.risks).map((key, index) => (
          <RiskTag key={index} riskLevel={key.toLowerCase()} size={Size.XSMALL}>
            {data.risks[key]}
          </RiskTag>
        ))}
      </RiskTagsContainer>
    ),
  },
];

const VulnerabilityColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${ExternalLink} {
    display: flex;
    font-size: var(--font-size-s);
  }
`;

const RiskTagsContainer = styled(Table.FlexCell)`
  align-items: center;
  gap: 1rem;

  ${RiskTag} {
    font-size: var(--font-size-xs);
    min-width: auto;
  }
`;

interface TopExploitableVulnerabilitiesProps<T> {
  data: ExploitableVulnerabilities[];
  onClick?: (event) => void;
  tableColumns: Column<T>[];
}

export const TopExploitableVulnerabilitiesTable = <T extends any>({
  data,
  onClick,
  tableColumns,
}: TopExploitableVulnerabilitiesProps<T>) => {
  const tableModel = useTable({ tableColumns, hasReorderColumns: false });

  return (
    <Table>
      <TableHeader tableModel={tableModel} />
      <Table.Body>
        {data.map((row, index) => (
          <Table.Row key={index} onClick={() => onClick(row)}>
            {tableModel.columns?.map(({ Cell, ...column }) => (
              <Cell key={column.label} data={row} />
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
