import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import RiskIcon, { autoIgnoreRiskLevel } from '@src/components/RiskIcon';

const MaterialChangeActions = ({
  materialChange,
  governanceRule,
  thenSubType,
  isReleaseContext,
}) => {
  const { application, rbac } = useInject();

  let riskIcon;
  let addExploreButton = false;
  if (governanceRule.isAutoIgnored) {
    riskIcon = <StyledIgnoredRiskIcon riskLevel={autoIgnoreRiskLevel} />;
  } else if (governanceRule.risk !== 'None' && thenSubType === 'Added') {
    riskIcon = <StyledRiskIcon riskLevel={governanceRule.risk} />;
    addExploreButton = true;
  }

  const shouldDisplayRiskLink =
    materialChange.riskLevel &&
    materialChange.riskLevel !== 'None' &&
    materialChange.repositoryKey &&
    materialChange.entityIdEncoded &&
    !isReleaseContext;

  const riskLink =
    shouldDisplayRiskLink &&
    `/profiles/repositories/${materialChange.repositoryKey}/risk/development/ruleTriggers/${materialChange.governanceRuleKey}/trigger/${materialChange.entityIdEncoded}__${materialChange.repositoryKey}`;

  return (
    <StyledMaterialChangeMenu>
      {riskLink ? (
        addExploreButton ? (
          <LinkWrapper>
            <Link to={riskLink}>
              <ExploreButton>Explore</ExploreButton>
            </Link>
            {riskIcon}
          </LinkWrapper>
        ) : (
          <Link to={riskLink}>{riskIcon}</Link>
        )
      ) : (
        riskIcon
      )}
      {application.isDemo && rbac.canEdit(resourceTypes.MaterialChanges) && (
        <DropdownMenu>
          <Dropdown.Item>
            <SvgIcon name="Invisible" /> Ignore this time
          </Dropdown.Item>
          <Dropdown.Item>
            <VendorIcon name="Jira" /> Open an issue
          </Dropdown.Item>
          <Dropdown.Item>
            <VendorIcon name="Slack" /> Share on Slack
          </Dropdown.Item>
          <Dropdown.Item>
            <VendorIcon name="Teams" /> Share on Teams
          </Dropdown.Item>
        </DropdownMenu>
      )}
    </StyledMaterialChangeMenu>
  );
};

export default MaterialChangeActions;

const StyledMaterialChangeMenu = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledRiskIcon = styled(RiskIcon)`
  margin-right: 6px;
`;

const StyledIgnoredRiskIcon = styled(StyledRiskIcon)`
  opacity: 0.5;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const ExploreButton = styled.span`
  padding: 3px 3rem;
  font-size: 3.5rem;
  border: 0.25rem solid var(--color-blue-gray-70);
  border-radius: 18px;
`;
