import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { ClampText } from '@src-v2/components/clamp-text';
import { Collapsible } from '@src-v2/components/collapsible';
import { ContentLimiter } from '@src-v2/components/content-limiter';
import { generateHierarchyFilterChangedPayload } from '@src-v2/components/filters/inline-control/containers/hierarchy-filter-general-select';
import { FiltersMenu, includesValue } from '@src-v2/components/filters/menu-control/filters-menu';
import { Checkbox } from '@src-v2/components/forms';
import { Paragraph, SubHeading4 } from '@src-v2/components/typography';
import { isHierarchyFilterOption } from '@src-v2/hooks/use-filters';

export function CheckboxOptions({
  groupKey,
  options,
  optionsLimit,
  activeValues,
  onChange,
  onDelete,
  ...props
}) {
  const sortedOptions = useMemo(
    () => _.sortBy(options, option => !includesValue(activeValues, option.value)),
    [options]
  );

  const handleChange = useCallback(
    (option, event) => {
      const ops = generateHierarchyFilterChangedPayload(
        groupKey,
        event.target.checked,
        option.value,
        options
      );

      onChange?.(ops, event);
    },
    [onChange]
  );

  return (
    <Collapsible {...props}>
      {options.length ? (
        <ContentLimiter limit={optionsLimit}>
          {sortedOptions.map(option => (
            <FiltersMenu.Option key={option.value}>
              <Checkbox
                checked={includesValue(activeValues, option.value)}
                onChange={event => handleChange(option, event)}
              />
              <FiltersMenu.OptionLabel>
                <HierarchyItemDisplay filterOption={option} />
              </FiltersMenu.OptionLabel>
            </FiltersMenu.Option>
          ))}
        </ContentLimiter>
      ) : (
        <Paragraph>No options found</Paragraph>
      )}
    </Collapsible>
  );
}

function HierarchyItemDisplay({ filterOption }) {
  if (!isHierarchyFilterOption(filterOption)) {
    return <ClampText>{filterOption.title}</ClampText>;
  }

  return (
    <>
      <HierarchyContainer as="span">
        <ClampText>{filterOption.hierarchy.map(option => option.name).join(' / ')}</ClampText>
        &nbsp;/
      </HierarchyContainer>
      <ClampText>{filterOption.title}</ClampText>
    </>
  );
}

const HierarchyContainer = styled(SubHeading4)`
  display: flex;
  align-items: center;
`;
