import styled from 'styled-components';
import InputValidationDescription from '@src/components/InputValidationDescription';
import { EllipsisDiv, FontBodySmall, FontBodySmallBold } from '@src/style/common';
import { CommitCodeReference } from './MaterialChangeUtils';
import { StyledDiv, StyledHorizontalStack } from './Styles';

const SmallSpan = styled.span`
  ${FontBodySmall};
`;

const SmallBoldSpan = styled.span`
  ${FontBodySmallBold};
`;

const InputValidationModificationsDetails = ({ modifications }) => (
  <StyledDiv>
    {modifications.map(({ isNewParameter, isValidated, parameterName, isDeleted }) => (
      <StyledHorizontalStack key={parameterName} wrapItems>
        <SmallBoldSpan>{parameterName}</SmallBoldSpan>
        <SmallSpan>-</SmallSpan>
        {isNewParameter ? (
          <SmallSpan>added without validation</SmallSpan>
        ) : isDeleted ? (
          <SmallSpan>previously unvalidated parameter was removed</SmallSpan>
        ) : (
          <SmallSpan>validation was {isValidated ? 'added' : 'removed'}</SmallSpan>
        )}
      </StyledHorizontalStack>
    ))}
  </StyledDiv>
);

const thenSubTypeToVerb = thenSubType => {
  switch (thenSubType) {
    case 'Added':
      return 'is missing';
    case 'Removed':
      return 'was added';
    case 'Altered':
      return 'has significantly changed';
    default:
      return null;
  }
};

function InputValidationMaterialChange({
  materialChange,
  thenSubType,
  repository,
  commitSha,
  relativeFilePath,
  lineNumber,
  whenValue,
}) {
  const { parameterNames, unvalidatedParameters } = materialChange.violation;
  const { className, methodName, apiName } = materialChange.codeReference;
  return (
    <>
      <StyledHorizontalStack>
        <span>Input validation {thenSubTypeToVerb(thenSubType)} for API</span>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}>
          {apiName || methodName}
        </CommitCodeReference>
        <span>of class</span>
        <span>{className},</span>
        {whenValue !== 'any' && <span>under {whenValue},</span>}
        {thenSubType === 'Removed' ? (
          <span>for all parameters</span>
        ) : (
          <span>for the following parameters:</span>
        )}
      </StyledHorizontalStack>
      <StyledHorizontalStack>
        <EllipsisDiv>
          {thenSubType === 'Altered' && (
            <InputValidationModificationsDetails modifications={materialChange.modifications} />
          )}
          {thenSubType === 'Added' && (
            <InputValidationDescription
              repository={repository}
              relativeFilePath={relativeFilePath}
              lineNumber={lineNumber}
              methodDisplayString={materialChange.codeReference.methodSignature}
              parameterNames={parameterNames}
              unvalidatedParameters={unvalidatedParameters}
            />
          )}
        </EllipsisDiv>
      </StyledHorizontalStack>
    </>
  );
}

export default InputValidationMaterialChange;
