import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { Heading3, SubHeading3 } from '@src-v2/components/typography';
import { DraggableProvidersPreferences } from '@src-v2/containers/entity-pane/sca/draggable-providers-preferences';
import { ProviderGroup } from '@src-v2/types/enums/provider-group';
import { FormLayoutV2 } from '@src/src-v2/components/forms/form-layout-v2';

export const ProvidersPreferences = () => {
  const { setValue, watch } = useFormContext();
  const providers = watch('providers');

  const handleChange = useCallback(
    (providers: ProviderGroup[]) => setValue('providers', providers, { shouldDirty: true }),
    []
  );

  return (
    <FormLayoutV2.Section>
      <Header>
        <Heading3>SCA source preference</Heading3>
        <SubHeading3 data-variant={Variant.SECONDARY}>
          Apiiro consolidates findings from your SCA sources.
          <br /> Set the order of provider findings for risk pane evidence, remediation data, and
          tickets generated by Apiiro.
        </SubHeading3>
      </Header>
      <DraggableProvidersPreferencesContainer>
        <DraggableProvidersPreferences providers={providers} onChange={handleChange} />
      </DraggableProvidersPreferencesContainer>
    </FormLayoutV2.Section>
  );
};

const DraggableProvidersPreferencesContainer = styled.div`
  width: 103rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;
`;
