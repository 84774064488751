import { useCallback, useState } from 'react';

export function useToggle(initialValue?) {
  const [value, set] = useState(Boolean(initialValue));
  const toggle = useCallback(
    (resetValue = null) =>
      typeof resetValue === 'boolean' ? set(resetValue) : set(value => !value),
    [set]
  );
  return [value, toggle] as const;
}
