import map from 'lodash/map';
import styled from 'styled-components';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { TrimmedVerticalStack } from '@src/components/TrimmedVerticalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { FontBodySmall } from '@src/style/common';
import {
  CommitCodeReference,
  thenSubTypeToConjunction,
  thenSubTypeToVerb,
} from './MaterialChangeUtils';
import { StyledHorizontalStack } from './Styles';

const StyledDividerContainer = styled.div`
  padding-left: 3rem;
`;

const StyledDataFieldsContainer = styled.div`
  border-left: 0.25rem solid var(--color-blue-gray-35);
  padding-left: 3rem;
`;

const StyledFieldName = styled.div`
  ${FontBodySmall};
`;

const Fields = ({ fieldNames }) => (
  <StyledDividerContainer>
    <StyledDataFieldsContainer>
      <TrimmedVerticalStack
        items={map(fieldNames, field => (
          <StyledFieldName key={field}>{field}</StyledFieldName>
        ))}
        nameSingle="PII field"
        namePlural="PII fields"
      />
    </StyledDataFieldsContainer>
  </StyledDividerContainer>
);

const SensitiveDataMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  lineNumber,
  materialChange,
  whenValue,
  thenSubType,
  fieldNames,
  name,
  methodName,
}) => {
  switch (whenValue) {
    case 'Exposed':
    case 'Involved':
      const { sensitiveDataCodeReference } = materialChange;
      return (
        <VerticalStack>
          <StyledHorizontalStack>
            <span>Data model</span>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}
              lineNumber={lineNumber}>
              {sensitiveDataCodeReference.name}
            </CommitCodeReference>
            <span>
              that contains {materialChange.associatedSensitiveDataTypeDescription} fields is{' '}
              {whenValue === 'Exposed' ? 'exposed by' : 'involved in the flow of'} API method
            </span>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}>
              {methodName}
            </CommitCodeReference>
          </StyledHorizontalStack>
          <Fields fieldNames={fieldNames} />
        </VerticalStack>
      );

    default:
      return (
        <VerticalStack>
          <HorizontalStack>
            <span>{String(materialChange.associatedSensitiveDataTypeDescription)} field was</span>
            <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
            <span>{String(thenSubTypeToConjunction(thenSubType))}</span>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}>
              {name || relativeFilePath}
            </CommitCodeReference>
          </HorizontalStack>
          <Fields fieldNames={[materialChange.sensitiveDataName]} />
        </VerticalStack>
      );
  }
};

export default SensitiveDataMaterialChange;
