import _ from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { FormFieldArray } from '@src-v2/components/forms/form-field-array';
import { SelectV2 } from '@src-v2/components/select/select-v2';
import { FormTagValue } from '@src-v2/containers/profiles/profile-tags/manage-profile-tags-modal';
import { SingleTagSelection } from '@src-v2/containers/profiles/profile-tags/single-tag-selection';
import { TagOption } from '@src-v2/types/profiles/tags/profile-tag';

interface TagsSelectionProps {
  name?: string;
  tagOptions: TagOption[];
}

export function TagsSelection({ name = 'tags', tagOptions }: TagsSelectionProps) {
  const selectedTags: FormTagValue[] = useWatch({ name });

  const [selectedTagOptions, availableTagOptions] = useMemo(() => {
    const selectedOptions = selectedTags
      ?.filter(formTag => Boolean(formTag?.keyOption))
      .map(formTag => formTag?.keyOption);
    return [selectedOptions, _.differenceBy(tagOptions, selectedOptions, 'name')] as const;
  }, [tagOptions, selectedTags]);

  return (
    <TagsFieldArray buttonText="Add tag" name={name} options={{ shouldUnregister: true }}>
      {({ name }) => (
        <SingleTagSelection
          name={name}
          selectedTagOptions={selectedTagOptions}
          tagOptions={availableTagOptions}
        />
      )}
    </TagsFieldArray>
  );
}

const TagsFieldArray = styled(FormFieldArray)`
  padding-bottom: 2rem;

  ${FormFieldArray.FieldContainer} {
    line-height: 9rem;

    ${SelectV2.Container} {
      flex: 1;
    }
  }
`;
