import { useCallback, useEffect, useState } from 'react';
import { useInject } from '@src-v2/hooks/use-inject';

export function useTable({ tableColumns, hasReorderColumns = true }) {
  const [tableModel, setTableModel] = useState({ columns: tableColumns });
  const { application } = useInject();

  useEffect(() => {
    const filteredTableColumns = tableColumns.filter(
      column => !column.betaFeature || application.isFeatureEnabled(column.betaFeature)
    );
    setTableModel({ ...tableModel, columns: filteredTableColumns });
  }, [tableColumns]);

  const moveColumn = useCallback(
    (dragIndex, hoverIndex) => {
      const dragColumn = tableModel.columns?.[dragIndex];
      const filtered = tableModel.columns?.filter(column => column.label !== dragColumn.label);

      const updatedColumns = [
        ...filtered.slice(0, hoverIndex),
        dragColumn,
        ...filtered.slice(hoverIndex),
      ];

      setTableModel({ ...tableModel, columns: updatedColumns });
    },
    [tableModel]
  );

  return { ...tableModel, moveColumn, hasReorderColumns };
}
