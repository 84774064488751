import styled from 'styled-components';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { FontBodyBold } from '@src/style/common';
import { getMethodName } from '@src/utils/stringDisplayUtils';
import { CommitCodeReference } from './MaterialChangeUtils';
import { StyledHighlightedCode, StyledWrappedTitledList } from './Styles';

const StyledApiMethodName = styled(PopoverTip)`
  ${FontBodyBold};
`;

const getAccessingMethodDescription = (api, repository, commitSha, action) => {
  return (
    <HorizontalStack>
      <span>Method</span>
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={api.reference.relativeFilePath}
        lineNumber={api.reference.lineNumber}>
        {getMethodName(api.neighborMethodFullName, true)}
      </CommitCodeReference>
      <span>{action === 'read' ? 'reads from' : 'writes to'}</span>
      <Bold>{api.framework}</Bold>
      <span>storage bucket</span>
    </HorizontalStack>
  );
};

const getAccessingMethodsList = (materialChange, repository, commitSha) => {
  const reads = materialChange.readsFromStorageBucket.map(api =>
    getAccessingMethodDescription(api, repository, commitSha, 'read')
  );
  const writes = materialChange.writesToStorageBucket.map(api =>
    getAccessingMethodDescription(api, repository, commitSha, 'write')
  );
  return reads.concat(writes);
};

const StorageBucketAccessMaterialChange = ({
  commitSha,
  repository,
  materialChange,
  thenSubType,
}) => {
  const { relativeFilePath, lineNumber, apiName, methodName, className, methodSignature } =
    materialChange.codeReference;
  return (
    <StyledWrappedTitledList
      title={
        <VerticalStack>
          <HorizontalStack>
            <span>API</span>
            <StyledApiMethodName key={methodSignature} title="API" linkText={apiName || methodName}>
              <StyledHighlightedCode language="java" code={methodSignature}>
                {methodSignature}
              </StyledHighlightedCode>
            </StyledApiMethodName>
            <span>from class</span>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}
              lineNumber={lineNumber}>
              {className || relativeFilePath}
            </CommitCodeReference>
            <span>storage bucket access was</span>
            <Bold>{thenSubType}</Bold>
          </HorizontalStack>
        </VerticalStack>
      }
      list={getAccessingMethodsList(materialChange, repository, commitSha)}
    />
  );
};

export default StorageBucketAccessMaterialChange;
