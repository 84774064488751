import _ from 'lodash';
import { useCveEntityContext } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-context-provider';
import { EntityType } from '@src-v2/types/enums/entity-type';

export const useCveFeedbackContext = (triageValue: string) => {
  const { cveEntity, profile, element } = useCveEntityContext();

  return {
    dependencyDataModelReference: {
      containingProfileId: profile.key,
      diffableEntityId: element.entityId,
      diffableEntityType: element.entityType as keyof typeof EntityType,
      containingProfileType: profile.type,
      diffableEntityObjectType: EntityType[element.entityType],
      inventoryTableEntityType: EntityType[element.entityType],
    },
    dependencyFindingId: cveEntity.entityId,
    fieldName: _.findKey(cveEntity.triage, value => value === triageValue),
  };
};
