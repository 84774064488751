import styled from 'styled-components';
import { StyledVerticalStack } from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableDisplay';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import {
  CommitCodeReference,
  thenSubTypeToVerb,
} from '@src/components/MaterialChange/MaterialChangeUtils';
import { StyledWrappedTitledList } from '@src/components/MaterialChange/Styles';
import { FontBodySmall } from '@src/style/common';

const StyledField = styled.div`
  ${FontBodySmall};
  padding-top: 6px;
`;

const StyledFieldType = styled.span`
  color: var(--color-blue-gray-45);
`;

const ProtobufMessageMaterialChange = ({
  commitSha,
  repository,
  contentSummary,
  codeReference,
  descriptions,
  thenSubType,
}) => (
  <StyledVerticalStack>
    <StyledWrappedTitledList
      title={
        <HorizontalStack>
          <span>Protobuf message scheme </span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={codeReference.relativeFilePath}>
            <Bold>{codeReference.name}</Bold>
          </CommitCodeReference>
          <span>was</span>
          <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
        </HorizontalStack>
      }
      list={
        thenSubType === 'Added'
          ? contentSummary.map(({ name, type }) => (
              <StyledField key={name}>
                <span>{name}</span>
                {type && <StyledFieldType>: {type}</StyledFieldType>}
              </StyledField>
            ))
          : descriptions
      }
    />
  </StyledVerticalStack>
);

export default ProtobufMessageMaterialChange;
