import styled from 'styled-components';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { CommitCodeReference } from '@src/components/MaterialChange/MaterialChangeUtils';
import { StyledWrappedTitledList } from '@src/components/MaterialChange/Styles';
import { FontBodyBold } from '@src/style/common';

const StyledDiffableDisplayName = styled.div`
  ${FontBodyBold}
`;

export const EntityInsightsMaterialChange = ({ materialChange, repository, commitSha }) => {
  const { relativeFilePath, lineNumber } = materialChange.codeReference ?? {};

  return (
    <StyledWrappedTitledList
      title={
        <HorizontalStack>
          <span>Insights for {materialChange.pullRequestEntityName}</span>
          <StyledDiffableDisplayName>
            {materialChange.diffableDisplayName}
          </StyledDiffableDisplayName>
          {materialChange.codeReference && (
            <>
              in file
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={relativeFilePath}
                lineNumber={lineNumber}>
                {relativeFilePath}
              </CommitCodeReference>
            </>
          )}
        </HorizontalStack>
      }
      list={materialChange.insights.map(insight => insight.badge)}
    />
  );
};
