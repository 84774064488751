import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';
import Bold from '@src/components/Bold';
import { VerticalStack } from '@src/components/VerticalStack';

export const ManagedCheckovMaterialChange = ({
  isAdded,
  commitSha,
  repository,
  lineNumber,
  filePath,
  entityDisplayName,
  technologyDisplayName,
}) => (
  <VerticalStack>
    <span>
      A misconfiguration was {isAdded ? 'added to' : 'removed from'} a pipeline configuration file{' '}
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        lineNumber={lineNumber}
        relativeFilePath={filePath}>
        {filePath}
      </CommitCodeReference>{' '}
      of provider {technologyDisplayName}
    </span>
    <Bold>{entityDisplayName}</Bold>
  </VerticalStack>
);
