import { AggregationResult } from '@src-v2/types/aggregation-result';

interface ConsumableItem {
  key: string;
  name: string;
  description: string;
  isAdminBySelf: boolean;
  adminsCount: number;
  membersCount: number;
  rolesCount: number;
}

export class UserGroups {
  #client;
  #asyncCache;

  constructor({ apiClient, asyncCache }) {
    this.#client = apiClient;
    this.#asyncCache = asyncCache;
  }

  async getUserGroup({ key }) {
    return await this.#client.get(`roleGroups/${key}`);
  }

  async deleteUserGroup(key) {
    return await this.#client.delete(`roleGroups/${key}`);
  }

  async saveUserGroup(data) {
    return await this.#client.post('roleGroups', data);
  }

  getApiiroUserGroups(params: {
    searchTerm?: string;
    pageNumber?: number;
    limit?: number;
  }): Promise<AggregationResult<ConsumableItem>> {
    return this.#client.search('roleGroups/search', params);
  }

  invalidateGroups() {
    this.#asyncCache.invalidateAll(this.getApiiroUserGroups);
  }

  invalidateEditGroup() {
    this.#asyncCache.invalidateAll(this.getUserGroup);
  }
}
