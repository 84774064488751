import { memo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { DeleteButton, IconButton } from '@src-v2/components/buttons';
import { Collapsible } from '@src-v2/components/collapsible';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { FilterOptionFactory } from '@src-v2/components/filters/menu-control/filter-options-factory';
import { LabelToggle } from '@src-v2/components/forms';
import { InputControl } from '@src-v2/components/forms/form-controls';
import { Field, Label } from '@src-v2/components/forms/modal-form-layout';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { OptionalTitle } from '@src-v2/components/optional-title';
import { Popover } from '@src-v2/components/tooltips/popover';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Paragraph } from '@src-v2/components/typography';
import { useToggle } from '@src-v2/hooks';
import { useChangeId } from '@src-v2/hooks/react-helpers/use-change-id';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { classNames } from '@src-v2/utils/style-utils';

export function FiltersMenu({
  data,
  activeValues,
  controlOperator,
  placement = 'bottom-end',
  autoOpenCount = 3,
  optionsLimit = 5,
  onChange,
  onClear,
  onSearch,
  onSave,
  onDelete = undefined,
  onOpen = undefined,
  onClose,
  ...props
}) {
  const trackAnalytics = useTrackAnalytics();
  const searchRef = useRef();
  const [showMenu, toggleMenu] = useToggle(false);
  const [modalElement, setModal, closeModal] = useModalState();
  const optionsKey = useChangeId(data); // used to force remount of collapsible components on data change
  const activeValuesCount = Object.values(activeValues ?? {}).flat().length;

  const handleOperatorChange = useCallback(
    (value, event) => onChange?.({ key: 'operator', value }, event),
    [onChange]
  );

  const handleChange = useCallback(
    (value, event) => {
      if (!value) {
        return;
      }
      const title = data.find(item => item.key === (value[0]?.key ?? value.key))?.title;
      trackAnalytics(AnalyticsEventName.Filter, { [AnalyticsDataField.FilterType]: title });
      onChange?.(value, event);
    },
    [onChange, data, trackAnalytics]
  );

  const handleSave = useCallback(() => {
    setModal(
      <PromptModal
        title="Save chosen criteria as a filter"
        onSubmit={formData => {
          onSave?.({ title: formData.title, value: activeValues });
          closeModal();
        }}
        onClose={closeModal}>
        <Field>
          <Label required>Filter Name</Label>
          <InputControl name="title" defaultValue="" rules={{ required: true }} autoFocus />
        </Field>
      </PromptModal>
    );
  }, [onSave, activeValues]);

  const handleDelete = useCallback(
    option => {
      setModal(
        <ConfirmationModal
          title={`Delete ${option.title}?`}
          submitStatus="failure"
          submitText="Delete"
          onSubmit={() => {
            onDelete?.(option.key);
            closeModal();
          }}
          onClose={closeModal}>
          <Paragraph>
            By confirming, you will no longer be able to use this custom filter.
          </Paragraph>
          <Paragraph>Are you sure?</Paragraph>
        </ConfirmationModal>
      );
    },
    [onDelete, setModal, closeModal]
  );

  return (
    <>
      <FiltersMenu.Popover
        {...props}
        appendTo="parent"
        visible={showMenu}
        placement={placement}
        onClickOutside={toggleMenu}
        onHide={onClose}
        onShow={onOpen}
        maxHeight="150rem"
        content={
          <>
            <FiltersMenu.Actions>
              {onSearch && (
                <FiltersMenu.ActionsRow>
                  <FiltersMenu.Search
                    ref={searchRef}
                    placeholder="Search filters"
                    onChange={onSearch}
                  />
                  {onSave && (
                    <Tooltip content="Save filters">
                      <IconButton
                        name="Save"
                        disabled={activeValuesCount < 2}
                        onClick={handleSave}
                      />
                    </Tooltip>
                  )}
                  {onClear && (
                    <Tooltip content="Clear filters">
                      <DeleteButton disabled={!activeValuesCount} onClick={onClear} />
                    </Tooltip>
                  )}
                </FiltersMenu.ActionsRow>
              )}
              {controlOperator && (
                <FiltersMenu.ActionsRow>
                  In-Category Behavior
                  <LabelToggle
                    labels={operatorOptions}
                    defaultLabel={activeValues?.operator}
                    onChange={handleOperatorChange}
                  />
                </FiltersMenu.ActionsRow>
              )}
            </FiltersMenu.Actions>

            <FiltersMenu.OptionGroups key={optionsKey}>
              {data
                ?.filter(optionsGroup => optionsGroup.options?.length > 0)
                .map(({ key: groupKey, ...props }, index) => (
                  <FilterOptionFactory
                    {...props}
                    key={`${groupKey}-${props.title}`}
                    groupKey={groupKey}
                    optionsLimit={optionsLimit}
                    activeValues={activeValues?.[groupKey]}
                    defaultOpen={
                      searchRef.current?.value.length > 0 ||
                      (activeValuesCount
                        ? Object.hasOwn(activeValues, groupKey)
                        : index < autoOpenCount)
                    }
                    onChange={handleChange}
                    onDelete={handleDelete}
                  />
                ))}
            </FiltersMenu.OptionGroups>
          </>
        }>
        <FiltersMenu.Button
          name="Filter"
          className={classNames({ showMenu, active: activeValuesCount })}
          onClick={toggleMenu}
        />
      </FiltersMenu.Popover>

      {modalElement}
    </>
  );
}

const operatorOptions = ['And', 'Or'];

const PromptModal = styled(ConfirmationModal)`
  ${Field} {
    margin: 0;
  }
`;

FiltersMenu.Popover = styled(Popover)`
  user-select: none;

  ${Popover.Content} {
    max-width: 90rem;
    min-width: auto;
    padding: 6rem;
  }

  ${Collapsible.Title} {
    font-size: var(--font-size-m);
  }

  ${Collapsible.Chevron} {
    color: var(--color-blue-gray-50);
  }
`;

FiltersMenu.Actions = styled.aside`
  ${BaseIcon} {
    flex-shrink: 0;
  }
`;

FiltersMenu.ActionsRow = styled.div`
  display: flex;
  margin-bottom: 3rem;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  &:last-child:not(:only-child) {
    padding-bottom: 3rem;
    border-bottom: 0.25rem solid var(--color-blue-gray-20);
  }
`;

FiltersMenu.Search = styled.input`
  width: 100%;
  height: 9rem;
  padding: 0 3rem;
  line-height: 9rem;
  color: var(--color-blue-gray-70);
  border-radius: 100vmax;
  background-color: var(--color-blue-gray-15);
  transition: background-color 120ms;

  &:focus,
  &:hover,
  &:not(:placeholder-shown) {
    background-color: var(--color-blue-gray-20);
  }
`;

FiltersMenu.Button = styled(SvgIcon)`
  width: 8rem;
  height: 8rem;
  padding: 1rem;
  color: var(--color-blue-gray-50);
  border: 0.25rem solid var(--color-blue-gray-40);
  transition:
    color 400ms,
    border-color 400ms,
    background-color 400ms;
  border-radius: 100vmax;
  cursor: pointer;

  &:hover {
    color: var(--color-blue-gray-60);
    border-color: var(--color-blue-gray-60);
  }

  &.show-menu {
    background-color: var(--color-blue-15);
    border-color: var(--color-blue-gray-65);

    &:hover {
      color: var(--color-blue-gray-65);
      background-color: var(--color-blue-25);
    }
  }

  &.active {
    color: var(--color-blue-gray-65);
    border-color: var(--color-blue-gray-65);
  }
`;

FiltersMenu.OptionGroups = styled.main`
  display: flex;
  flex-direction: column;
  gap: 5rem;

  ${Paragraph} {
    margin-top: 1rem;
    text-align: center;
  }
`;

FiltersMenu.OptionLabel = styled(memo(OptionalTitle))`
  display: flex;
  gap: 1rem;
  padding-right: 1rem;
  overflow: hidden;
`;

FiltersMenu.Option = styled.label`
  display: flex;
  width: fit-content;
  max-width: 100%;
  margin-top: 2rem;
  align-items: center;
  font-size: var(--font-size-s);
  line-height: 6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  gap: 1rem;

  &[data-type='custom'] {
    width: auto;
    justify-content: space-between;
    cursor: unset;

    ${FiltersMenu.OptionLabel} {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      + ${BaseIcon} {
        padding: 1rem;
        margin-right: -0.75rem;
      }
    }
  }
`;

export function includesValue(activeValues, value) {
  return activeValues?.values?.includes?.(String(value)) ?? false;
}
