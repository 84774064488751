import { useDrag as useDndDrag, useDrop as useDndDrop } from 'react-dnd';

export function useDrag<T>({ item, type, ...props }: { item: T; type: string; canDrag: boolean }) {
  const [dragState, dragRef, previewRef] = useDndDrag(
    () => ({
      ...props,
      type,
      item,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        draggedItem: monitor.getItem(),
      }),
    }),
    [item]
  );
  return [dragRef, dragState, previewRef] as const;
}

export function useDrop({ type, onDrop, canDrop, ...props }) {
  const [dropState, dropRef] = useDndDrop(
    () => ({
      ...props,
      accept: type,
      drop: item => onDrop(item),
      canDrop: item => canDrop(item),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop]
  );

  return [dropRef, dropState] as const;
}
