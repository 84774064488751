export enum FeatureFlag {
  ApplicationGroupTags = 'ApplicationGroupTags',
  BlackDuck = 'BlackDuck',
  ContributorActivityGraph = 'ContributorActivityGraph',
  DeployKeys = 'DeployKeys',
  ExtendedRiskPage = 'extendedRiskPage',
  FailPullRequests = 'failPullRequests',
  JFrog = 'JFrog',
  JFrogXray = 'JFrogXray',
  PrismaCloud = 'PrismaCloud',
  CrowdStrike = 'CrowdStrike',
  Mend = 'Mend',
  Fortify = 'Fortify',
  Jenkins = 'Jenkins',
  NewApiControls = 'NewApiControls',
  NewInternalFrameworkDefinition = 'newInternalFrameworkDefinition',
  ProcessTags = 'processTags',
  Qisrp = 'q-isrp',
  Questionnaire = 'questionnaire',
  Questionnaires = 'questionnaires',
  BranchProtection = 'BranchProtection',
  SecretsPlayground = 'SecretsPlayground',
  ExternalSecrets = 'ExternalSecrets',
  GitlabSecretsIngestion = 'GitlabSecretsIngestion',
  ShiftLeft = 'ShiftLeft',
  SpacetimeGraph = 'spacetimeGraph',
  Wiz = 'Wiz',
  FreeTextExplorerQueries = 'FreeTextExplorerQueries',
  SupplyChainRiskTable = 'SupplyChainRiskTable',
  SLATile = 'SLATile',
  InstallBitbucketCloudApplication = 'install-bitbucket-cloud-application',
  PipelineTables = 'pipeline-tables',
  PipelineCICD = 'PipelineCICD',
  Splunk = 'Splunk',
  RisksByAgeAndSeverityTile = 'RisksByAgeAndSeverityTile',
  SupplyChainDashboardAdditionalTiles = 'SupplyChainDashboardAdditionalTiles',
  SupplyChainDashboardAbnormalCommitsTile = 'SupplyChainDashboardAbnormalCommitsTile',
  Invicti = 'Invicti',
  RepositoryTagFilter = 'RepositoryTagFilter',
  ProviderRepositoryTagFilter = 'ProviderRepositoryTagFilter',
  ApplicationTagFilter = 'ApplicationTagFilter',
  UsedInCode = 'UsedInCode',
  DesignRisksV3 = 'DesignRisksV3',
  DesignRisksV3Explorer = 'DesignRisksV3Explorer',
  ExportPdfDashboard = 'ExportPdfDashboard',
  AccessTokenForm = 'AccessTokenForm',
  SmartPolicies = 'SmartPolicies',
  NewCvePane = 'NewCvePane',
  NewCvePaneThirdParty = 'NewCvePaneThirdParty',
  MonorepoProjects = 'monorepoProjects',
  PullWizIssues = 'PullWizIssues',
  PipelinesRisksTable = 'PipelinesRisksTable',
  ProcessedFindingsInExplorer = 'ProcessedFindingsInExplorer',
  FindingsRisk = 'FindingsRisk',
  OrgTeams = 'OrgTeams',
  OrgTeamsCommunication = 'OrgTeamsCommunication',
  ApplicationCommunicationChannel = 'ApplicationCommunicationChannel',
  ApplicationDefaultCommunication = 'ApplicationDefaultCommunication',
  TeamDefaultCommunication = 'TeamDefaultCommunication',
  GitPlain = 'GitPlain',
  ExposurePathV2 = 'ExposurePathV2',
  ScaProviderOrder = 'ScaProviderOrder',
  ExportInventory = 'ExportInventory',
  BurpSuite = 'BurpSuite',
  ReportingBusiness = 'ReportingBusiness',
  ReportingTeams = 'ReportingTeams',
  ReportingLicense = 'ReportingLicense',
  ManualFindingsEntry = 'ManualFindingsEntry',
  Jira2WayIntegration = 'Jira2WayIntegration',
  ServiceNow = 'ServiceNowEnableOnly',
  Artifact = 'Artifact',
  SonatypeLifecycle = 'SonatypeLifecycle',
  CustomReportsV2 = 'CustomReportsV2',
  RolesPageV2 = 'RolesPageV2',
  DisableRisksFunnel = 'DisableRisksFunnel',
  ArtifactsInExplorer = 'ArtifactsInExplorer',
  ShowDependencyScopeInInventory = 'ShowDependencyScopeInInventory',
  ArtifactMatchedSourceCode = 'ArtifactMatchedSourceCode',
  CodeFindingsInExplorer = 'CodeFindingsInExplorer',
  JFrogSCAToggle = 'JFrogSCAToggle',
  AkamaiInternetExposed = 'akamai-internetexposed',
  SupplyChainScmIntegrityToggle = 'SupplyChainScmIntegrityToggle',
  ScaTopLevelRemediationProfile = 'ScaTopLevelRemediationProfile',
  ApplicationCreationForm = 'ApplicationCreationForm',
  ProfileInsightFilter = 'ProfileInsightFilter',
  SearchPageFunnel = 'SearchPageFunnel',
  SmartTableOnRiskPane = 'SmartTableOnRiskPane',
  Orca = 'Orca',
  Backstage = 'Backstage',
  GroupedByCwe = 'GroupedByCwe',
  ArtifactMatchSourceAssets = 'ArtifactMatchSourceAssets',
  ForceAzureCloudOnPremConnector = 'ForceAzureCloudOnPremConnector',
  WorkflowsV2 = 'WorkflowsV2',
  PocExposurePathData = 'PocExposurePathData',
  PocExposurePathRiskyTickets = 'PocExposurePathRiskyTickets',
  EmptyStates = 'EmptyStates',
  GranularSLA = 'GranularSLA',
  TopLevelUsedInCode = 'TopLevelUsedInCode',
  LicenseUsageDifferEnabled = 'LicenseUsageDifferEnabled',
  GroupByArtifact = 'GroupByArtifact',
  RiskScore = 'RiskScore',
  SettingsNewLayout = 'SettingsNewLayout',
  ProductFruit = 'ProductFruit',
  SdElements = 'SdElements',
  UploadCSVReport = 'UploadCSVReport',
  OrgTeamTags = 'OrgTeamTags',
  TopLevelRemediationDisplay = 'TopLevelRemediationDisplay',
  KillSwitch = 'KillSwitch',
  SmartPolicyRisksInPr = 'SmartPolicyRisksInPr',
  SemgrepUIConfig = 'SemgrepUIConfig',
  ConnectorEvents = 'ConnectorEvents',
  SaltSecurity = 'SaltSecurity',
  Tenable = 'Tenable',
  InsightVm = 'InsightVm',
  Nexpose = 'Nexpose',
}
