import { ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { AboutSensitiveDataRiskCard } from '@src-v2/containers/entity-pane/sensitive-data/main-tab/about-sensitive-data-risk-card';
import { RelatedApisCard } from '@src-v2/containers/entity-pane/sensitive-data/main-tab/related-apis-card';

export function SensitiveDataMainTab(props: ControlledCardProps) {
  return (
    <>
      <AboutSensitiveDataRiskCard {...props} />
      <RelatedApisCard {...props} />
    </>
  );
}
