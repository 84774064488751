import _ from 'lodash';
import styled from 'styled-components';
import { SummaryLegacyChip } from '@src-v2/containers/profiles/attack-surface-summary-chips';
import { abbreviate } from '@src-v2/utils/number-utils';
import { DetailsHeading } from './DetailsBlocks';
import { DetailsChips } from './DetailsChips';

export const ProfileFilterResults = ({ results }) => {
  return (
    <>
      <DetailsHeading>Filter Results</DetailsHeading>
      {results.map(({ key, group, title, value, countingRepositories, prefix }) => {
        const filterTitle = `${prefix ? `${prefix}: ` : ''}${title}${
          countingRepositories ? ' repositories' : ''
        }`;
        return _.isBoolean(value) ? (
          <SummaryLegacyChip key={`${key}_${group}`} data-active>
            {filterTitle}
          </SummaryLegacyChip>
        ) : (
          <FilterDetails key={`${key}_${group}`} title={filterTitle}>
            <Counter>{abbreviate(value)}</Counter>
          </FilterDetails>
        );
      })}
    </>
  );
};

const FilterDetails = styled(DetailsChips)`
  width: calc(50% - 1rem);
`;

const Counter = styled.span`
  flex: 1 0 auto;
  min-width: 3.5rem;
  height: 3.75rem;
  padding: 0 1rem;
  margin: 1rem 0 1rem 1.5rem;
  color: var(--default-text-color);
  font-size: var(--font-size-xs);
  font-weight: 600;
  text-align: center;
  line-height: 3.75rem;
  border-radius: 100vmax;
  background-color: var(--color-blue-gray-20);
`;
