import _ from 'lodash';

export function toArray(value) {
  return Array.isArray(value) ? value : typeof value === 'undefined' ? [] : [value];
}

export function extractBy(item, iteratee) {
  return typeof iteratee === 'function' ? iteratee(item) : _.get(item, iteratee);
}

export function mapFromArray(array, keyMapper, valueMapper = _.identity) {
  return new Map(array.map(item => [extractBy(item, keyMapper), extractBy(item, valueMapper)]));
}

export function toggleValues(array, ...values) {
  return _.xor(array, values);
}
