import { ActiveFiltersData } from '@src-v2/hooks/use-filters';
import { transformLegacyFilters } from '@src-v2/services';
import { BaseOverviewService } from '@src-v2/services/overview/base-overview-service';
import { BusinessImpactAbbreviation } from '@src-v2/types/enums/business-impact';
import {
  AppsRiskScoreResponse,
  CommitsOverTimeResponse,
  PullRequestsOverTimeResponse,
  RiskScoreTrendResponse,
  SlaBreachesResponse,
  WorkflowActionsOverTimeResponse,
} from '@src-v2/types/overview/overview-responses';

function transformBusinessImpact(data: AppsRiskScoreResponse[]): AppsRiskScoreResponse[] {
  return data.map(item => ({
    ...item,
    businessImpact: BusinessImpactAbbreviation[item.businessImpact.toLowerCase()],
  }));
}

export class Overview extends BaseOverviewService {
  constructor({ apiClient, organization }) {
    super({ apiClient, baseUrl: 'overview-dashboard', organization });
  }

  getPullRequestsOverTime({ filters }): Promise<PullRequestsOverTimeResponse> {
    return this.client.get(`${this.baseUrl}/prs-over-time`, {
      params: { filters: transformLegacyFilters(filters) },
      shouldTransformDates: true,
    });
  }

  async getAppsCountByBusinessImpactAndRiskScore({
    filters,
  }: {
    filters: ActiveFiltersData;
  }): Promise<AppsRiskScoreResponse[]> {
    const response = await this.client.get(`${this.baseUrl}/risk-score-heatmap`, {
      params: { filters: transformLegacyFilters(filters) },
      shouldTransformDates: true,
    });
    return transformBusinessImpact(response);
  }

  getRiskyCommitsOverTime({ filters }: { filters: any }): Promise<CommitsOverTimeResponse> {
    return this.client.get(`${this.baseUrl}/commits-over-time`, {
      params: { filters: transformLegacyFilters(filters) },
      shouldTransformDates: true,
    });
  }

  getRiskScoreTrend({ filters }): Promise<RiskScoreTrendResponse> {
    return this.client.get('/risk-score/trend', {
      params: { filters: transformLegacyFilters(filters) },
    });
  }

  getCoverageSummary({ filters }) {
    return this.client.get(`${this.baseUrl}/coverage-summary`, {
      params: { filters: transformLegacyFilters(filters) },
      shouldTransformDates: true,
    });
  }

  getWorkflowRemediationActions({ filters }): Promise<WorkflowActionsOverTimeResponse> {
    return this.client.get(`${this.baseUrl}/workflow-actions-over-time`, {
      params: { filters: transformLegacyFilters(filters) },
      shouldTransformDates: true,
    });
  }

  async hasPRAppm(): Promise<boolean> {
    return await this.client.get(`${this.baseUrl}/has-pr-app`);
  }

  getSLABreachesByRiskLevel({ filters }): Promise<SlaBreachesResponse> {
    return this.client.get(`${this.baseUrl}/sla-breach`, {
      shouldTransformDates: true,
      params: { filters: transformLegacyFilters(filters) },
    });
  }
}
