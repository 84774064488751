import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorTypeMapping } from '@src-v2/components/forms/field-error-display';
import { SelectControlV2 } from '@src-v2/components/forms/form-controls';
import { FormTagValue } from '@src-v2/containers/profiles/profile-tags/manage-profile-tags-modal';
import { useConditionalValidation, useValidation } from '@src-v2/hooks';
import { TagOption } from '@src-v2/types/profiles/tags/profile-tag';

interface SingleTagSelectionProps {
  name: string;
  tagOptions: TagOption[];
  selectedTagOptions: TagOption[];
}

export function SingleTagSelection({
  name: fieldName,
  tagOptions,
  selectedTagOptions,
}: SingleTagSelectionProps) {
  const { validateEmptyItem } = useValidation();

  const selectedTag: FormTagValue = useWatch({ name: fieldName });
  const { setValue } = useFormContext();

  const currentTagOptionalValues = useMemo(
    () => selectedTag?.keyOption?.optionalValues.map(value => ({ label: value, value })) ?? [],
    [selectedTag]
  );

  const handleNameChanged = useCallback(
    () => setValue(`${fieldName}.valueOption`, null),
    [fieldName, setValue]
  );

  const validateTagKeyOption = useCallback(
    (keyOption: TagOption) =>
      !keyOption
        ? ErrorTypeMapping.required
        : keyOption.name.includes(' ')
          ? "Tag name can contain only out of alphanumeric characters or '_'"
          : true,
    []
  );

  const validateNewTagOption = useCallback(
    (inputValue: string) => {
      return Boolean(inputValue) && !selectedTagOptions.some(tag => tag.name === inputValue);
    },
    [selectedTagOptions]
  );

  return (
    <>
      <SelectControlV2
        creatable
        name={`${fieldName}.keyOption`}
        placeholder="Type a key..."
        options={tagOptions}
        getNewOptionData={(inputValue, label) => ({
          key: inputValue,
          name: label,
          optionalValues: [],
        })}
        getOptionLabel={tag => tag.name}
        isValidNewOption={validateNewTagOption}
        rules={{
          validate: useConditionalValidation(validateTagKeyOption, fieldName),
        }}
        onChange={handleNameChanged}
      />{' '}
      :{' '}
      <SelectControlV2
        creatable
        placeholder="Type a value..."
        name={`${fieldName}.valueOption`}
        disabled={!selectedTag?.keyOption}
        isValidNewOption={(inputValue: string) => Boolean(inputValue)}
        options={currentTagOptionalValues}
        rules={{
          validate: useConditionalValidation(validateEmptyItem, fieldName),
        }}
      />
    </>
  );
}
