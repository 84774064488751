import { ElementRef, useCallback, useRef } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { DefaultErrorFallback } from '@src-v2/components/async-boundary';
import { Page } from '@src-v2/components/layout/page';
import { ReportingDashboardHeader } from '@src-v2/containers/pages/reporting/components/reporting-dashboard-header';
import { useReportingReady } from '@src-v2/containers/pages/reporting/hooks/use-measure-reporting-performance';
import { useMetabaseLoadingState } from '@src-v2/containers/pages/reporting/hooks/use-metabse-loading-state';
import { useRenderReportingPdf } from '@src-v2/containers/pages/reporting/hooks/use-render-reporting-pdf';
import { useUpdateIframeQueryParamsFromReferer } from '@src-v2/containers/pages/reporting/hooks/use-update-iframe-query-params-from-referer';
import { makeReportingSharedUrl } from '@src-v2/containers/pages/reporting/predefined-report';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useBreadcrumbs } from '@src-v2/hooks/use-breadcrumbs';
import { useShareLinkModal } from '@src-v2/hooks/use-share-link-modal';

export default function CustomReportPage() {
  const { reporting } = useInject();
  const path = useParams<{ dashboard: string }>();
  const trackAnalytics = useTrackAnalytics();
  const iframeRef = useRef<ElementRef<'iframe'>>();
  const location = useLocation();
  const {
    params: { id: customReportIdentifier },
  } = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const [id] = customReportIdentifier.split('-');
  const dashboardData = useSuspense(reporting.getDashboardById, id);
  const isInEditMode = dashboardData.dashcards?.length === 0 || location.pathname.includes('/edit');

  const [state, setState] = useMetabaseLoadingState(async () => {
    if (isInEditMode) {
      return await reporting.getEditCustomReportIframeUrl(customReportIdentifier);
    }
    return await reporting.getDisplayCustomReportIframeUrl(customReportIdentifier);
  });

  useBreadcrumbs({
    breadcrumbs: [
      { label: 'Overview', to: '/reporting' },
      {
        label: dashboardData.name,
        to: `/reporting/${customReportIdentifier}`,
      },
    ],
  });

  const onDashboardReady = useCallback((timeToLoad: string) => {
    setState(prevState => ({ ...prevState, ready: true }));
    trackAnalytics(AnalyticsEventName.Loaded, {
      [AnalyticsDataField.PageName]: `Custom report`,
      [AnalyticsDataField.DashboardName]: path.dashboard,
      [AnalyticsDataField.TimeToLoad]: timeToLoad,
    });
  }, []);

  const { isDownloadingPdf, handleDownloadPdf } = useRenderReportingPdf(iframeRef, true);
  const { showShareModal, shareLinkModalElement } = useShareLinkModal();

  useReportingReady(iframeRef, state.pageLoading, onDashboardReady);
  useUpdateIframeQueryParamsFromReferer(iframeRef);

  return (
    <Page title="Custom reports">
      <>
        {shareLinkModalElement}
        {!isInEditMode && (
          <ReportingDashboardHeader
            pageLoading={state.pageLoading}
            reportReady={state.ready}
            isDownloadingPdf={isDownloadingPdf}
            handleDownloadPdf={handleDownloadPdf}
            headerContent={{
              title: dashboardData.name,
              reportName: dashboardData.name,
              description:
                dashboardData.description ??
                'This is a custom report generated by your organization',
            }}
            handleEditReport={() => history.push(`${history.location.pathname}/edit`)}
            handleShareLink={() => showShareModal(makeReportingSharedUrl(iframeRef))}
            customReport
          />
        )}

        {state.pageLoading && (
          <Centered>
            <LogoSpinner />
          </Centered>
        )}
        {state.error && <Centered>{state.error}</Centered>}
        {state.iframeUrl && (
          <ReportsFrame
            ref={iframeRef}
            onError={() => setState(prev => ({ ...prev, error: <DefaultErrorFallback /> }))}
            onLoad={() => setState(prevState => ({ ...prevState, loading: false }))}
            src={state.iframeUrl}
          />
        )}
      </>
    </Page>
  );
}

const ReportsFrame = styled.iframe`
  width: 100%;
  height: calc(100vh - var(--top-bar-height) - 0.5rem);
`;

const Centered = styled.div`
  width: 100%;
  height: calc(100vh - var(--top-bar-height));
  display: flex;
  align-items: center;
  justify-content: center;
`;
