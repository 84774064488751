import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorBoundary } from '@src-v2/components/error-boundary';
import { SvgIcon } from '@src-v2/components/icons';
import { Gutters, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { TabsRouter } from '@src-v2/components/tabs/tabs-router';
import { generateCommitReferenceUrl, generatePullRequestUrl } from '@src-v2/data/connectors';
import { useInject, useQueryParams, useSessionStorage } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { ProfileRiskBadge } from '@src/blocks/ConsumableInfiniteScroll/blocks/ProfileRiskBadge';
import { PaneBody } from '@src/blocks/Pane/view';
import { ReleaseQuestionnaireTable } from '@src/blocks/ReleasePage/ReleaseQuestionnaireTable';
import EmptyState from '@src/blocks/RiskPosture/blocks/DetailsPane/EmptyState';
import { isSupportedTrigger } from '@src/blocks/RiskPosture/blocks/DetailsPane/TriggerDisplayUtils';
import { ManageRiskTable } from '@src/blocks/RiskPosture/blocks/ManageRiskTable';
import { onPaneClose, riskPanePrefix } from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Common';
import RiskRuleTriggers from '@src/blocks/RiskRuleTriggers';
import { FilterButtons } from '@src/components/FilterButtons';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import {
  getLabelsToGovernanceRuleKeys,
  materialChangeLabelsToCount,
} from '@src/components/MaterialChange/MaterialChangeUtils';
import { MaterialChanges } from '@src/components/MaterialChanges';
import { PageLoading } from '@src/components/PageLoading';
import { Tooltip } from '@src/components/Tooltip';
import profilesService from '@src/services/profilesService';
import { dispatch } from '@src/store';
import { FontBody } from '@src/style/common';
import { getShortSha } from '@src/utils/gitShaUtils';

const StyledTabs = styled(TabsRouter)`
  margin-bottom: 9px;
`;

const StyledContent = styled(Gutters)`
  margin-top: 9rem;
  max-width: calc(100vw - var(--sidebar-menu-width));
`;

const StyledTooltip = styled(Tooltip)`
  ${FontBody};
  font-weight: bold;
`;

const ReleasePage = ({
  releaseKey,
  release,
  materialChanges,
  isMonitored,
  repository,
  developerProfileByIdentityKey,
  fetchData,
  clearData,
  repositoryProfile,
  governanceRulesByKey,
}) => {
  const { apiClient, application, history } = useInject();
  const { queryParams, updateQueryParams } = useQueryParams();
  const { pathname } = useLocation();

  const handleFilterChange = useCallback(
    selectedFilters =>
      updateQueryParams({
        labels: selectedFilters.length ? selectedFilters : null,
      }),
    []
  );

  useEffect(() => clearData, [clearData]);

  useEffect(() => {
    fetchData(releaseKey);
  }, [fetchData, releaseKey]);

  const [{ questionnaires }, setPageData] = useSessionStorage('demo.questionnaires', {});

  useEffect(() => {
    if (!application.isFeatureEnabled(FeatureFlag.Questionnaires)) {
      setPageData({ questionnaires: [] });
    } else if (_.isNil(questionnaires)) {
      apiClient.get('questionnaires').then(setPageData);
    }
  }, []);

  const basePath = release && `/releases/${release.key}`;

  const processedProfileRiskTriggers = useMemo(
    () =>
      release &&
      profilesService.processProfileRiskTriggers(
        release.profileRiskTriggerSummaries,
        governanceRulesByKey
      ),
    [governanceRulesByKey, release]
  );

  const onRiskDetailsClickCallback = useCallback(
    (ruleTriggers, urlPrefix) => {
      const newPath = `${basePath}/compliance/${riskPanePrefix}/${ruleTriggers.ruleKey}`;
      if (!pathname.startsWith(newPath)) {
        history.push(newPath);
      }

      dispatch.pane.openPane({
        onClose: () => onPaneClose(riskPanePrefix),
        relevantPath: riskPanePrefix,
        id: ruleTriggers.rule.ordinalId,
        innerPane: true,
        content: (
          <PaneBody
            body={
              ruleTriggers?.triggers.some(isSupportedTrigger) ? (
                <ErrorBoundary
                  extraInfo={{ inventory: release.inventory, ruleKey: ruleTriggers.ruleKey }}>
                  <RiskRuleTriggers
                    profile={release.candidate.repositoryProfile}
                    previousProfile={release.baseline.repositoryProfile}
                    profileType="RepositoryProfile"
                    profileTriggers={release.profileRiskTriggerSummaries}
                    ruleKey={ruleTriggers.ruleKey}
                    isRelease
                  />
                </ErrorBoundary>
              ) : (
                <EmptyState ruleTriggers={ruleTriggers} urlPrefix={urlPrefix} />
              )
            }
          />
        ),
      });
    },
    [pathname, release]
  );

  if (!release || !questionnaires) {
    return <PageLoading />;
  }

  const isPullRequest = !_.isNil(release.pullRequestId);

  const finishedProcessingRelease =
    release.isDone || (isPullRequest && (release.isOutdated || release.isClosed));

  const isReleaseReadyToShowResults =
    isMonitored && (finishedProcessingRelease || release.isDiffedForPrComments);

  const isPullRequestInProgressWithPartialResults =
    isPullRequest && release.isDiffedForPrComments && !finishedProcessingRelease;

  const labelsToMaterialChangeKeys = materialChanges?.labelsToMaterialChangeKeys;
  const labelsToGovernanceRuleKeys =
    materialChanges && getLabelsToGovernanceRuleKeys(materialChanges.governanceRules);

  const releaseLinkData = isPullRequest
    ? {
        text: 'View pull request',
        url: repository && generatePullRequestUrl(repository, release.pullRequestId),
        identifier: `ID: ${release.pullRequestId}`,
      }
    : {
        text: 'View commit',
        url: repository && generateCommitReferenceUrl(repository, release.candidate.commitSha),
        identifier: `Commit: ${getShortSha(release.candidate.commitSha)}`,
      };

  const relevantQuestionnaire = questionnaires?.[0];

  return (
    <Page>
      <StickyHeader title={release.name}>
        {isReleaseReadyToShowResults && (
          <HorizontalStack>
            {!isPullRequest && (
              <ProfileRiskBadge
                profile={{
                  ...(repositoryProfile || repository),
                  risk: release.candidate.risk,
                }}
                showBusinessImpact="never"
              />
            )}
            <StyledTooltip tip={releaseLinkData.identifier}>
              <Link openInNewTab url={releaseLinkData.url}>
                <SvgIcon name="External" /> {releaseLinkData.text}
              </Link>
            </StyledTooltip>
          </HorizontalStack>
        )}
      </StickyHeader>

      <StyledContent>
        {_.isEmpty(repository) ? (
          <>
            Unfortunately we cannot assess the risk for this release, since the repository was not
            found.
          </>
        ) : !isMonitored ? (
          <>
            Unfortunately we cannot assess the risk for this release, since '{repository.name}'
            repository is not monitored.
            <br />
            <Link url={`/connectors?searchTerm="${repository.name}"`} underline>
              Monitor this repository
            </Link>
            &nbsp;and try again.
          </>
        ) : !isReleaseReadyToShowResults ? (
          'Release risk assessment still in progress'
        ) : (
          <StyledTabs
            routes={[
              {
                title: `${relevantQuestionnaire?.name} Questionnaire`,
                path: 'questionnaire',
                condition: application.isFeatureEnabled(FeatureFlag.Questionnaires),
                render: () => (
                  <ErrorBoundary extraInfo={{ release }}>
                    <ReleaseQuestionnaireTable
                      governanceRulesByKey={governanceRulesByKey}
                      questionnaire={questionnaires[0]}
                    />
                  </ErrorBoundary>
                ),
              },
              {
                title: 'Governance Compliance',
                path: 'compliance',
                condition: Boolean(release.profileRiskTriggerSummaries),
                render: () => (
                  <ErrorBoundary extraInfo={{ release }}>
                    <ManageRiskTable
                      isRelease
                      tableTitle="Policy"
                      profileType="RepositoryProfile"
                      profile={release.candidate.repositoryProfile}
                      profileRiskTriggers={processedProfileRiskTriggers}
                      onRiskDetailsClickCallback={
                        release.candidate.repositoryProfile ? onRiskDetailsClickCallback : null
                      }
                    />
                  </ErrorBoundary>
                ),
              },
              {
                title: (
                  <TabTitle>
                    Release Highlights
                    {isPullRequestInProgressWithPartialResults && (
                      <PartialResultsTitle>
                        <SvgIcon name="Learning" />
                        Full scan in progress, showing partial results
                      </PartialResultsTitle>
                    )}
                  </TabTitle>
                ),
                path: 'highlights',
                render: () => (
                  <FilterButtons
                    queryFilters={queryParams.labels}
                    onFilterChanged={handleFilterChange}
                    defaultFilter={{ name: 'All', count: materialChanges.count }}
                    filterToCountMapping={materialChangeLabelsToCount(labelsToMaterialChangeKeys)}
                    multiselect>
                    {selectedLabels => (
                      <MaterialChanges
                        repository={repository}
                        governanceRules={
                          _.isEmpty(selectedLabels)
                            ? materialChanges.governanceRules
                            : materialChanges.governanceRules.filter(rule =>
                                selectedLabels
                                  .flatMap(label => labelsToGovernanceRuleKeys[label])
                                  .includes(rule.key)
                              )
                        }
                        materialChangesByRuleKey={materialChanges.materialChangesByRuleKey}
                        commitSha={release.candidate.commitSha}
                        selectedMaterialChangeKeys={
                          _.isEmpty(selectedLabels)
                            ? []
                            : selectedLabels.flatMap(label => labelsToMaterialChangeKeys[label])
                        }
                        developerProfileByIdentityKey={developerProfileByIdentityKey}
                        isReleaseContext
                      />
                    )}
                  </FilterButtons>
                ),
              },
            ]}
          />
        )}
      </StyledContent>
    </Page>
  );
};

export default ReleasePage;

const PartialResultsTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
  margin-left: 3rem;
  gap: 1rem;
`;

const TabTitle = styled.span`
  display: flex;
  align-items: center;
`;
