import styled from 'styled-components';
import { useInject } from '@src-v2/hooks';

export function ContactUsLink() {
  const {
    // @ts-expect-error
    config: { CONTACT_US_EMAIL },
  } = useInject();
  return (
    <EmailLink href={`mailto:${CONTACT_US_EMAIL}`} target="_blank">
      {CONTACT_US_EMAIL}
    </EmailLink>
  );
}

const EmailLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;
