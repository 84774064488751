import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { CommitCodeReference, thenSubTypeToVerb } from './MaterialChangeUtils';
import { StyledHighlightedCode, StyledWrappedTitledList } from './Styles';

const SecurityControlsMaterialChange = ({ commitSha, repository, materialChange, thenSubType }) => {
  if (materialChange.apiCodeReference) {
    const { apiName, methodName, methodSignature, relativeFilePath } =
      materialChange.apiCodeReference;
    return (
      <StyledWrappedTitledList
        title={
          <VerticalStack>
            <HorizontalStack>
              <span>API method</span>
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={relativeFilePath}>
                {apiName || methodName}
              </CommitCodeReference>
              <span>has potential security issues</span>
            </HorizontalStack>
            <StyledHighlightedCode code={methodSignature} language="java" />
          </VerticalStack>
        }
        list={materialChange.descriptions}
      />
    );
  }

  const { name, relativeFilePath } = materialChange.classCodeReference;
  return (
    <StyledWrappedTitledList
      title={
        <HorizontalStack>
          <span>Web security configuration class</span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}>
            {name}
          </CommitCodeReference>
          <span>was</span>
          <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
        </HorizontalStack>
      }
      list={materialChange.descriptions}
    />
  );
};

export default SecurityControlsMaterialChange;
