import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import styled from 'styled-components';
import { pluralFormat } from '@src-v2/utils/number-utils';
import Bold from '@src/components/Bold';
import CodeReferenceLink from '@src/components/CodeReferenceLink';
import Collapsible from '@src/components/Collapsible';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { CommitCodeReference } from '@src/components/MaterialChange/MaterialChangeUtils';
import { Number } from '@src/components/Number';
import { Tooltip } from '@src/components/Tooltip';
import { VerticalStack } from '@src/components/VerticalStack';

export const ExitPointKinds = {
  LOG: {
    enumValue: 'LOG',
    display: 'logs',
    actionDescription: 'Written to logs',
  },
};

const StyledDividerContainer = styled.div`
  padding-left: 3rem;
`;

const StyledExitPointsContainer = styled.div`
  border-left: 0.25rem solid var(--color-blue-gray-35);
  padding-left: 3rem;
`;

const StyledHorizontalStack = styled(HorizontalStack)`
  margin-bottom: 3px;
`;

const StyledEntityName = styled(Bold)`
  margin-left: 6px;
`;

export const ExitPoints = ({ exitPointsByMember, repository, commitSha }) => (
  <StyledDividerContainer>
    <StyledExitPointsContainer>
      <VerticalStack>
        {Object.values(
          mapValues(exitPointsByMember, (exitPoints, member) =>
            renderExitPoint(exitPoints, member, repository, commitSha)
          )
        )}
      </VerticalStack>
    </StyledExitPointsContainer>
  </StyledDividerContainer>
);

export const ExitPointsHeader = ({
  exitPoints,
  dataOfInterestFamilyType,
  name,
  relativeFilePath,
  showName,
}) => {
  const exitPointsByMember = groupBy(exitPoints, 'member');
  const allUniqueKindDisplays = () => [
    ...new Set(exitPoints.map(_ => ExitPointKinds[_.kind]?.display)),
  ];
  const exitPointsCount = Object.keys(exitPointsByMember).length;
  return (
    <HorizontalStack>
      <Number
        value={exitPointsCount}
        one={`${dataOfInterestFamilyType} field`}
        other={`${dataOfInterestFamilyType} fields`}
      />
      {showName && (
        <>
          of data model
          <Tooltip tip={relativeFilePath}>
            <StyledEntityName>{name}</StyledEntityName>
          </Tooltip>
        </>
      )}
      <span>
        {pluralFormat(exitPointsCount, 'is', 'are')} written to {allUniqueKindDisplays().join(',')}
      </span>
    </HorizontalStack>
  );
};

const exitPointDescription = (exitPoint, index, repository, commitSha) => {
  const { codeReference } = exitPoint;
  const methodName = exitPoint.codeReference.name.split('#')[1] ?? '';
  const className = exitPoint.codeReference.name.split('#')[0]?.split('.')?.slice(-1)[0] ?? '';
  const methodDisplay = methodName ? `${className}.${methodName}` : className;
  return (
    <StyledHorizontalStack spacing="3px" key={`${exitPoint.member}-${index}`}>
      <span>{`${ExitPointKinds[exitPoint.kind]?.actionDescription ?? 'Written'} in`}</span>
      {commitSha ? (
        <CommitCodeReference
          underline={false}
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={codeReference.relativeFilePath ?? ''}>
          <StyledEntityName>{methodDisplay}</StyledEntityName>
        </CommitCodeReference>
      ) : (
        <CodeReferenceLink
          lineNumber={codeReference.lineNumber}
          repository={repository}
          relativeFilePath={codeReference.relativeFilePath ?? ''}>
          <StyledEntityName>{methodDisplay}</StyledEntityName>
        </CodeReferenceLink>
      )}
    </StyledHorizontalStack>
  );
};

const renderExitPoint = (exitPoints, member, repository, commitSha) => (
  <Collapsible
    key={member}
    title={
      <HorizontalStack withSeparatorAfter>
        <div>{member}</div>
        <Number underline value={exitPoints.length} one="Time" other="Times" />
      </HorizontalStack>
    }>
    <StyledExitPointsContainer>
      {exitPoints.map((exitPoint, index) =>
        exitPointDescription(exitPoint, index, repository, commitSha)
      )}
    </StyledExitPointsContainer>
  </Collapsible>
);
