import uniq from 'lodash/uniq';

export default {
  state: {},
  reducers: {},
  selectors: () => ({}),
  effects: dispatch => ({
    async fetchData({ issues }) {
      const developerIdentityKeys = uniq(
        issues.flatMap(issue => Object.keys(issue.assigneeIdentitiesKeysToActivityTime))
      );
      await dispatch.developerProfiles.getDevelopersByIdentitiesKeysAsync({
        keys: developerIdentityKeys,
      });
    },
  }),
};
