import { renderTipContent } from '@src/components/ReactSelect/ReactSelectComponents';

export function useGroupProperties(props, ...propGroups) {
  const leftoverProps = { ...props };
  const customProps = propGroups
    .map(keys =>
      keys.reduce((groupProps, key) => {
        if (props.hasOwnProperty(key)) {
          groupProps[key] = props[key];
        }
        delete leftoverProps[key];
        return groupProps;
      }, {})
    )
    .concat(leftoverProps);
  customProps[0].content = renderTipContent(customProps[0].content);
  return customProps;
}
