import styled from 'styled-components';
import { Heading, ListItem, Paragraph, Strong, UnorderedList } from '@src-v2/components/typography';
import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

const Fields = styled(({ fieldNames }) => (
  <UnorderedList>
    {fieldNames.map(field => (
      <ListItem key={field}>{field}</ListItem>
    ))}
  </UnorderedList>
))`
  border-left: 0.25rem solid var(--color-blue-gray-35);
  padding-left: 3rem;
`;

export const SensitiveDataMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  lineNumber,
  materialChange,
  whenValue,
  thenSubType,
  fieldNames,
  name,
  methodName,
}) => {
  switch (whenValue) {
    case 'Exposed':
    case 'Involved':
      const { sensitiveDataCodeReference } = materialChange;
      return (
        <>
          <Heading>
            Data model
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}
              lineNumber={lineNumber}>
              {sensitiveDataCodeReference.name}
            </CommitCodeReference>
            <Paragraph>
              {' '}
              that contains {materialChange.associatedSensitiveDataTypeDescription} fields is{' '}
              {whenValue === 'Exposed' ? 'exposed by' : 'involved in the flow of'} API method
            </Paragraph>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}>
              {methodName}
            </CommitCodeReference>
          </Heading>
          <Fields fieldNames={fieldNames} />
        </>
      );

    default:
      return (
        <>
          <Heading>
            <Paragraph>
              {String(materialChange.associatedSensitiveDataTypeDescription)} field was
            </Paragraph>
            <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}>
              {name || relativeFilePath}
            </CommitCodeReference>
          </Heading>
          <Fields fieldNames={[materialChange.sensitiveDataName]} />
        </>
      );
  }
};
