import { ContributorTooltip } from '@src-v2/containers/contributors/contributor-tooltip';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';

const DeveloperFirstCommitToRepositoryMaterialChange = ({
  materialChange,
  developerProfileByIdentityKey,
}) => (
  <VerticalStack>
    <HorizontalStack>
      <Bold>Developer’s first commit to repository</Bold>{' '}
      <ContributorTooltip
        hideImage
        developerProfile={developerProfileByIdentityKey[materialChange.authorIdentityKey]}
      />
    </HorizontalStack>
  </VerticalStack>
);

export default DeveloperFirstCommitToRepositoryMaterialChange;
