import { ApiClient, SearchParams } from '@src-v2/services/api-client';
import { AggregationResult } from '@src-v2/types/aggregation-result';
import { Provider } from '@src-v2/types/enums/provider';
import { LeanConsumable } from '@src-v2/types/profiles/lean-consumable';
import { ProjectProfile } from '@src-v2/types/profiles/project-profile';

interface ProjectUser {
  key: string;
  email: string;
  displayName: string;
}

interface IssueSummary {
  id: string;
  key: string;
  summary: string;
}

export class Projects {
  #client: ApiClient;

  constructor({ apiClient }) {
    this.#client = apiClient;
  }

  searchUsers({
    projectKey,
    ...options
  }: { projectKey: string } & Partial<SearchParams>): Promise<ProjectUser[]> {
    return this.#client.search(`/projects/${projectKey}/users/search`, options);
  }

  searchIssues({
    projectKey,
    ...options
  }: {
    projectKey: string;
    searchTerm?: string;
    specificIssueTypes?: string[];
    isParentSearch?: boolean;
    issueType?: string;
  }): Promise<IssueSummary[]> {
    return this.#client.search(`/projects/${projectKey}/issues/search`, options);
  }

  // This endpoint should replace both `workflow.getMonitoredTicketingProjects`
  // and `ticketingIssues.getMonitoredProjects` in the future

  searchMonitoredProjects({
    provider,
    ...searchParams
  }: {
    provider: Provider;
  } & Partial<SearchParams>): Promise<AggregationResult<ProjectProfile>> {
    return this.#client.search(`/projects/v2/${provider}/search`, searchParams);
  }

  searchLeanProjects = async (
    params: Pick<SearchParams, 'limit' | 'searchTerm' | 'pageNumber'>
  ): Promise<AggregationResult<LeanConsumable>> => {
    return await this.#client.search('projects/v2/search/lean', params);
  };
}
