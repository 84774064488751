import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { rbacTypeToNameFromCodeReference } from '@src/utils/roleUtils';
import { CommitCodeReference, thenSubTypeToVerb } from './MaterialChangeUtils';
import { StyledWrappedTitledList } from './Styles';

export default ({ materialChange, thenSubType, repository, commitSha }) => {
  const { name: roleName, usageIndications } = materialChange;

  const rbacTypeToDisplayString = {
    MethodAuthorization: 'To authorization method of',
    SecurityConfiguration: 'To security configuration',
    Swagger: 'To Swagger definition',
  };

  const rbacTypeToEntity = {
    MethodAuthorization: 'Method',
    SecurityConfiguration: 'Security configuration',
    Swagger: 'Swagger definition',
  };

  const indicationToString = usageIndication => {
    const {
      rbacType,
      usages: [firstIndication, ...remainingIndications],
    } = usageIndication;
    return (
      <HorizontalStack>
        <span>{rbacTypeToDisplayString[rbacType]}</span>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={firstIndication.relativeFilePath}>
          {rbacTypeToNameFromCodeReference[rbacType](firstIndication)}
        </CommitCodeReference>
        {remainingIndications.length > 0 ? (
          <HorizontalStack>
            <span>and</span>
            <PopoverTip
              linkText={
                <Number
                  one={`more ${rbacTypeToEntity[rbacType].toLowerCase()}`}
                  other={`more ${rbacTypeToEntity[rbacType].toLowerCase()}s`}
                  value={remainingIndications.length}
                />
              }
              title={
                <Number
                  one={String(rbacTypeToEntity[rbacType])}
                  other={`${rbacTypeToEntity[rbacType]}s`}
                  value={remainingIndications.length}
                />
              }>
              {remainingIndications.map((indication, index) => (
                <CommitCodeReference
                  key={index}
                  repository={repository}
                  commitSha={commitSha}
                  relativeFilePath={indication.relativeFilePath}>
                  {rbacTypeToNameFromCodeReference[rbacType](indication)}
                </CommitCodeReference>
              ))}
            </PopoverTip>
          </HorizontalStack>
        ) : null}
      </HorizontalStack>
    );
  };

  return (
    <StyledWrappedTitledList
      title={
        <HorizontalStack>
          <span>API Role</span>
          <Bold>{roleName}</Bold>
          <span> was </span>
          <span>{thenSubTypeToVerb(thenSubType)}</span>
        </HorizontalStack>
      }
      list={usageIndications.map(usageIndication => indicationToString(usageIndication))}
    />
  );
};
