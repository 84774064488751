import { HorizontalStack } from '@src/components/HorizontalStack';
import { CommitCodeReference } from '@src/components/MaterialChange/MaterialChangeUtils';

const TerraformResourceMaterialChange = ({ commitSha, repository, materialChange }) => {
  const resource = materialChange.resourceSummary;
  return (
    <HorizontalStack>
      <span>resource</span>
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={resource.path}>
        {`${resource.typeDescription} (${resource.key})`}
      </CommitCodeReference>
      <span>{`of type ${resource.iacCategory} was ${materialChange.changeType} to module`}</span>
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={resource.modulePath}>
        {resource.modulePath === '/' ? 'root' : resource.modulePath}
      </CommitCodeReference>
    </HorizontalStack>
  );
};

export default TerraformResourceMaterialChange;
