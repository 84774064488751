import styled from 'styled-components';

export const DetailsChips = styled(({ title, children, ...props }) => (
  <div {...props}>
    <DetailsChips.Title title={title}>{title}</DetailsChips.Title>
    {children}
  </div>
))`
  display: flex;
  height: 24px;
  padding: 2px 6px 2px 8px;
  align-items: center;
  border-radius: 100vmax;
  background-color: #fff;
  gap: 8px;
`;

DetailsChips.Title = styled.span`
  flex: 100 1 auto;
  padding: 2px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
