import { ApiClient } from '@src-v2/services/api-client';

export class PullRequestScan {
  private client: ApiClient;
  private baseUrl: string;

  constructor({ apiClient }) {
    this.client = apiClient;
    this.baseUrl = 'pullRequestScanKillSwitch';
  }

  async getKillSwitchConfiguration() {
    try {
      return await this.client.get(`${this.baseUrl}/pullRequestScanKillSwitch`);
    } catch (error) {
      console.error('Failed to fetch kill switch configuration:', error);
      throw error;
    }
  }

  setKillSwitchConfiguration() {
    try {
      return this.client.post(`${this.baseUrl}/pullRequestScanKillSwitch`);
    } catch (error) {
      console.error('Failed to set kill switch configuration:', error);
      throw error;
    }
  }
}
