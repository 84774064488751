import _ from 'lodash';
import styled from 'styled-components';
import ApiiroLogoSmall from '@src-v2/assets/apiiro-logo-small.svg';
import { SvgIcon } from '@src-v2/components/icons';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { useInject, useSessionStorage } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { Trigger } from '@src/blocks/QuestionnaireForm/questionnaire-form';
import { CircledIcon } from '@src/blocks/RiskPosture/blocks/styles';
import { Link } from '@src/components/Link';
import { Table } from '@src/components/Table';
import TooltippedEllipsis from '@src/components/TooltippedEllipsis';
import { VerticalStack } from '@src/components/VerticalStack';

export const ReleaseQuestionnaireTable = ({ questionnaire }) => {
  const { application } = useInject();
  const [{ predefinedQuestions, triggers }] = useSessionStorage('demo.questionnaires', {});

  const data = application.isFeatureEnabled(FeatureFlag.Qisrp) ? mockDataIsrp : mockDataHeatMap;
  return (
    <Table
      noTopPadding
      small
      cellsBackground
      headerBackground={false}
      headers={[
        { name: questionnaire.title, weight: 8, fontWeight: 'thin' },
        { name: 'Triggers', weight: 2 },
        { name: 'Answered by', weight: 1, align: 'center' },
        { name: 'Violations', weight: 1, align: 'center' },
      ]}
      rows={_.map(questionnaire.questions, question => {
        const predefinedQuestion =
          predefinedQuestions.find(_ => _.key === question.predefinedQuestionKey) ?? question;
        const { ruleName } = predefinedQuestion;

        return {
          key: question.key,
          cells: [
            {
              content: (
                <TooltippedEllipsis
                  text={
                    <span>
                      <b>{question.title}</b> - {predefinedQuestion.description}
                    </span>
                  }
                  tip={predefinedQuestion.description}
                />
              ),
            },
            {
              content: (
                <>
                  <Trigger>{triggers[predefinedQuestion.triggers?.[0]]}</Trigger>
                  {predefinedQuestion.triggers?.length > 1 && (
                    <Tooltip
                      content={predefinedQuestion.triggers?.slice(1).map(trigger => (
                        <Trigger key={trigger}>{triggers[trigger]}</Trigger>
                      ))}>
                      <StyledTrigger>+{predefinedQuestion.triggers?.length - 1}</StyledTrigger>
                    </Tooltip>
                  )}
                </>
              ),
            },
            {
              content: ruleName ? (
                <Tooltip content={`Automated by rule ${ruleName}`}>
                  <CircledIcon as={ApiiroLogoSmall} />
                </Tooltip>
              ) : data.formsByQuestionKey[question.key] ? (
                getFormAnswerSummary(data.formsByQuestionKey[question.key], question)
              ) : (
                <DisabledText>Missing</DisabledText>
              ),
            },
            {
              content: data.violationsByQuestionKey[question.key] ? (
                <Tooltip content={data.violationsByQuestionKey[question.key]}>
                  <SvgIcon name="Warning" />
                </Tooltip>
              ) : (
                (ruleName ||
                  (data.formsByQuestionKey[question.key] &&
                    data.formsByQuestionKey[question.key].some(form => !form.expired))) && (
                  <SvgIcon name="Accept" />
                )
              ),
            },
          ],
        };
      })}
    />
  );
};

const getFormAnswerSummary = (forms, question) => {
  const allExpired = forms.every(form => form.expired);

  return (
    <PopoverTip
      linkText={allExpired ? <DisabledText>Outdated</DisabledText> : 'Manual'}
      title={question.title}>
      <VerticalStack>
        {forms.map((form, index) => (
          <Link key={index} url={`/questionnaires/form/${form.key}?readOnly=true`}>
            <VerticalStack spacing="3px">
              {form.fillReason}
              <span>
                Filled on <b>{form.date}</b> by <b>{form.submitter}</b>
              </span>
              {form.expired && <b>Expired - questionnaire answered too long ago</b>}
            </VerticalStack>
          </Link>
        ))}
      </VerticalStack>
    </PopoverTip>
  );
};

const DisabledText = styled.div`
  color: var(--color-blue-gray-50);
`;

const StyledTrigger = styled(Trigger)`
  margin-left: 3px;
`;

const mockDataHeatMap = {
  questionnaireKey: '0',
  formsByQuestionKey: {
    0: [
      {
        key: 'aa',
        submitter: 'Or Vardi',
        date: '20/2/2021',
        fillReason: 'Changes in commit deadbeef',
      },
      {
        key: 'aa',
        submitter: 'Igal',
        date: '30/3/2021',
        fillReason: 'Periodic product owner questionnaire',
      },
    ],
    2: [
      {
        key: 'aa',
        submitter: 'Or Vardi',
        date: '30/2/2021',
        fillReason: 'Changes in commit 2dead2beef',
      },
    ],
    3: [
      {
        key: 'aa',
        submitter: 'Or Vardi',
        date: '30/6/2020',
        fillReason: 'Periodic product owner questionnaire',
        expired: true,
      },
    ],
    4: [
      {
        key: 'aa',
        submitter: 'Or Vardi',
        date: '30/6/2020',
        fillReason: 'Periodic product owner questionnaire',
        expired: true,
      },
    ],
  },
  violationsByQuestionKey: {
    0: 'Violated according to developer information',
    12: 'Session expiration enforcement not detected',
  },
};

const mockDataIsrp = {
  questionnaireKey: '1',
  formsByQuestionKey: {
    0: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '30/10/2021',
        fillReason: 'Changes in commit 9b10373',
      },
      {
        key: '0',
        submitter: 'Igal',
        date: '3/11/2021',
        fillReason: 'Periodic product owner questionnaire',
      },
    ],
    1: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '30/10/2021',
        fillReason: 'Changes in commit 9b10373',
      },
    ],
    2: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '30/10/2021',
        fillReason: 'Changes in commit 9b10373',
      },
    ],
    4: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '30/10/2021',
        fillReason: 'Changes in commit 9b10373',
      },
    ],
    7: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '20/10/2021',
        fillReason: 'Changes in commit ec567a1',
      },
    ],
    8: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '20/10/2021',
        fillReason: 'Changes in commit ec567a1',
      },
    ],
    9: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '20/10/2021',
        fillReason: 'Changes in commit ec567a1',
      },
    ],
    10: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '20/10/2021',
        fillReason: 'Changes in commit ec567a1',
      },
    ],
    11: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '20/10/2021',
        fillReason: 'Changes in commit ec567a1',
      },
    ],
    12: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '20/08/2021',
        fillReason: 'Changes in commit ec567a1',
        expired: true,
      },
    ],
    13: [
      {
        key: '0',
        submitter: 'Or Vardi',
        date: '20/08/2021',
        fillReason: 'Changes in commit ec567a1',
        expired: true,
      },
    ],
  },
  violationsByQuestionKey: {
    2: 'Violated according to commiter information',
    3: 'Siteminder framework usage not detected',
    4: 'Violated according to commiter information: Error states password is wrong when the user exists',
  },
};
