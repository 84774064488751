import Bold from '@src/components/Bold';
import { StyledHorizontalStack } from './Styles';

const AbnormalBehaviorTypeToString = abnormalBehavior => {
  switch (abnormalBehavior) {
    case 'DormantRepositoryAnomaly':
      return 'Dormant repository';
    case 'DormantDeveloperAnomaly':
      return 'Dormant developer';
    case 'DormantRepositoryDeveloperAnomaly':
      return 'Dormant developer in the context of repository';
    default:
      return null;
  }
};

const AbnormalBehaviorMaterialChange = ({ materialChange }) => {
  return (
    <StyledHorizontalStack>
      <span>Abnormal behavior of type</span>
      <Bold>{AbnormalBehaviorTypeToString(materialChange.abnormalBehavior)}</Bold>
      <span>was detected</span>
    </StyledHorizontalStack>
  );
};

export default AbnormalBehaviorMaterialChange;
