import styled from 'styled-components';
import { ExternalLink, Heading, Paragraph, Strong } from '@src-v2/components/typography';
import { MaterialChangesRow } from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

const getDisplayName = (dependency, showVersions, name) => {
  return `${name ?? dependency.name}${
    showVersions && dependency.resolvedVersion
      ? ` (${dependency.resolvedVersion})`
      : showVersions && dependency.version
        ? ` (${dependency.version})`
        : ''
  }`;
};

export const Dependency = ({ dependency, showVersions, name, withLink = true }) => (
  <>
    {dependency.isSubDependency && 'Sub-dependency'}
    {dependency.codeReference?.relativeFilePath}
    <MaterialChangesRow>
      {withLink ? (
        <DependencyLink href={dependency.homePage}>
          {getDisplayName(dependency, showVersions, name)}
        </DependencyLink>
      ) : (
        getDisplayName(dependency, showVersions, name)
      )}
    </MaterialChangesRow>
  </>
);

export const DependencyMaterialChange = styled(
  ({ dependency, thenSubType, children, ...props }) => (
    <Heading {...props}>
      <Paragraph> External dependency declardation </Paragraph>
      {dependency.homePage ? (
        <DependencyLink href={dependency.homePage}> {dependency.name}</DependencyLink>
      ) : (
        <Strong>{dependency.name}</Strong>
      )}{' '}
      was <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
      {children}
    </Heading>
  )
)`
  display: flex;
  gap: 1rem;
`;

const DependencyLink = styled(ExternalLink)`
  text-decoration: underline;
  color: var(--color-blue-65);
`;
