import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { CommitCodeReference } from './MaterialChangeUtils';
import { StyledHorizontalStack, StyledWrappedTitledList } from './Styles';

const ApiMethodParameterMaterialChange = ({ commitSha, repository, materialChange }) => {
  const { className, relativeFilePath, lineNumber, apiName } = materialChange?.codeReference ?? {};
  return (
    <StyledWrappedTitledList
      title={
        <HorizontalStack>
          <Bold>Method parameters were modified</Bold>
          <span>in API</span>
          <Bold>{apiName}</Bold>
          <span>defined at</span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}
            lineNumber={lineNumber}>
            {className || relativeFilePath}
          </CommitCodeReference>
        </HorizontalStack>
      }
      list={materialChange.modifications.map(modification => ModificationDescription(modification))}
    />
  );
};

const ModificationDescription = ({
  name,
  parentType,
  modificationType,
  previousType,
  currentType,
}) => {
  const key = `${name}-${parentType}-${previousType}-${currentType}`;

  switch (modificationType) {
    case 'Added':
      return (
        <StyledHorizontalStack key={key}>
          <span>{parentType ? 'Field' : 'Parameter'}</span>
          <Bold>
            {currentType} {name}
          </Bold>
          {parentType && (
            <>
              <span>in type</span>
              <Bold>{parentType}</Bold>
            </>
          )}
          <span>was added</span>
        </StyledHorizontalStack>
      );

    case 'Removed':
      return (
        <StyledHorizontalStack key={key}>
          <span>{parentType ? 'Field' : 'Parameter'}</span>
          <Bold>
            {previousType} {name}
          </Bold>
          {parentType && (
            <>
              <span>in type</span>
              <Bold>{parentType}</Bold>
            </>
          )}
          <span>was removed</span>
        </StyledHorizontalStack>
      );

    case 'ChangedType':
      return (
        <StyledHorizontalStack key={key}>
          <span>Type of {parentType ? 'field' : 'parameter'}</span>
          <Bold>{name}</Bold>
          {parentType && (
            <>
              <span>in type</span>
              <Bold>{parentType}</Bold>
            </>
          )}
          <span>was changed from</span>
          <Bold>{previousType}</Bold>
          <span>to</span>
          <Bold>{currentType}</Bold>
        </StyledHorizontalStack>
      );

    default:
      return null;
  }
};

export default ApiMethodParameterMaterialChange;
