import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';
import Bold from '@src/components/Bold';
import { VerticalStack } from '@src/components/VerticalStack';

export const ManagedSemgrepMaterialChange = ({
  isAdded,
  commitSha,
  repository,
  lineNumber,
  filePath,
  entityDisplayName,
}) => (
  <VerticalStack>
    <span>Managed Semgrep detected {isAdded ? 'a new' : 'a resolved'} code finding at path</span>
    <CommitCodeReference
      repository={repository}
      commitSha={commitSha}
      lineNumber={lineNumber}
      relativeFilePath={filePath}>
      <Bold>{entityDisplayName}</Bold>
    </CommitCodeReference>
  </VerticalStack>
);
