const mappings = {
  types: {
    API: 'API',
    Issue: 'Issue',
    UserStory: 'UserStory',
    InternalFramework: 'InternalFramework',
    CustomSensitiveData: 'CustomSensitiveData',
    SecretsExclusion: 'SecretsExclusion',
  },
  apiPathByType: {
    API: 'apiClassification',
    Issue: 'riskRelatedIssueDefinitions',
    UserStory: 'userStoryDefinitions',
    InternalFramework: 'internalFrameworkDefinitions',
    CustomSensitiveData: 'customSensitiveDataDefinitions',
    SecretsExclusion: 'secretExclusionDefinitions',
  },
  modalTitleByType: {
    Issue: 'Define Issues to Track',
    UserStory: 'Define Feature Requests to Track',
    InternalFramework: 'Define a Custom Framework',
  },
  typeDisplayNameByType: {
    API: 'ApiClassificationDefinition',
    Issue: 'RiskRelatedIssueDefinition',
    UserStory: 'UserStoryDefinition',
    InternalFramework: 'InternalFrameworkDefinition',
    CustomSensitiveData: 'CustomSensitiveDataDefinition',
    SecretsExclusion: 'SecretsExclusionDefinition',
  },
  typeToRisk: {
    Issue: 'RiskRelatedIssue',
    UserStory: 'RiskyUserStory',
  },
  typeDefinitionToRule: {
    ClassifyApis: 'ApiClassification',
    Issue: {
      given: 'RiskRelatedIssue',
      when: 'OpenIssue',
    },
    UserStory: {
      given: 'RiskyUserStory',
      when: 'OpenUserStory',
    },
    InternalFramework: {
      given: 'Repository',
      when: 'Technology',
    },
    CustomSensitiveData: {
      when: 'SensitiveData',
    },
  },
};

export default mappings;

export const getDefinitionType = definition =>
  ({
    ApiClassificationDefinition: 'API',
    RiskRelatedIssueDefinition: 'Issue',
    UserStoryDefinition: 'UserStory',
    InternalFrameworkDefinition: 'InternalFramework',
    CustomSensitiveDataDefinition: 'CustomSensitiveData',
    SecretsExclusionDefinition: 'SecretsExclusion',
  })[definition.type];

export const getDefinitionNamePrefix = definition =>
  ({
    ApiClassificationDefinition: 'api routes',
    RiskRelatedIssueDefinition: 'tracked issues',
    UserStoryDefinition: 'feature requests',
    InternalFrameworkDefinition: 'custom framework',
    CustomSensitiveDataDefinition: 'custom sensitive data',
    SecretsExclusionDefinition: 'secrets exclusion',
  })[definition.type];
