import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { StatusIcon } from '@src-v2/components/icons';
import { ExternalLink, Heading, NavLink } from '@src-v2/components/typography';

export const Toastify = styled(
  forwardRef((props, ref) => {
    return createPortal(<ToastContainer ref={ref} {...props} />, document.body);
  })
)`
  position: fixed;
  z-index: 10;
  display: flex;
  min-width: 80rem;
  max-width: 140rem;
  flex-direction: column;
  gap: 4rem;

  &.Toastify__toast-container {
    padding: 0;
    width: inherit;
  }

  &.Toastify__toast-container--top-left {
    top: 4rem;
    left: 4rem;
  }

  &.Toastify__toast-container--top-center {
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.Toastify__toast-container--top-right {
    top: 4rem;
    right: 4rem;
  }

  &.Toastify__toast-container--bottom-left {
    bottom: 4rem;
    left: 4rem;
  }

  &.Toastify__toast-container--bottom-center {
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.Toastify__toast-container--bottom-right {
    bottom: 4rem;
    right: 4rem;
  }

  .Toastify__toast {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 14rem;
    max-height: 100rem;
    font: var(--font-size-m) 400;
    color: var(--color-blue-gray-70);
    padding: 3rem;
    line-height: 6rem;
    border-radius: 3rem;
    border: 0.25rem solid transparent;
    box-shadow: var(--elevation-6);
    overflow: hidden;
    margin: 0;

    ${Heading} {
      font-size: var(--font-size-m);
      font-weight: 400;
    }

    ${NavLink} {
      text-decoration: underline;
    }
  }

  .Toastify__toast-body {
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
    padding: 0;
    margin: 0 2rem 0 0;

    > :last-child {
      flex-grow: 1;
    }
  }

  .Toastify__toast-icon {
    flex-shrink: 0;
    width: initial;
    margin-right: 2rem;

    ${StatusIcon} {
      display: block;
    }
  }

  .Toastify__toast--info {
    border-color: var(--color-blue-60);
    background-color: var(--color-blue-15);

    .Toastify__toast-icon {
      color: var(--color-blue-60);
    }
  }

  .Toastify__toast--success {
    border-color: var(--color-green-50);
    background-color: var(--color-green-10);

    .Toastify__toast-icon {
      color: var(--color-green-50);
    }
  }

  .Toastify__toast--warning {
    border-color: var(--color-yellow-55);
    background-color: var(--color-yellow-15);

    .Toastify__toast-icon {
      color: var(--color-yellow-40);
    }
  }

  .Toastify__toast--error {
    border-color: var(--color-red-50);
    background-color: var(--color-red-15);

    .Toastify__toast-icon {
      color: var(--color-red-50);
    }
  }

  .Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 700ms;
  }

  .Toastify--animate-icon {
    animation-fill-mode: both;
    animation-duration: 300ms;
  }

  .Toastify__bounce-enter--top-left,
  .Toastify__bounce-enter--bottom-left {
    animation-name: Toastify__bounceInLeft;
  }

  .Toastify__bounce-enter--top-right,
  .Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight;
  }

  .Toastify__bounce-enter--top-center {
    animation-name: Toastify__bounceInDown;
  }

  .Toastify__bounce-enter--bottom-center {
    animation-name: Toastify__bounceInUp;
  }

  .Toastify__bounce-exit--top-left,
  .Toastify__bounce-exit--bottom-left {
    animation-name: Toastify__bounceOutLeft;
  }

  .Toastify__bounce-exit--top-right,
  .Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight;
  }

  .Toastify__bounce-exit--top-center {
    animation-name: Toastify__bounceOutUp;
  }

  .Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown;
  }

  @keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
      opacity: 0;
      transform: translate3d(750rem, 0, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(-6rem, 0, 0);
    }
    75% {
      transform: translate3d(3rem, 0, 0);
    }
    90% {
      transform: translate3d(-1rem, 0, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes Toastify__bounceOutRight {
    20% {
      opacity: 1;
      transform: translate3d(-5rem, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(500rem, 0, 0);
    }
  }
  @keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: translate3d(-750rem, 0, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(6rem, 0, 0);
    }
    75% {
      transform: translate3d(-3rem, 0, 0);
    }
    90% {
      transform: translate3d(1rem, 0, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes Toastify__bounceOutLeft {
    20% {
      opacity: 1;
      transform: translate3d(5rem, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(-500rem, 0, 0);
    }
  }
  @keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
      opacity: 0;
      transform: translate3d(0, 750rem, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(0, -5rem, 0);
    }
    75% {
      transform: translate3d(0, 3rem, 0);
    }
    90% {
      transform: translate3d(0, -1rem, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes Toastify__bounceOutUp {
    20% {
      transform: translate3d(0, -3rem, 0);
    }
    40%,
    45% {
      opacity: 1;
      transform: translate3d(0, 5rem, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(0, -500rem, 0);
    }
  }
  @keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: translate3d(0, -750rem, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(0, 6rem, 0);
    }
    75% {
      transform: translate3d(0, -3rem, 0);
    }
    90% {
      transform: translate3d(0, 1rem, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes Toastify__bounceOutDown {
    20% {
      transform: translate3d(0, 3rem, 0);
    }
    40%,
    45% {
      opacity: 1;
      transform: translate3d(0, -5rem, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(0, 500rem, 0);
    }
  }

  // https://github.com/fkhadra/react-toastify/issues/822
  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;

    &.Toastify__progress-bar--success {
      background: var(--color-green-55);
    }
  }
`;

export const ToastParagraph = styled.p`
  font-size: 3.5rem;
  font-weight: 300;
  color: var(--color-blue-gray-65);
`;

export const SuccessToast = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10rem;

  ${ExternalLink} {
    font-size: var(--font-size-xs);
  }
`;
