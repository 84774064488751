import { DateTime } from '@src-v2/components/time';
import { ContributorTooltip } from '@src-v2/containers/contributors/contributor-tooltip';
import { dateFormats } from '@src-v2/data/datetime';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';

const NewDeveloperMaterialChange = ({ materialChange, developerProfileByIdentityKey }) => {
  const developerProfile = developerProfileByIdentityKey[materialChange.authorIdentityKey];

  return (
    <VerticalStack>
      <HorizontalStack>
        <Bold>Committed by new developer</Bold>{' '}
        <ContributorTooltip hideImage developerProfile={developerProfile} />
        <div>
          (first activity{' '}
          <DateTime date={developerProfile.activeSince} format={dateFormats.longDate} />)
        </div>
      </HorizontalStack>
    </VerticalStack>
  );
};

export default NewDeveloperMaterialChange;
