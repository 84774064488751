import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import styled from 'styled-components';
import { Avatar } from '@src-v2/components/avatar';
import { Card } from '@src-v2/components/cards';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink, Heading, Link, Paragraph, Strong } from '@src-v2/components/typography';
import { getCommitCodeReferenceUrl } from '@src-v2/containers/commit/material-changes-utils';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';

export const CommitCodeReference = ({ repository, commitSha, relativeFilePath, children }) => (
  <Tooltip content={relativeFilePath}>
    <ExternalLink href={getCommitCodeReferenceUrl(repository, commitSha, relativeFilePath)}>
      {children}
    </ExternalLink>
  </Tooltip>
);

export const CodeReference = ({ repository, commitSha, relativeFilePath, children }) => (
  <Tooltip content={relativeFilePath}>
    <ExternalLink href={generateCodeReferenceUrl(repository, { commitSha, relativeFilePath })}>
      {children}
    </ExternalLink>
  </Tooltip>
);

export const DeveloperDetails = styled(({ developerProfile, ...props }) => {
  return (
    <div {...props}>
      <Avatar {...developerProfile} username={developerProfile.displayName} />
      <Link to={`/users/contributors/${developerProfile.representativeIdentityKeySha}`}>
        {developerProfile.displayName}
      </Link>
    </div>
  );
})`
  display: flex;

  ${Avatar} {
    width: 7rem;
    height: 7rem;
    font-size: 3rem;
  }

  ${Link} {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    color: black;
    gap: 2rem;
    font-weight: 400;
  }
`;

export const HighlightedCode = styled(({ code, language, wrapLongLines = true, ...props }) => (
  <SyntaxHighlighter
    {...props}
    language={language}
    wrapLongLines={wrapLongLines}
    customStyle={{
      background: 'transparent',
      margin: 0,
      padding: 0,
    }}>
    {code}
  </SyntaxHighlighter>
))`
  ${Tooltip} & code {
    color: white;
  }
`;

export const MaterialChangeCard = styled(Card)`
  display: flex;
  align-items: center;
  padding: 4rem 6rem 3rem 6rem;
  margin-bottom: 6rem;

  ${Strong} {
    margin-right: 1rem;
    white-space: nowrap;
  }

  ${Paragraph} {
    margin-right: 1rem;
    white-space: nowrap;
    width: fit-content;
  }

  ${Heading} {
    font-size: var(--font-size-m);
    font-weight: 300;
    display: flex;
    white-space: nowrap;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${ExternalLink} {
    color: var(--color-blue-65);
    text-decoration: underline;
    margin-right: 1rem;
  }
`;

export const MaterialChangesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: var(--font-size-s);
  font-weight: 300;
`;

export const MaterialChangesRow = styled.div`
  font-size: var(--font-size-s);
  display: flex;
  font-weight: 300;
  width: fit-content;
  align-items: start;
`;
