export const convertInitialValue = value => ({
  name: value.displayName,
  label: value.displayName,
  value: value.key,
  ...value,
});

export const convertProject = project => ({
  displayName: project.name,
  label: project.name,
  value: project.key,
  additionalProperties: project.additionalFields,
  ...project,
});

export const convertIssueType = issueType => ({
  label: issueType.displayName,
  value: issueType.key,
  ...issueType,
});

export const getInitialIssueType = (value: string, options: { value: string }[]) => {
  return convertIssueType(options?.find(option => option.value === value) ?? options[0]);
};
