import uniq from 'lodash/uniq';

export default {
  state: {},
  reducers: {},
  selectors: () => ({}),
  effects: dispatch => ({
    async fetchData({ deployKeys }) {
      const developerIdentityKeys = uniq(
        deployKeys.map(deployKey => deployKey.createdByIdentityKey)
      );
      await dispatch.developerProfiles.getDevelopersByIdentitiesKeysAsync({
        keys: developerIdentityKeys,
      });
    },
  }),
};
