import { useCallback } from 'react';
import { useToggle } from '@src-v2/hooks';

export function useDetectScrolling() {
  const [scrolled, toggleScrolled] = useToggle();

  const handleScroll = useCallback(
    event => {
      const newScrolled = event.target.scrollTop > 0;
      if (newScrolled !== scrolled) {
        toggleScrolled(newScrolled);
      }
    },
    [scrolled, toggleScrolled]
  );

  return [scrolled, handleScroll] as const;
}
